import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, Button, Skeleton, List, ListItem, ListItemIcon } from "@mui/material";
import InAppPurchaseService from "../../../services/native/InAppPurchaseService.ts";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs.jsx";
import { ReactComponent as CheckIcon } from "../../../assets/icons/svg/circle-check.svg";
import { navigateTo } from "../../../actions/navigation.js";

const IOSProduct = ({ product, term, userId, navigateTo }) => {
  const [savings, setSavings] = useState("");
  console.log("PRODUCT");
  console.log(product);

  const getSavings = () => {
    switch (term) {
      case "annual":
        setSavings("61%");
        return;
      case "monthly":
        setSavings("50%");
        return;
      case "weekly":
        setSavings("");
        return;
      default:
        setSavings("");
    }
  };

  useEffect(() => {
    getSavings();
  }, [term]);
  const handleInAppPurchase = async (productId) => {
    try {
      const result = await InAppPurchaseService.purchaseSubscription(productId);
      if (result) {
        console.log("Successful purchase: ", productId);
        navigateTo("/plus/welcome");
      }
    } catch (error) {
      console.error("Purchase failed:", error);
    }
  };

  const getDisplayPrice = (product) => {
    if (product?.raw?.price) {
      return product.raw.price;
    } else {
      if (product?.id) {
        switch (product.id) {
          case "single_plus_monthly":
            return "$11.99";
          case "single_plus_weekly":
            return "$5.99";
          case "single_plus_annual":
            return "$119.99";
          default:
            return "$11.99/month";
        }
      } else {
        return "$11.99/month";
      }
    }
  };

  const formatTitle = () => {
    if (product && product?.title) {
      return product.title;
    } else {
      return "thisPantry+ Subscription";
    }
  };

  const getTerm = () => {
    switch (term) {
      case "monthly":
        return "month";
      case "weekly":
        return "week";
      case "annual":
        return "year";
      default:
        return "term";
    }
  };

  return (
    <div className="px-4 sm:px-8">
      <div>
        <Typography variant="h5" className="text-background mb-8 my-4 font-medium">
          {formatTitle()}
        </Typography>
      </div>
      <div className="py-4">
        <div className="flex flex-row items-end">
          <Typography variant="h4" className="text-background font-semibold leading-none mr-1">
            {getDisplayPrice(product)}
          </Typography>
          <Typography className="subtitle2 text-secondaryText-100 leading-snug text-xs">
            per
            <br></br>
            {getTerm()}
          </Typography>
        </div>
        {savings !== "" && (
          <div className="mt-2">
            <Typography variant="subtitle2" className="font-normal text-white">
              Save <span className="font-semibold">{savings}</span>!
            </Typography>
          </div>
        )}
      </div>

      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        onClick={() => handleInAppPurchase(product.id)}
      >
        Start Trial
      </Button>
      <div className="pt-8">
        <Typography variant="subtitle2" className="text-secondaryText-100 font-normal">
          This includes:
        </Typography>
        <List>
          <ListItem className="py-1 px-2">
            <ListItemIcon className="w-6 min-w-6 h-4">
              <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
            </ListItemIcon>
            <Typography variant="subtitle2" className="text-xs text-white font-normal">
              Generate Recipes with thisPantry+ AI
            </Typography>
          </ListItem>
          <ListItem className="py-1 px-2">
            <ListItemIcon className="w-6 min-w-6 h-4">
              <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
            </ListItemIcon>
            <Typography variant="subtitle2" className="text-xs text-white font-normal">
              Exclusive access to the newest features first
            </Typography>
          </ListItem>
          <ListItem className="py-1 px-2">
            <ListItemIcon className="w-6 min-w-6 h-4">
              <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
            </ListItemIcon>
            <Typography variant="subtitle2" className="text-xs text-white font-normal">
              Direct line to our developers
            </Typography>
          </ListItem>
          <ListItem className="py-1 px-2">
            <ListItemIcon className="w-6 min-w-6 h-4">
              <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
            </ListItemIcon>
            <Typography variant="subtitle2" className="text-xs text-white font-normal">
              Keep our launch pricing forever
            </Typography>
          </ListItem>
        </List>
        <div className="mt-4 text-center">
          <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug font-normal text-xs">
            All plans include a 7-day free trial.
          </Typography>
          <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug font-normal text-xs">
            Cancel anytime.
          </Typography>
        </div>
      </div>
    </div>
  );
};

const IOSSkeleton = () => {
  return (
    <div className="w-full h-full">
      <div className="flex flex-col px-4 sm:px-8">
        <div className="mt-4 mb-8">
          <Skeleton variant="rectangular" width="100%" className="h-5 rounded-md bg-white/20" />
        </div>
        <div className="py-4 w-full max-w-[160px]">
          <Skeleton variant="rectangular" width="100%" className="rounded-lg h-[2.125rem] bg-white/20" />
        </div>
        <div>
          <Button
            variant="contained"
            disabled={true}
            disableElevation
            fullWidth
            className="[&.Mui-disabled]:bg-mainGreen/50 [&.Mui-disabled]:text-white/50"
          >
            Loading...
          </Button>
        </div>
        <div className="pt-8">
          <Typography variant="subtitle2" className="text-secondaryText-100 font-normal">
            This includes:
          </Typography>
          <List>
            <ListItem className="py-1 px-2">
              <ListItemIcon className="w-6 min-w-6 h-4">
                <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
              </ListItemIcon>
              <Typography variant="subtitle2" className="text-xs text-white font-normal">
                Generate Recipes with thisPantry+ AI
              </Typography>
            </ListItem>
            <ListItem className="py-1 px-2">
              <ListItemIcon className="w-6 min-w-6 h-4">
                <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
              </ListItemIcon>
              <Typography variant="subtitle2" className="text-xs text-white font-normal">
                Exclusive access to the newest features first
              </Typography>
            </ListItem>
            <ListItem className="py-1 px-2">
              <ListItemIcon className="w-6 min-w-6 h-4">
                <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
              </ListItemIcon>
              <Typography variant="subtitle2" className="text-xs text-white font-normal">
                Direct line to our developers
              </Typography>
            </ListItem>
            <ListItem className="py-1 px-2">
              <ListItemIcon className="w-6 min-w-6 h-4">
                <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
              </ListItemIcon>
              <Typography variant="subtitle2" className="text-xs text-white font-normal">
                Keep our launch pricing forever
              </Typography>
            </ListItem>
          </List>
          <div className="mt-4 text-center">
            <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug font-normal text-xs">
              All plans include a 7-day free trial.
            </Typography>
            <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug font-normal text-xs">
              Cancel anytime.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const IOSPriceSelector = ({ auth: { user }, navigateTo }) => {
  const [products, setProducts] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState("monthly");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkProducts = setInterval(() => {
      if (InAppPurchaseService && InAppPurchaseService?.initialized) {
        const availableProducts = InAppPurchaseService.getProducts();
        if (availableProducts.length > 0) {
          setProducts(availableProducts);
          setLoading(false);
          clearInterval(checkProducts);
        }
      }
    }, 100);

    return () => clearInterval(checkProducts);
  }, []);

  const currentProduct = products?.find((p) => p.id.includes(selectedTerm));

  const handleTabChange = (value) => {
    setSelectedTerm(value);
  };

  return (
    <div className="w-full h-fit max-w-lg mx-auto">
      <div className="flex flex-col gap-4">
        <div className="sm:px-8">
          <Tabs value={selectedTerm} onValueChange={(value) => handleTabChange(value)} className="w-full">
            <TabsList className="w-full border-x-0 border-y sm:border border-secondaryText-100/50 bg-primaryText p-0 rounded-none sm:rounded-lg">
              <TabsTrigger
                value="weekly"
                className="w-full h-full text-white/90 data-[state=active]:bg-mainGreen data-[state=active]:text-white rounded-none sm:rounded-md"
              >
                Weekly
              </TabsTrigger>
              <TabsTrigger
                value="monthly"
                className="w-full h-full text-white/90 data-[state=active]:bg-mainGreen data-[state=active]:text-white rounded-none sm:rounded-md"
              >
                Monthly
              </TabsTrigger>
              <TabsTrigger
                value="annual"
                className="w-full h-full text-white/90 data-[state=active]:bg-mainGreen data-[state=active]:text-white rounded-none sm:rounded-md"
              >
                Annual
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        {loading ? (
          <IOSSkeleton />
        ) : (
          <IOSProduct product={currentProduct} term={selectedTerm} userId={user._id} navigateTo={navigateTo} />
        )}
      </div>
    </div>
  );
};

IOSPriceSelector.propTypes = {
  auth: PropTypes.object.isRequired,
  navigateTo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { navigateTo })(IOSPriceSelector);
