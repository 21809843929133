//Redux types

//Auth
export const INITIALIZE_AUTH = "INITIALIZE_AUTH";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_FEED = "CLEAR_FEED";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";
export const UPDATE_REQUESTS = "UPDATE_REQUESTS";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";
export const SHOW_REGISTER_MODAL = "SHOW_REGISTER_MODAL";
export const HIDE_REGISTER_MODAL = "HIDE_REGISTER_MODAL";
export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const HIDE_LOGIN_MODAL = "HIDE_LOGIN_MODAL";
export const SET_TOKENS_FROM_CALLBACK = "SET_TOKENS_FROM_CALLBACK";

//Signup
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SET_SIGNUP_STAGE = "SET_SIGNUP_STAGE";
export const CLEAR_SIGNUP = "CLEAR_SIGNUP";
export const SET_REGISTER_ERRORS = "SET_REGISTER_ERRORS";
export const SIGNUP_RESTRICTIONS_NEXT = "SIGNUP_RESTRICTIONS_NEXT";
export const SIGNUP_RESTRICTIONS_ERROR = "SIGNUP_RESTRICTIONS_ERROR";
export const SIGNUP_PREFERENCES_NEXT = "SIGNUP_PREFERENCES_NEXT";
export const SIGNUP_PREFERENCES_ERROR = "SIGNUP_PREFERENCES_ERROR";
export const SIGNUP_ABILITY_NEXT = "SIGNUP_ABILITY_NEXT";
export const SIGNUP_ABILITY_ERROR = "SIGNUP_ABILITY_ERROR";

//Profile
export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";
export const CURRENT_PROFILE_ERROR = "CURRENT_PROFILE_ERROR";

export const GET_VIEWED_PROFILE = "GET_VIEWED_PROFILE";
export const MY_VIEWED_PROFILE = "MY_VIEWED_PROFILE";
export const VIEWED_PROFILE_ERROR = "VIEWED_PROFILE_ERROR";
export const RESET_VIEWED_PROFILE = "RESET_VIEWED_PROFILE";
export const GET_PROFILE_POSTDATA = "GET_PROFILE_POSTDATA";
export const GET_PROFILE_POST_ERROR = "GET_PROFILE_POST_ERROR";
export const GET_PROFILE_RECIPEDATA = "GET_PROFILE_RECIPEDATA";
export const GET_PROFILE_RECIPE_ERROR = "GET_PROFILE_RECIPE_ERROR";

//Edit Profile
export const UPDATE_EDIT_PROFILE = "UPDATE_EDIT_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

//Feed
export const GET_HOME_FEED = "GET_HOME_FEED";
export const SET_HOME_FEED = "SET_HOME_FEED";
export const HOME_FEED_ERROR = "HOME_FEED_ERROR";
export const GET_FEED_POST = "GET_FEED_POST";
export const GET_FEED_POST_ERROR = "GET_FEED_POST_ERROR";
export const GET_POST_COMMENTS = "GET_POST_COMMENTS";
export const GET_POST_COMMENTS_ERROR = "GET_POST_COMMENTS_ERROR";
export const GET_POST_COMMENTS_RESET = "GET_POST_COMMENTS_RESET";
export const SET_POST_INTERACTIONS = "SET_POST_INTERACTIONS";
export const FEED_DELETE_COMMENT = "FEED_DELETE_COMMENT";
export const REFRESH_HOME_FEED = "REFRESH_HOME_FEED";
//Create Recipe
export const RECIPE_CREATE_FORMAT_INGRED = "RECIPE_CREATE_FORMAT_INGRED";
export const RECIPE_CREATE_UPDATE = "RECIPE_CREATE_UPDATE";
export const RECIPE_CREATE_INGRED_ERROR = "RECIPE_CREATE_INGRED_ERROR";
export const RECIPE_CREATE_REMOVE_INGRED = "RECIPE_CREATE_REMOVE_INGRED";
export const RECIPE_CREATE_EDIT_INGRED = "RECIPE_CREATE_EDIT_INGRED";
export const GET_EDIT_RECIPE = "GET_EDIT_RECIPE";
export const GET_EXTERNAL_RECIPE = "GET_EXTERNAL_RECIPE";
export const GET_GENERATED_RECIPE = "GET_GENERATED_RECIPE";
export const RECIPE_CREATE_RESET = "RECIPE_CREATE_RESET";
export const TOGGLE_RECIPE_MACROS_MODAL = "TOGGLE_RECIPE_MACROS_MODAL";
export const TOGGLE_RECIPE_OUTPUT_MODAL = "TOGGLE_RECIPE_OUTPUT_MODAL";
export const TOGGLE_RECIPE_TIMING_MODAL = "TOGGLE_RECIPE_TIMING_MODAL";
export const SET_ORIGINAL_IMAGES = "SET_ORIGINAL_IMAGES";
export const SET_CREATE_RECIPE_ERRORS = "SET_CREATE_RECIPE_ERRORS";
export const RESET_CREATE_RECIPE_ERRORS = "RESET_CREATE_RECIPE_ERRORS";
export const CLEAR_CREATE_RECIPE_ERROR_PATH = "CLEAR_CREATE_RECIPE_ERROR_PATH";

//Create Recipe Modals
export const RECIPE_CREATE_OPEN_ADD_INGRED_MODAL = "RECIPE_CREATE_OPEN_ADD_INGRED_MODAL";
export const RECIPE_CREATE_CLOSE_ADD_INGRED_MODAL = "RECIPE_CREATE_CLOSE_ADD_INGRED_MODAL";
export const RECIPE_CREATE_OPEN_EDIT_INGRED_MODAL = "RECIPE_CREATE_OPEN_EDIT_INGRED_MODAL";
export const RECIPE_CREATE_CLOSE_EDIT_INGRED_MODAL = "RECIPE_CREATE_CLOSE_EDIT_INGRED_MODAL";
export const RECIPE_CREATE_OPEN_EDIT_STEP_MODAL = "RECIPE_CREATE_OPEN_EDIT_STEP_MODAL";
export const RECIPE_CREATE_CLOSE_EDIT_STEP_MODAL = "RECIPE_CREATE_CLOSE_EDIT_STEP_MODAL";

//Walkthrough
export const RESET_WALKTHROUGH = "RESET_WALKTHROUGH";
export const WALKTHROUGH_GET_RECIPE = "WALKTHROUGH_GET_RECIPE";
export const WALKTHROUGH_RECIPE_ERROR = "WALKTHROUGH_RECIPE_ERROR";
export const WALKTHROUGH_SET_SECTION = "WALKTHROUGH_SET_SECTION";
export const WALKTHROUGH_SET_INGREDIENT = "WALKTHROUGH_SET_INGREDIENT";
export const WALKTHROUGH_SCALE_RECIPE = "WALKTHROUGH_SCALE_RECIPE";
export const WALKTHROUGH_SCALE_SECTION = "WALKTHROUGH_SCALE_SECTION";
export const WALKTHROUGH_SET_SERVINGS_EATEN = "WALKTHROUGH_SET_SERVINGS_EATEN";
export const WALKTHROUGH_SET_SECTION_SERVINGS = "WALKTHROUGH_SET_SECTION_SERVINGS";
export const SET_WALKTHROUGH_ERRORS = "SET_WALKTHROUGH_ERRORS";

//Update
export const GET_NEW_UPDATES = "GET_NEW_UPDATES";
export const NEW_UPDATES_ERROR = "NEW_UPDATES_ERROR";
export const CLOSE_UPDATE_MODAL = "CLOSE_UPDATE_MODAL";

//Alert
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//Pantry
export const GET_MY_INGREDIENTS = "GET_MY_INGREDIENTS";
export const MY_INGREDIENTS_ERROR = "MY_INGREDIENTS_ERROR";
export const SEARCH_SINGLE_INGREDIENT = "SEARCH_SINGLE_INGREDIENT";
export const SEARCH_SINGLE_INGREDIENT_ERROR = "SEARCH_SINGLE_INGREDIENT_ERROR";
export const RESET_SINGLE_INGREDIENT_SEARCH = "RESET_SINGLE_INGREDIENT_SEARCH";
export const SELECT_SINGLE_INGREDIENT_TO_ADD = "SELECT_SINGLE_INGREDIENT_TO_ADD";
export const START_SINGLE_INGREDIENT_SEARCH = "START_SINGLE_INGREDIENT_SEARCH";
export const ADD_SINGLE_INGREDIENT_TO_PANTRY = "ADD_SINGLE_INGREDIENT_TO_PANTRY";
export const ADD_SINGLE_INGREDIENT_TO_PANTRY_ERROR = "ADD_SINGLE_INGREDIENT_TO_PANTRY_ERROR";
export const PANTRY_SELECT_ITEM_BY_ID = "PANTRY_SELECT_ITEM_BY_ID";
export const PANTRY_OPEN_ITEM_MODAL = "PANTRY_OPEN_ITEM_MODAL";
export const PANTRY_CLOSE_ITEM_MODAL = "PANTRY_CLOSE_ITEM_MODAL";
export const PANTRY_ITEM_MODAL_ERROR = "PANTRY_ITEM_MODAL_ERROR";
export const RESET_ADD_PANTRY = "RESET_ADD_PANTRY";

//Search
export const SEARCH_SET_QUERY = "SEARCH_SET_QUERY";
export const SEARCH_SET_TYPE = "SEARCH_SET_TYPE";
export const SEARCH_GET_RESULTS = "SEARCH_GET_RESULTS";
export const SEARCH_RESULTS_ERROR = "SEARCH_RESULTS_ERROR";
export const SEARCH_RESET_RESPONSE = "SEARCH_RESET_RESPONSE";
export const SEARCH_FETCH_PROFILES = "SEARCH_FETCH_PROFILES";
export const SEARCH_PROFILES_ERROR = "SEARCH_PROFILES_ERROR";

//Recipes
export const GET_RECIPE_FROM_URL = "GET_RECIPE_FROM_URL";
export const RECIPE_FROM_URL_ERROR = "RECIPE_FROM_URL_ERROR";
export const RESET_STANDALONE_RECIPE = "RESET_STANDALONE_RECIPE";
export const LIKE_RECIPE = "LIKE_RECIPE";
export const UNLIKE_RECIPE = "UNLIKE_RECIPE";
export const LIKE_RECIPE_ERROR = "LIKE_RECIPE_ERROR";
export const BOOKMARK_RECIPE = "BOOKMARK_RECIPE";
export const UNBOOKMARK_RECIPE = "UNBOOKMARK_RECIPE";
export const BOOKMARK_RECIPE_ERROR = "BOOKMARK_RECIPE_ERROR";
export const RECIPE_CREATED = "RECIPE_CREATED";
//Media
export const IMAGE_UPLOAD_ADD = "IMAGE_UPLOAD_ADD";
export const IMAGE_UPLOAD_REMOVE = "IMAGE_UPLOAD_REMOVE";
export const IMAGE_UPLOAD_ERROR = "IMAGE_UPLOAD_ERROR";
export const IMAGE_UPLOAD_RESET = "IMAGE_UPLOAD_RESET";
export const IMAGE_UPLOAD_CROP = "IMAGE_UPLOAD_CROP";
export const IMAGE_UPLOAD_CROP_ERROR = "IMAGE_UPLOAD_CROP_ERROR";
export const IMAGE_UPLOAD_REORDER = "IMAGE_UPLOAD_REORDER";
export const IMAGE_UPLOAD_OPEN_CROP = "IMAGE_UPLOAD_OPEN_CROP";
export const IMAGE_UPLOAD_CLOSE_CROP = "IMAGE_UPLOAD_CLOSE_CROP";

//Cookbook
export const GET_COOKBOOK_RECIPES = "GET_COOKBOOK_RECIPES";
export const COOKBOOK_RECIPES_ERROR = "COOKBOOK_RECIPES_ERROR";
export const GET_DEFAULT_RECIPE_FILTERS = "GET_DEFAULT_RECIPE_FILTERS";
export const GET_DEFAULT_RECIPE_FILTERS_ERROR = "GET_DEFAULT_RECIPE_FILTERS_ERROR";
export const SET_SEARCH_RECIPES = "SET_SEARCH_RECIPES";
export const GET_SEARCH_RECIPES = "GET_SEARCH_RECIPES";
export const SEARCH_RECIPES_ERROR = "SEARCH_RECIPES_ERROR";
export const RESET_COOKBOOK_FILTERS = "RESET_COOKBOOK_FILTERS";
export const SEARCH_RECIPES_RESET = "SEARCH_RECIPES_RESET";
export const SET_COOKBOOK_QUERY = "SET_COOKBOOK_QUERY";
export const RESET_COOKBOOK_QUERY = "RESET_COOKBOOK_QUERY";
export const SET_ALLERGEN_FILTERS = "SET_ALLERGEN_FILTERS";
export const SET_DIETARY_FILTERS = "SET_DIETARY_FILTERS";
export const SET_INCLUDE_FILTERS = "SET_INCLUDE_FILTERS";
export const SET_EXCLUDE_FILTERS = "SET_EXCLUDE_FILTERS";
export const SET_INCLUDE_INGRED_TYPE = "SET_INCLUDE_INGRED_TYPE";

//Reviews
export const SHOW_RECIPE_REVIEW_MODAL = "SHOW_RECIPE_REVIEW_MODAL";
export const HIDE_RECIPE_REVIEW_MODAL = "HIDE_RECIPE_REVIEW_MODAL";
export const CREATE_RECIPE_REVIEW = "CREATE_RECIPE_REVIEW";
export const CREATE_RECIPE_REVIEW_ERROR = "CREATE_RECIPE_REVIEW_ERROR";
export const FETCH_RECIPE_REVIEWS = "FETCH_RECIPE_REVIEWS";
export const FETCH_RECIPE_REVIEWS_ERROR = "FETCH_RECIPE_REVIEWS_ERROR";
export const RESET_RECIPE_REVIEWS = "RESET_RECIPE_REVIEWS";

//Notifications
export const GET_UNREAD_NOTIFICATIONS = "GET_UNREAD_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_ERROR = "GET_ALL_NOTIFICATIONS_ERROR";
export const GET_UNREAD_NOTIFICATIONS_ERROR = "GET_UNREAD_NOTIFICATIONS_ERROR";
export const RESET_ALL_NOTIFICATIONS = "RESET_ALL_NOTIFICATIONS";

//Navigation
export const TOGGLE_CREATE_MODAL = "TOGGLE_CREATE_MODAL";
export const TOGGLE_KITCHEN_DASHBOARD_MODAL = "TOGGLE_KITCHEN_DASHBOARD_MODAL";
export const NAVIGATE = "NAVIGATE";
export const NAVIGATE_RESET = "NAVIGATE_RESET";

//Plus
export const TOGGLE_PLUS_UPGRADE_MODAL = "TOGGLE_PLUS_UPGRADE_MODAL";

//Device
export const GET_DEVICE_INFO = "GET_DEVICE_INFO";
export const DEVICE_INFO_ERROR = "DEVICE_INFO_ERROR";
