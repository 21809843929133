import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

//UI Components
import Searchable from "../../../layout/searchable/Searchable";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

//Actions
import {
  resetCookbookFilters,
  setAllergenFilters,
  setDietFilters,
  setExcludeIngredientFilters,
  setIncludeIngredientFilters,
  setIncludeIngredientsType,
} from "../../../../actions/cookbook";

//Icons
import { ReactComponent as LinkIcon } from "../../../../assets/icons/svg/link.svg";
import { ReactComponent as FiltersIcon } from "../../../../assets/icons/svg/sliders-light.svg";

const FiltersModal = ({
  handleClose,
  cookbook: { search },
  resetCookbookFilters,
  setAllergenFilters,
  setDietFilters,
  setExcludeIngredientFilters,
  setIncludeIngredientFilters,
  setIncludeIngredientsType,
}) => {
  const onAllergenChange = (items) => {
    setAllergenFilters(items);
  };

  const onDietChange = (items) => {
    setDietFilters(items);
  };

  const onIncludeChange = (items) => {
    setIncludeIngredientFilters(items);
  };

  const onExcludeChange = (items) => {
    setExcludeIngredientFilters(items);
  };

  const handleResetFilters = () => {
    resetCookbookFilters();
  };

  const getMatchTypeLabel = (value) => {
    if (value) {
      return "Recipes must include at least ALL of the included ingredients";
    } else {
      return "Recipes must include at least one of the included ingredients";
    }
  };

  return (
    <div className="flex flex-col p-4 pt-0 max-h-[85vh] overflow-y-auto">
      <div className="mb-8 flex flex-col gap-4">
        <div>
          <div className="flex flex-row items-center gap-2 mb-1">
            <Typography variant="h6" className="font-normal leading-none">
              Search Filters
            </Typography>
            <FiltersIcon className="fill-primaryText h-[1rem]" />
          </div>
          <div className="flex flex-col mt-2">
            <Typography variant="subtitle2" className="text-secondaryText font-normal text-xs">
              Your{" "}
              <Link to="/settings/dietary" onClick={() => handleClose()}>
                <span className=" text-mainGreen font-medium">dietary preferences</span>
              </Link>{" "}
              are added by default. You can edit them{" "}
              <Link to="/settings/dietary" onClick={() => handleClose()}>
                <span className=" text-mainGreen font-medium">here</span>
              </Link>
              .
            </Typography>
          </div>
        </div>

        <div>
          <Button
            className="text-primaryText"
            onClick={() => handleResetFilters()}
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            disableRipple
          >
            Clear Filters
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-4 pb-8">
        <Typography variant="subtitle1" className="font-normal">
          Ingredients:
        </Typography>
        <div>
          <Searchable
            label="Exclude"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            allowAdd={true}
            onItemChange={onExcludeChange}
            selectedItemsFromParent={search.filters.exclude}
            helperText="Recipe must not include any of these ingredients"
          />
        </div>
        <div>
          <Searchable
            label="Include"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            allowAdd={true}
            onItemChange={onIncludeChange}
            selectedItemsFromParent={search.filters.include}
            helperText={getMatchTypeLabel(search?.filters?.includeMatchAll)}
          />
          <div className="">
            <div className="px-2">
              <FormControlLabel
                control={
                  <Switch
                    checked={search.filters.includeMatchAll}
                    onChange={(e) => setIncludeIngredientsType(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle2" className="text-secondaryText-600 px-2 font-normal">
                    Include All
                  </Typography>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mb-4">
        <Typography variant="subtitle1" className="font-normal">
          Dietary:
        </Typography>
        <div>
          <Searchable
            label="Allergens"
            labelWidth={85}
            maxItems={30}
            multiple={true}
            freeSolo={true}
            apiUrl="/allergens/searchable"
            allowAdd={true}
            onItemChange={onAllergenChange}
            selectedItemsFromParent={search.filters.allergies}
            helperText="Avoid recipes with these allergens listed"
          />
        </div>
        <div>
          <Searchable
            label="Special Diets"
            labelWidth={115}
            multiple={true}
            maxItems={30}
            freeSolo={true}
            apiUrl="/diets/searchable"
            allowAdd={true}
            onItemChange={onDietChange}
            selectedItemsFromParent={search.filters.diets}
            helperText="Prefer recipes for these friendly diets"
          />
        </div>
      </div>

      <div className="py-4">
        <Button
          disableElevation
          variant="contained"
          color="primary"
          className="bg-mainGreen text-background normal-case"
          onClick={handleClose}
          fullWidth
        >
          Set Filters
        </Button>
      </div>
    </div>
  );
};

FiltersModal.propTypes = {
  handleClose: PropTypes.func,
  cookbook: PropTypes.object.isRequired,
  resetCookbookFilters: PropTypes.func.isRequired,
  setAllergenFilters: PropTypes.func.isRequired,
  setDietFilters: PropTypes.func.isRequired,
  setIncludeIngredientFilters: PropTypes.func.isRequired,
  setExcludeIngredientFilters: PropTypes.func.isRequired,
  setIncludeIngredientsType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cookbook: state.cookbook,
});

export default connect(mapStateToProps, {
  resetCookbookFilters,
  setAllergenFilters,
  setDietFilters,
  setIncludeIngredientFilters,
  setExcludeIngredientFilters,
  setIncludeIngredientsType,
})(FiltersModal);
