import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, TextField, Button } from "@mui/material";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../ui/dropdown-menu";
import { ChevronDown } from "lucide-react";

import {
  scaleRecipe,
  scaleSection,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
} from "../../../../../actions/walkthrough";

const ServingsModal = ({
  section,
  sectionIndex,
  scaleRecipe,
  scaleSection,
  handleClose,
  errors,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
}) => {
  const [servings, setServings] = useState(section.servings || 1);
  const [scaleType, setScaleType] = useState("section");

  const handleServingsChange = (e) => {
    const value = e.target.value;

    let hasErrors = false;
    let preventSetValue = false;
    if (value === "") {
      setServings("");
      return;
    }

    if (isNaN(value)) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.servings`, [{ msg: "Servings must be a number" }]);
      hasErrors = true;
      preventSetValue = true;
    }

    const numValue = parseFloat(value);
    if (numValue <= 0) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.servings`, [{ msg: "Servings must be greater than 0" }]);
      hasErrors = true;
      preventSetValue = true;
    }

    if (numValue > 100) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.servings`, [
        { msg: "Servings must be less than or equal to 100" },
      ]);
      hasErrors = true;
      preventSetValue = true;
    }

    if (!hasErrors) {
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.servings`);
    }

    if (!preventSetValue) {
      setServings(numValue && numValue % 1 === 0 ? numValue : numValue.toFixed(1));
    }
  };

  const handleScaleTypeChange = (event) => {
    setScaleType(event.target.value);
  };

  const handleSubmit = () => {
    const scaleFactor = servings / (section.servings || 1);

    if (scaleType === "section") {
      scaleSection(sectionIndex, scaleFactor);
    } else if (scaleType === "recipe") {
      scaleRecipe(scaleFactor);
    }
    handleClose();
  };

  const calculateScaleFactor = () => {
    if (!servings || servings === 0) {
      return 1;
    }
    return servings / (section.servings || 1);
  };

  return (
    <div className="w-full max-w-full md:max-w-[600px]">
      <div className="w-full p-4 bg-neutral-100">
        <Typography variant="h6" className="font-normal">
          {section.title || "Edit Servings"}
        </Typography>
      </div>

      <div className="p-4">
        <div className="pt-2">
          <TextField
            label="Servings Made"
            value={servings}
            onChange={handleServingsChange}
            inputProps={{
              maxLength: 3,
            }}
            fullWidth
            error={!!errors?.sections?.[sectionIndex]?.servings}
            helperText={errors?.sections?.[sectionIndex]?.servings?.[0]?.msg}
          />
          <div className="mt-4">
            <Typography variant="subtitle1" className="font-normal leading-snug">
              Serving Size:
            </Typography>
            <Typography variant="subtitle2" className="font-normal leading-snug text-secondaryText-600 mb-4">
              {section.servingSize}
            </Typography>
          </div>
        </div>
        {calculateScaleFactor() !== 1 && (
          <div className="w-full pb-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outlined"
                  className="w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                >
                  {scaleType === "none"
                    ? "Edit Servings Only"
                    : `Scale ${
                        scaleType.charAt(0).toUpperCase() + scaleType.slice(1)
                      } (${calculateScaleFactor().toFixed(2)}x)`}
                  <ChevronDown className="w-3 opacity-50 ml-2" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                <DropdownMenuItem
                  onSelect={() => handleScaleTypeChange({ target: { value: "none" } })}
                  className="text-secondaryText-600/90"
                >
                  Edit Servings Only
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleScaleTypeChange({ target: { value: "section" } })}>
                  Scale Section ({calculateScaleFactor().toFixed(2)}x)
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleScaleTypeChange({ target: { value: "recipe" } })}>
                  Scale Recipe ({calculateScaleFactor().toFixed(2)}x)
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
        <div className="flex justify-end">
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!servings || servings <= 0}
            fullWidth
            disableElevation
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

ServingsModal.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  scaleRecipe: PropTypes.func.isRequired,
  scaleSection: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setWalkthroughErrorByPath: PropTypes.func.isRequired,
  clearWalkthroughErrorByPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.walkthrough.errors,
});

export default connect(mapStateToProps, {
  scaleRecipe,
  scaleSection,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
})(ServingsModal);
