import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import Typography from "@mui/material/Typography";

const ComingSoonFeature = ({ icon, title, description, bulletPoints, screenshots, isReversed = false }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "-100px 0px",
  });

  return (
    <div
      ref={ref}
      className={`flex flex-col ${
        isReversed ? "xl:flex-row-reverse" : "xl:flex-row"
      } items-start xl:items-center gap-8`}
    >
      <div className="w-full h-[350px] sm:h-[400px] md:h-[500px] flex justify-center items-center">
        <div className="relative h-full flex justify-center items-center">
          <motion.img
            src={screenshots[0]}
            alt={`${title} screenshot 1`}
            className="h-full w-auto max-w-[45%] object-contain relative z-10 drop-shadow-xl translate-x-[-5%] hover:cursor-pointer"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
          />
          <motion.img
            src={screenshots[1]}
            alt={`${title} screenshot 2`}
            className="h-[90%] w-auto max-w-[40%] object-contain relative drop-shadow-xl translate-x-[10%] hover:cursor-pointer"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
          />
        </div>
      </div>
      <div className="flex items-start gap-4">
        <div>
          <div className="flex flex-row items-center gap-2">
            <div>{icon}</div>
            <Typography variant="h6" className="text-">
              {title}
            </Typography>
          </div>

          <Typography variant="subtitle1" className="text-secondaryText-700 leading-tight font-normal">
            {description}
          </Typography>
          <ul className="list-disc list-inside text-secondaryText-700 mt-4">
            {bulletPoints.map((point, index) => (
              <li key={index} className="leading-tight text-sm mb-1">
                {point}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonFeature;
