import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button, IconButton } from "@mui/material";

import ReactPlayer from "react-player";
import CustomModal from "../../../../modal/CustomModal";
import TimingModal from "../modals/TimingModal";

import { ReactComponent as VideoPlayIcon } from "../../../../../assets/icons/svg/clapperboard-play-solid.svg";

const SectionSteps = ({ section, sectionIndex }) => {
  const [hasAdditionalTiming, setHasAdditionalTiming] = useState(false);
  const [showTimingModal, setShowTimingModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleTimingModalClose = () => {
    setShowTimingModal(false);
  };

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  const checkAdditionalTiming = () => {
    if (section.timing) {
      for (const key in section.timing) {
        if (key !== "total") {
          const timing = section.timing[key];
          if ((timing.minutes && timing.minutes > 0) || (timing.hours && timing.hours > 0)) {
            setHasAdditionalTiming(true);
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    checkAdditionalTiming();
  }, [section]);
  return (
    <div className="w-full md:rounded-lg md:border md:border-separator bg-background">
      <CustomModal
        open={showVideoModal}
        handleClose={handleVideoModalClose}
        contentClassNameOverride="w-fit max-w-fit min-w-fit md:max-w-fit md:min-w-fit"
        type="bottom"
      >
        <ReactPlayer url={section?.video} />
      </CustomModal>
      <CustomModal
        open={showTimingModal}
        handleClose={handleTimingModalClose}
        contentClassNameOverride="w-full md:max-w-[600px]"
        type="bottom"
      >
        <TimingModal timing={section?.timing} title={section?.title ? section?.title : "Timing"} />
      </CustomModal>
      <div className="flex flex-col">
        <div className="w-full bg-neutral-100 px-4 md:px-8 py-3 md:rounded-t-lg md:border-b md:border-separator flex flex-row gap-2 items-center">
          <Typography variant="subtitle1" className="font-normal">
            Steps:
          </Typography>
          {section?.video && ReactPlayer.canPlay(section?.video) && (
            <IconButton
              onClick={() => setShowVideoModal(true)}
              className="py-0 px-2 hover:bg-transparent"
              disableRipple
            >
              <VideoPlayIcon className="h-4 fill-mainGreen-600" />
            </IconButton>
          )}
        </div>

        {section?.timing &&
          section?.timing?.total &&
          (section?.timing?.total?.hours > 0 || section?.timing?.total?.minutes > 0) && (
            <div className="px-4 md:px-8 w-full border-b border-separator py-4 bg-background">
              <div className="flex flex-col gap-1">
                <Typography variant="subtitle2" component="p" className="font-normal leading-snug">
                  {` ${section.timing.total.hours > 0 ? `${section.timing.total.hours} hrs ` : ""}${
                    section.timing.total.minutes
                  } mins`}
                </Typography>
                {hasAdditionalTiming && (
                  <Button
                    className="p-0 hover:bg-transparent items-start justify-start"
                    disableRipple
                    onClick={() => setShowTimingModal(true)}
                  >
                    <Typography variant="subtitle2" className="text-mainGreen leading-none text-xs font-medium">
                      View Timing
                    </Typography>
                  </Button>
                )}
              </div>
            </div>
          )}
      </div>
      <div className="px-4 md:px-8 py-6">
        {section && section?.steps ? (
          section.steps.map((step, i) => {
            return (
              <div className="flex flex-row mb-2">
                <div>
                  <Typography variant="subtitle1" className="w-[1.5rem] leading-[1.5rem] text-mainGreen font-medium">
                    {i + 1}.
                  </Typography>
                </div>
                <div>
                  <div>
                    <Typography variant="body1">{step.text}</Typography>
                  </div>
                  {step && step.tip && (
                    <div className="text-secondaryText mt-1">
                      <Typography variant="body1">Tip: {step.tip}</Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <Typography>No steps</Typography>
        )}
      </div>
    </div>
  );
};

SectionSteps.propTypes = {
  walkthrough: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, {})(SectionSteps);
