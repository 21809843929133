import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from "../../api/api";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//Actions
import { logout } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

//MUI
import { Button, Typography, Divider } from "@mui/material";

//Icons
import { ReactComponent as ChevronRight } from "../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/settings.svg";

import InAppPurchaseService from "../../services/native/InAppPurchaseService.ts";

const Settings = ({ logout, device: { platform }, auth: { user, isPlus } }) => {
  const handleCopyLink = () => {
    const postLink = ``;
    navigator.clipboard.writeText(window.location.origin + postLink);
    setAlert("Link copied to clipboard", "success");
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Post by @username`,
          text: `Post by @username`,
          url: `${window.location.origin}/username/post/$postid`,
        });
      } catch (error) {
        handleCopyLink();
        setAlert("Link copied to clipboard", "success");
      }
    } else {
      handleCopyLink();
      setAlert("Link copied to clipboard", "success");
      setAlert("Web Share API is not supported in your browser", "warning");
    }
  };

  const [restoring, setRestoring] = useState(false);
  const handleRestoreSubscription = async () => {
    try {
      setRestoring(true);
      const restored = await InAppPurchaseService.restorePurchases();

      if (restored) {
        setAlert("thisPantry+ Subscription Restored!", "success");
      } else {
        setAlert("Failed to restore thisPantry+ Subscription. Please try again.", "error");
      }
    } catch (error) {
      console.error("Failed to restore purchases:", error);
      setAlert("Failed to restore thisPantry+ Subscription. Please try again.", "error");
    } finally {
      setRestoring(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:ml-[5rem]">
          <div className="px-4 py-2 md:px-8 md:py-4 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="flex flex-row mt-2">
                <div className="mr-4 mt-1">
                  <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                </div>
                <div className="mb-4">
                  <Typography variant="h6" className="font-normal leading-snug mb-1">
                    Settings
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                    Manage your thisPantry account
                  </Typography>
                </div>
              </div>
            </div>
            <Divider />
            <div className="my-4">
              <Link to="/settings/account">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="w-full">
                    <Typography variant="subtitle1" className="leading-snug font-normal">
                      My Account
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                      Account Information and Deactivation Options
                    </Typography>
                  </div>
                  <div className="ml-4">
                    <ChevronRight className="h-[1rem] text-primaryText " />
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <Divider />
              </div>
            </div>
            <div className="my-4">
              <Link to="/settings/notifications">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="w-full">
                    <Typography variant="subtitle1" className="leading-snug font-normal">
                      Notification Settings
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                      Change Your Email and Manage Notifications
                    </Typography>
                  </div>
                  <div className="ml-4">
                    <ChevronRight className="h-[1rem] text-primaryText " />
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <Divider />
              </div>
            </div>
            <div className="my-4">
              <Link to="/settings/dietary">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="w-full">
                    <Typography variant="subtitle1" className="leading-snug font-normal">
                      Dietary Settings
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                      View and Edit Your Dietary Information
                    </Typography>
                  </div>
                  <div className="ml-4">
                    <ChevronRight className="h-[1rem] text-primaryText " />
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <Divider />
              </div>
            </div>
            <div className="my-4">
              <Link to="/settings/blocked">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="w-full">
                    <Typography variant="subtitle1" className="leading-snug font-normal">
                      Blocked Users
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                      View and Edit Your Blocked Users
                    </Typography>
                  </div>
                  <div className="ml-4">
                    <ChevronRight className="h-[1rem] text-primaryText " />
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <Divider />
              </div>
            </div>
            <div className="my-4">
              <Link to="/settings/policies">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="w-full">
                    <Typography variant="subtitle1" className="leading-snug font-normal">
                      Legal and Privacy
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                      Review Policies, Terms, and Cookie Information
                    </Typography>
                  </div>
                  <div className="ml-4">
                    <ChevronRight className="h-[1rem] text-primaryText " />
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <Divider />
              </div>
            </div>
            <div className="my-4">
              <Link to="/contact">
                <div className="w-full flex flex-row justify-between items-center">
                  <div className="w-full">
                    <Typography variant="subtitle1" className="leading-snug font-normal">
                      Help/Contact Us
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                      Get Help, Report a Bug, or Contact Us
                    </Typography>
                  </div>
                  <div className="ml-4">
                    <ChevronRight className="h-[1rem] text-primaryText " />
                  </div>
                </div>
              </Link>
              <div className="mt-4">
                <Divider />
              </div>
            </div>

            <div className="my-4">
              {isPlus ? (
                <Link to="/settings/subscription">
                  <div className="w-full flex flex-row justify-between items-center">
                    <div className="w-full flex flex-col justify-center">
                      <Typography variant="subtitle1" className="leading-snug font-normal">
                        thisPantry<span className="text-mainGreen font-semibold">+</span>
                      </Typography>
                      <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                        Manage your subscription and view features
                      </Typography>
                    </div>
                    <div className="ml-4">
                      <ChevronRight className="h-[1rem] text-primaryText " />
                    </div>
                  </div>
                </Link>
              ) : (
                <div className="my-4 flex flex-col gap-4">
                  <Link to="/plus">
                    <div className="w-full flex flex-row justify-between items-center">
                      <div className="w-full">
                        <Typography variant="subtitle1" className="leading-snug font-normal">
                          thisPantry<span className="text-mainGreen font-semibold">+</span>
                        </Typography>
                        <Typography variant="subtitle2" className="text-secondaryText mt-1 leading-snug font-normal">
                          Generate recipes, get detailed insights, and more
                        </Typography>
                      </div>
                      <div className="ml-4">
                        <ChevronRight className="h-[1rem] text-primaryText " />
                      </div>
                    </div>
                  </Link>
                  {platform === "ios" && (
                    <>
                      <Divider />
                      <Button
                        onClick={handleRestoreSubscription}
                        className="w-full p-0 hover:bg-transparent"
                        disableRipple
                        disableElevation
                        disabled={restoring}
                      >
                        <div className="w-full flex flex-row justify-between items-center">
                          <div className="w-full flex flex-col items-start text-start">
                            <Typography variant="subtitle1" className="leading-snug font-normal">
                              Restore Subscription
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              className="text-secondaryText mt-1 leading-snug font-normal"
                            >
                              Synchronize your subscription if you have purchased one with your Apple ID
                            </Typography>
                          </div>
                          <div className="ml-4">
                            <ChevronRight className="h-[1rem] text-primaryText " />
                          </div>
                        </div>
                      </Button>
                      <Divider />
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="mt-24 mb-4">
              <Button
                variant="contained"
                fullWidth
                disableRipple
                disableElevation
                onClick={logout}
                className="bg-primaryText text-background hover:bg-primaryText/90"
              >
                <Typography variant="subtitle1" className="leading-snug font-normal text-sm">
                  Log Out
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Settings.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  device: state.device,
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Settings);
