import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import { removeAlert } from "../../actions/alert";

const Alerts = ({ alerts, isAuthenticated, removeAlert }) => {
  //TO DO:
  //Alert types -> error, warning, info, success
  //onClose functionality
  //action funcitonality (eg. undo) with title
  //Mounting and closing transitions
  //Mobile and desktop styling, auth/no auth versions too?
  return (
    <div className="fixed top-[4rem] md:bottom-0 right-0 z-[9999] p-4 w-full md:max-w-[500px] pointer-events-none">
      {alert !== null &&
        alerts.length > 0 &&
        alerts.map((alert) => {
          if (alert.action) {
            return (
              <Alert
                key={alert.id}
                severity={alert.alertType}
                action={
                  <Button color="inherit" size="small" onClick={alert.action.callback} variant="outlined">
                    {alert.action.text}
                  </Button>
                }
                variant="outlined"
                className="mb-2 md:mb-4"
              >
                <Typography variant="body1">{alert.msg}</Typography>
              </Alert>
            );
          } else if (alert.onClose) {
            return (
              <Alert
                key={alert.id}
                severity={alert.alertType}
                onClose={alert.onClose}
                className="mb-2 md:mb-4"
                variant="outlined"
              >
                <Typography variant="body1">{alert.msg}</Typography>
              </Alert>
            );
          } else {
            return (
              <Alert key={alert.id} severity={alert.alertType} className="mb-[4rem] md:mb-4" variant="outlined">
                <Typography variant="body1">{alert.msg}</Typography>
              </Alert>
            );
          }
        })}
    </div>
  );
};

//Add redux states that are props
Alerts.propTypes = {
  alerts: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

//Get Alert State and map to a property
const mapStateToProps = (state) => ({
  alerts: state.alert,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { removeAlert })(Alerts);
