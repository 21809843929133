import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { showRegisterModal } from "../../../actions/auth";

import { Typography, Button, List, ListItem, ListItemIcon } from "@mui/material";
import { ReactComponent as CheckIcon } from "../../../assets/icons/svg/circle-check.svg";
import { Tabs, TabsTrigger, TabsList } from "../../ui/tabs";

const NoAuth = ({ showRegisterModal }) => {
  const [savings, setSavings] = useState("");
  const [term, setTerm] = useState("monthly");

  const handleTabChange = (value) => {
    setTerm(value);
  };

  const getTerm = () => {
    switch (term) {
      case "monthly":
        return "month";
      case "weekly":
        return "week";
      case "annual":
        return "year";
      default:
        return "term";
    }
  };

  const getDisplayPrice = () => {
    switch (term) {
      case "annual":
        return "$119.99";
      case "monthly":
        return "$11.99";
      case "weekly":
        return "$5.99";
    }
  };

  const handlePurchaseAttemptClick = () => {
    showRegisterModal();
  };

  return (
    <div className="w-full h-fit max-w-lg mx-auto">
      <div className="flex flex-col gap-4">
        <div className="sm:px-8">
          <Tabs value={term} onValueChange={(value) => handleTabChange(value)} className="w-full">
            <TabsList className="w-full border-x-0 border-y sm:border border-secondaryText-100/50 bg-primaryText p-0 rounded-none sm:rounded-lg">
              <TabsTrigger
                value="weekly"
                className="w-full h-full text-white/90 data-[state=active]:bg-mainGreen data-[state=active]:text-white rounded-none sm:rounded-md"
              >
                Weekly
              </TabsTrigger>
              <TabsTrigger
                value="monthly"
                className="w-full h-full text-white/90 data-[state=active]:bg-mainGreen data-[state=active]:text-white rounded-none sm:rounded-md"
              >
                Monthly
              </TabsTrigger>
              <TabsTrigger
                value="annual"
                className="w-full h-full text-white/90 data-[state=active]:bg-mainGreen data-[state=active]:text-white rounded-none sm:rounded-md"
              >
                Annual
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <div className="px-4 sm:px-8">
          <div>
            <Typography variant="h5" className="text-background mb-8 my-4 font-medium">
              thisPantry+ Subscription
            </Typography>
          </div>
          <div className="py-4">
            <div className="flex flex-row items-end">
              <Typography variant="h4" className="text-background font-semibold leading-none mr-1">
                {getDisplayPrice()}
              </Typography>
              <Typography className="subtitle2 text-secondaryText-100 leading-snug text-xs">
                per
                <br></br>
                {getTerm()}
              </Typography>
            </div>
            {savings !== "" && (
              <div className="mt-2">
                <Typography variant="subtitle2" className="font-normal text-white">
                  Save <span className="font-semibold">{savings}</span>!
                </Typography>
              </div>
            )}
          </div>

          <Button variant="contained" color="primary" disableElevation fullWidth onClick={handlePurchaseAttemptClick}>
            Start Trial
          </Button>
          <div className="pt-8">
            <Typography variant="subtitle2" className="text-secondaryText-100 font-normal">
              This includes:
            </Typography>
            <List>
              <ListItem className="py-1 px-2">
                <ListItemIcon className="w-6 min-w-6 h-4">
                  <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
                </ListItemIcon>
                <Typography variant="subtitle2" className="text-xs text-white font-normal">
                  Generate Recipes with thisPantry+ AI
                </Typography>
              </ListItem>
              <ListItem className="py-1 px-2">
                <ListItemIcon className="w-6 min-w-6 h-4">
                  <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
                </ListItemIcon>
                <Typography variant="subtitle2" className="text-xs text-white font-normal">
                  Exclusive access to the newest features first
                </Typography>
              </ListItem>
              <ListItem className="py-1 px-2">
                <ListItemIcon className="w-6 min-w-6 h-4">
                  <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
                </ListItemIcon>
                <Typography variant="subtitle2" className="text-xs text-white font-normal">
                  Direct line to our developers
                </Typography>
              </ListItem>
              <ListItem className="py-1 px-2">
                <ListItemIcon className="w-6 min-w-6 h-4">
                  <CheckIcon className="w-3 h-auto fill-secondaryText-100" />
                </ListItemIcon>
                <Typography variant="subtitle2" className="text-xs text-white font-normal">
                  Keep our launch pricing forever
                </Typography>
              </ListItem>
            </List>
            <div className="mt-4 text-center">
              <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug font-normal text-xs">
                All plans include a 7-day free trial.
              </Typography>
              <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug font-normal text-xs">
                Cancel anytime.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NoAuth.propTypes = {
  showRegisterModal: PropTypes.func.isRequired,
};

export default connect(null, { showRegisterModal })(NoAuth);
