import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../api/api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import Spinner from "../layout/Spinner";

//Assets
import BackgroundImage from "../plus/res/plus/banana.jpg";
import { ReactComponent as PlusLogo } from "../../assets/logos/plus/pluslogo.svg";
import { ReactComponent as PlusLogoOnGreen } from "../../assets/logos/plus/pluslogo-duodark.svg";

//Screenshots
import InsightsScreenshot1 from "../landing/img/savings/dashboard.png";
import InsightsScreenshot2 from "../landing/img/savings/highlight.png";
import PantryScreenshot1 from "../landing/img/pantry/3/pantry.png";
import PantryScreenshot2 from "../landing/img/pantry/1/pantry.png";

//Icons
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as ChefIcon } from "../../assets/icons/svg/chef-sharp-light.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/svg/discord.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/svg/home.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons/svg/arrow-down-to-line-light.svg";
import { ReactComponent as GenerateIcon } from "../../assets/icons/svg/magic-wand-solid.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/svg/chart-line.svg";
import { ReactComponent as PantryIcon } from "../../assets/icons/svg/carrot.svg";

//Actions
import { loadUser } from "../../actions/auth";

//Components
import ComingSoonFeature from "../plus/ComingSoonFeature";
import GeneralError from "../static/GeneralError";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionSuccessPage = ({ platform, loadUser }) => {
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  const location = useLocation();
  const stripe = useStripe();

  useEffect(() => {
    if (platform === "ios") {
      loadUser();
      setStatus("success");
      setError(null);
      return;
    } else {
      if (!stripe) {
        return;
      }

      const clientSecret = new URLSearchParams(location.search).get("payment_intent_client_secret");

      if (!clientSecret) {
        setStatus("error");
        setError("No client secret found in URL");
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setStatus("success");
            break;
          case "processing":
            setStatus("processing");
            break;
          case "requires_payment_method":
            setStatus("error");
            setError("Payment failed. Please try another payment method.");
            break;
          default:
            setStatus("error");
            setError("Something went wrong.");
            break;
        }
      });
    }
  }, [stripe, location.search]);

  if (status === "loading") {
    return (
      <div className="w-full mx-auto py-[4rem] md:py-0 md:pl-[5rem]">
        <Spinner />
      </div>
    );
  }

  if (status === "error") {
    return <GeneralError errorText={error} link="/plus" linkText="Go Back" />;
  }

  //TODO:
  //Generate recipe
  //Join discord

  return (
    <div className="w-full mx-auto py-[4rem] md:py-0 md:pl-[5rem] ">
      <div className="w-full bg-mainGreen p-4 py-8 sm:p-8 lg:px-24 relative overflow-hidden flex items-end">
        <div className="w-full flex flex-col gap-4 md:py-8">
          <div className="w-full flex items-start justify-start xl:items-end z-10">
            <div></div>
          </div>
          <div className="w-full flex justify-end">
            <div className="w-full flex flex-col gap-4 md:gap-8 ">
              <div className="w-full flex flex-col items-start lg:pr-[20%] justify-center z-10">
                <Typography variant="h2" className="font-normal leading-[0.9] text-background">
                  LET'S
                </Typography>
                <Typography variant="h2" className="font-normal leading-[0.9] text-background">
                  GET
                </Typography>
                <Typography variant="h2" className="font-normal leading-[0.9] text-background">
                  COOKIN'
                </Typography>
                <Typography variant="h2" className="font-bold leading-[0.9] text-primaryText">
                  GOOD
                </Typography>
                <Typography variant="h2" className="font-bold leading-[0.9] text-primaryText">
                  LOOKIN'
                </Typography>
              </div>
              <div className="flex flex-col items-end justify-end z-10">
                <Typography variant="h4" className="text-background text-xl md:text-2xl font-medium">
                  {status === "processing" ? "Payment processing, welcome to" : "Welcome to"}
                </Typography>
                <PlusLogoOnGreen className="h-[4rem] md:h-24 w-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[7vh] bg-[#f3c25d]">
        <img src={BackgroundImage} className="w-full h-full object-cover" />
      </div>

      <div className="flex p-4 sm:p-8 sm:py-12 py-12 md:py-24 bg-stone-800 items-start  mx-auto">
        <div className="flex flex-col gap-8 w-full max-w-4xl mx-auto items-center">
          <div className="">
            <div className="w-full mb-8">
              <Typography variant="h5" className="text-2xl xl:text-3xl text-background">
                What's next?
              </Typography>
              <Typography variant="subtitle1" className="text-secondaryText-200 leading-tight font-normal">
                Get the most out of your kitchen with these features:
              </Typography>
            </div>

            <div className="w-full flex flex-col gap-2 max-w-[32rem]">
              <NavigationOption
                icon={<GenerateIcon className="h-6 fill-primaryText" />}
                title="Generate a Recipe"
                description="Create your next favorite meal with our AI-powered recipe generator."
                link="/create/recipe?type=generate"
              />

              <NavigationOption
                icon={<ImportIcon className="h-6 fill-primaryText" />}
                title="Import a Recipe"
                description="Fill out your cookbook with your favorite recipes from around the web."
                link="/create/recipe?type=import"
              />
              <div className="w-full flex flex-row gap-4 p-4 items-center border border-separator shadow-sm rounded-xl bg-background">
                <div className="w-12 flex justify-center items-center">
                  <DiscordIcon className="h-6 fill-discord"></DiscordIcon>
                </div>
                <a
                  href="https://discord.gg/YyYNRzjK"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full flex-1"
                >
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <Typography variant="subtitle1" className="font-normal leading-none">
                        Join the Discord
                      </Typography>
                      <LinkIcon className="w-3 fill-secondaryText" />
                    </div>
                    <div>
                      <Typography variant="body2" className="font-normal leading-none text-secondaryText-600 max-w-fit">
                        Say hello to other thisPantry+ users and get updates on our latest features and improvements
                        before release!
                      </Typography>
                    </div>
                  </div>
                </a>
              </div>
              <NavigationOption
                icon={<HomeIcon className="h-6 fill-secondaryText-600" />}
                title="Home"
                description="Go back to the home page."
                link="/"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[7vh] bg-[#f3c25d]">
        <img src={BackgroundImage} className="w-full h-full object-cover" />
      </div>

      <div className="px-4 sm:px-8 py-16 md:py-24 bg-[#f1efe7]">
        <div className="w-full md:max-w-[70vw] mx-auto">
          <div className="mb-12 md:mb-24">
            <Typography variant="h5" className="text-primaryText">
              <span className="flex flex-col items-start gap-2">
                <span>Coming Soon to</span> <PlusLogo className="h-12 md:h-24 w-auto" />
              </span>
            </Typography>
            <Typography variant="subtitle2" className="text-secondaryText-700 font-normal leading-tight mt-4">
              Features that will be available to <br></br>thisPantry+ members, coming Spring 2025.
            </Typography>
          </div>

          <div className="flex flex-col gap-12">
            <ComingSoonFeature
              icon={<InsightsIcon className="h-6 fill-mainGreen" />}
              title="Insights"
              description="Track your cooking habits, kitchen efficiency, and see how you're saving money over time."
              bulletPoints={[
                "Easily track your grocery and restaurant spending.",
                "Stay on top of your nutritional macros and see how you're doing over time.",
                "Monitor your food waste and save money by reducing spoilage.",
              ]}
              screenshots={[InsightsScreenshot1, InsightsScreenshot2]}
            />
            <Divider />
            <ComingSoonFeature
              icon={<PantryIcon className="h-6 fill-mainGreen" />}
              title="Pantry"
              description="Your pantry, fridge, freezer, all digitized to keep you up to date in real-time."
              bulletPoints={[
                "Automatically track all of your expiration dates",
                "Get tailored recipes based on what you have and what you like, before your ingredients expire.",
                "Easily add ingredients by scanning receipts, barcodes, or by voice.",
              ]}
              screenshots={[PantryScreenshot1, PantryScreenshot2]}
              isReversed={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const NavigationOption = ({ icon, title, description, link }) => {
  return (
    <Link to={link} className="w-full flex-1">
      <div className="w-full flex flex-row  gap-4 p-4 items-center border border-separator shadow-sm rounded-xl bg-background">
        <div className="w-12 flex justify-center items-center">{icon}</div>

        <div>
          <div className="flex flex-col text-start gap-2">
            <Typography variant="subtitle1" className="font-number leading-none">
              {title}
            </Typography>
            {description && (
              <Typography variant="body2" className="font-normal leading-none text-secondaryText-600 max-w-fit">
                {description}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

const SubscriptionSuccessWrapper = ({ device: { platform }, loadUser }) => {
  return (
    <>
      <Helmet>
        <title>Welcome to thisPantry+!</title>
      </Helmet>
      <Elements stripe={stripePromise}>
        <SubscriptionSuccessPage platform={platform} loadUser={loadUser} />
      </Elements>
    </>
  );
};

const mapStateToProps = (state) => ({
  device: state.device,
});

export default connect(mapStateToProps, { loadUser })(SubscriptionSuccessWrapper);
