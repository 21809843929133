import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Typography, Button, Divider } from "@mui/material";
import Footer from "../layout/Footer";

import { preventWidows } from "../../utils/text";
import api from "../../api/api";

// Images
import InsightsScreenshot1 from "../landing/img/savings/dashboard.png";
import InsightsScreenshot2 from "../landing/img/savings/highlight.png";
import PantryScreenshot1 from "../landing/img/pantry/3/pantry.png";
import PantryScreenshot2 from "../landing/img/pantry/1/pantry.png";

// Icons
import { ReactComponent as NewFeaturesIcon } from "../../assets/icons/svg/tag-light.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/svg/chart-line.svg";
import { ReactComponent as PantryIcon } from "../../assets/icons/svg/carrot.svg";
import { ReactComponent as ChefIcon } from "../../assets/icons/svg/chef-sharp-light.svg";
import { ReactComponent as DevIcon } from "../../assets/icons/svg/phone-light.svg";
import { ReactComponent as PriceIcon } from "../../assets/icons/svg/badge-check-light.svg";

// Images
import PlusImage from "./res/plus/banana.jpg";
import { ReactComponent as PlusLogo } from "../../assets/logos/plus/pluslogo.svg";
import { ReactComponent as PlusLogoGreen } from "../../assets/logos/plus/pluslogogreen.svg";
import SlicedApple from "./res/plus/apple.png";

//Components
import CheckoutPriceSelector from "../stripe/CheckoutPriceSelector";
import ComingSoonFeature from "./ComingSoonFeature";

const PlusPage = ({ auth: { isAuthenticated }, plus: { isPlus }, type = "page" }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/stripe/create-checkout-session");
      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkoutRef = useRef(null);

  const scrollToCheckout = () => {
    checkoutRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className={`bg-background ${
        isAuthenticated && type !== "modal" ? "min-h-screen py-[4rem] md:py-0 md:pl-[5rem]" : "h-full max-h-[85vh]"
      }`}
    >
      <Helmet>
        <title>thisPantry+ - Get more out of your kitchen</title>
      </Helmet>
      <div className="w-full mx-auto">
        <div
          className={`w-full bg-[#f1efe7] p-4 py-8 sm:p-8 lg:px-8 relative overflow-hidden ${
            type === "page" ? "min-h-[60vh]" : ""
          } flex items-end`}
        >
          <div className={`w-full flex flex-col ${type === "page" ? "xl:flex-row" : ""} gap-4 md:py-8`}>
            <div className="w-full flex items-start justify-start xl:items-end z-10">
              <div>
                <PlusLogo className="h-16 xs:h-20 md:h-24 w-auto object-contain max-h-[12vh]" />
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className="w-full xl:max-w-[600px] flex flex-col gap-4 md:gap-8 ">
                <div className="w-full flex flex-col items-start lg:pr-[20%] justify-center z-10">
                  <Typography
                    variant="h6"
                    className=" font-normal text-primaryText text-xl leading-6 md:text-3xl md:leading-8"
                  >
                    GENERATE&nbsp;CUSTOM&nbsp;RECIPES.<br></br>LAUNCH&nbsp;PRICE&nbsp;GUARANTEE.<br></br>
                    TRY&nbsp;NEW&nbsp;FEATURES&nbsp;FIRST.<br></br>
                    DIRECT&nbsp;LINE&nbsp;TO&nbsp;DEVELOPERS.
                  </Typography>
                </div>
                <div className="flex flex-col items-end justify-end z-10">
                  <Typography
                    variant="h6"
                    className=" font-bold text-primaryText text-2xl leading-6 md:text-3xl md:leading-7"
                  >
                    <span className="text-nowrap">
                      social<span className="font-semibold text-mainGreen text-lg md:text-2xl">&nbsp;//&nbsp;</span>
                      pantry&nbsp;
                      <br></br>
                    </span>
                    <span>
                      database&nbsp;<span className="font-semibold text-mainGreen text-lg md:text-2xl">//</span>
                      &nbsp;macro
                    </span>
                    &nbsp;+<br></br>expense tracking&nbsp;
                    <span className="font-semibold text-mainGreen text-lg md:text-2xl">//&nbsp;</span>
                    <br></br>recipe generation
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[7vh] bg-[#f3c25d]">
          <img src={PlusImage} className="w-full h-full object-cover" />
        </div>
        <div ref={checkoutRef}>
          <CheckoutPriceSelector type={type} />
        </div>

        <div className="flex p-4 sm:p-8 py-12 md:py-24 bg-mainGreen-600 items-start  mx-auto">
          <div className="flex flex-col gap-8 w-full max-w-4xl mx-auto items-center">
            <FeatureItem
              title="Generate Custom Recipes"
              description="Personalized recipes that match your pantry items, helping you make the most of what you have."
            />
            <FeatureItem
              title="Exclusive Access to New Features"
              description="Get the inside scoop and be the first to try out new tools and functionalities before they're released to the public."
            />
            <FeatureItem
              title="Direct Line to Developers"
              description="Have a say in how thisPantry evolves by giving feedback directly to the team. Your input shapes the features you'll use every day."
            />
            <FeatureItem
              title="Lifetime Launch Price Guarantee"
              description="The first 1000 plus users get our launch subscription price forever—no future price hikes, ever."
            />
          </div>
        </div>
        <div className="w-full h-[7vh] bg-[#f3c25d]">
          <img src={PlusImage} className="w-full h-full object-cover" />
        </div>
        <div className="px-4 sm:px-8 py-16 md:py-24 bg-[#f1efe7]">
          <div className="w-full md:max-w-[70vw] mx-auto">
            <div className="mb-12 md:mb-24">
              <Typography variant="h5" className="text-primaryText">
                <span className="flex flex-col items-start gap-2">
                  <span>Coming Soon to</span> <PlusLogo className="h-12 md:h-24 w-auto" />
                </span>
              </Typography>
              <Typography variant="subtitle2" className="text-secondaryText-700 font-normal leading-tight mt-4">
                Features that will be available to <br></br>thisPantry+ members, coming Spring 2025.
              </Typography>
            </div>

            <div className="flex flex-col gap-12">
              <ComingSoonFeature
                icon={<InsightsIcon className="h-6 fill-mainGreen" />}
                title="Insights"
                description="Track your cooking habits, kitchen efficiency, and see how you're saving money over time."
                bulletPoints={[
                  "Easily track your grocery and restaurant spending.",
                  "Stay on top of your nutritional macros and see how you're doing over time.",
                  "Monitor your food waste and save money by reducing spoilage.",
                ]}
                screenshots={[InsightsScreenshot1, InsightsScreenshot2]}
              />
              <Divider />
              <ComingSoonFeature
                icon={<PantryIcon className="h-6 fill-mainGreen" />}
                title="Pantry"
                description="Your pantry, fridge, freezer, all digitized to keep you up to date in real-time."
                bulletPoints={[
                  "Automatically track all of your expiration dates",
                  "Get tailored recipes based on what you have and what you like, before your ingredients expire.",
                  "Easily add ingredients by scanning receipts, barcodes, or by voice.",
                ]}
                screenshots={[PantryScreenshot1, PantryScreenshot2]}
                isReversed={true}
              />
            </div>
          </div>
        </div>

        {!isPlus && (
          <div className="flex flex-col justify-center py-8 sm:py-16 pt-48 px-4 sm:px-8 bg-primaryText">
            <div className="w-full max-w-xl mx-auto">
              <div className="mb-8">
                <PlusLogoGreen className="h-16 w-auto mb-4" />
                <Typography variant="h5" className="text-white opacity-100">
                  Get in early
                </Typography>
                <Typography variant="subtitle1" className="text-neutral-100 font-normal leading-snug">
                  Lock in our launch subscription price forever — $12/mo
                </Typography>
              </div>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className="px-8 py-3 w-full"
                disableElevation
                onClick={scrollToCheckout}
              >
                <Typography variant="subtitle2" className=" font-normal">
                  Join <span className="font-semibold">thisPantry+</span>
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
      {!isAuthenticated && <Footer navMargin={false} />}
    </div>
  );
};

const FeatureItem = ({ title, description }) => (
  <div className="flex w-full items-start gap-4 mx-4 md:mx-8 max-w-lg">
    <div>
      <div className="flex flex-row items-center gap-3 mb-2">
        <Typography variant="h6" className="leading-none text-background">
          {preventWidows(title)}
        </Typography>
      </div>

      <Typography variant="subtitle1" className="text-white/90 leading-tight font-normal">
        {description}
      </Typography>
    </div>
  </div>
);

PlusPage.propTypes = {
  auth: PropTypes.object.isRequired,
  plus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  plus: state.plus,
});

export default connect(mapStateToProps)(PlusPage);
