import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, TextField, Button } from "@mui/material";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import Searchable from "../../../../layout/searchable/Searchable";

import {
  scaleRecipe,
  scaleSection,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
} from "../../../../../actions/walkthrough";

//Utility Functions
import { decimalToFraction } from "../../../../../utils/recipe";

const YieldModal = ({
  section,
  sectionIndex,
  scaleRecipe,
  scaleSection,
  handleClose,
  errors,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
}) => {
  const [yieldObj, setYieldObj] = useState({
    amount: section.yield?.amount ? section.yield.amount : "",
    unit: section.yield?.unit
      ? { ...section.yield.unit, name: section.yield.unit.name, value: section.yield.unit.name }
      : [],
    ingredient: section.yield?.ingredient
      ? { ...section.yield.ingredient, name: section.yield.ingredient.name, value: section.yield.ingredient.name }
      : [],
  });
  const [scaleType, setScaleType] = useState("recipe");

  const verifyAmount = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    // Handle empty value check first
    if (value === "") {
      return { hasErrors, preventSetValue };
    }

    // Match the same pattern as EditIngredientModal
    const fractionRegex =
      /^(\d{1,3}\/\d{1,3})$|^(\d{1,3}\/)$|^(\d{1,3}\s+\d{1,3}\/\d{1,3})$|^(\d{1,3})$|^(\d{1,3}\.\d+)$/;

    if (!fractionRegex.test(value)) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
        { msg: "Amount must be a number or valid fraction" },
      ]);
      hasErrors = true;
      // Don't prevent value while typing
      preventSetValue = false;
    } else {
      // Only validate numeric bounds if we have a complete number
      if (typeof value === "string" && value.includes(" ") && value.includes("/")) {
        // Handle compound fraction (e.g., "1 1/2")
        const [whole, fraction] = value.split(" ");
        const [numerator, denominator] = fraction.split("/");
        const numericValue = parseInt(whole) + parseInt(numerator) / parseInt(denominator);

        if (numericValue > 10000) {
          setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be less than or equal to 10,000" },
          ]);
          preventSetValue = true;
          hasErrors = true;
        } else if (numericValue < 1) {
          setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be greater than or equal to 1" },
          ]);
          hasErrors = true;
        }
      } else if (typeof value === "string" && value.includes("/")) {
        // Handle simple fraction (e.g., "1/2")
        const [numerator, denominator] = value.split("/");
        if (denominator) {
          const fractionValue = parseInt(numerator) / parseInt(denominator);
          if (fractionValue > 10000) {
            setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
              { msg: "Amount must be less than or equal to 10,000" },
            ]);
            preventSetValue = true;
            hasErrors = true;
          } else if (fractionValue < 1) {
            setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
              { msg: "Amount must be greater than or equal to 1" },
            ]);
            hasErrors = true;
          }
        }
      } else if (!isNaN(value)) {
        console.log(value);
        // Handle plain numbers
        const numericValue = parseFloat(value);
        if (numericValue > 10000) {
          setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be less than or equal to 10,000" },
          ]);
          preventSetValue = true;
          hasErrors = true;
        } else if (numericValue < 1) {
          setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be greater than or equal to 1" },
          ]);
          hasErrors = true;
        }
      } else {
        setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [{ msg: "Invalid amount" }]);
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`);
    }
    return { hasErrors, preventSetValue };
  };
  const verifyUnit = (searchableData) => {
    let hasErrors = false;

    if (searchableData.value.length > 32) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldUnit`, [
        { msg: "Yield Unit name must be less than 32 characters" },
      ]);
      hasErrors = true;
    }

    if (searchableData.value.length < 3 && searchableData.value.length > 0) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldUnit`, [
        { msg: "Yield Unit name must be at least 3 characters" },
      ]);
      hasErrors = true;
    }

    if (!hasErrors) {
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldUnit`);
    }

    return hasErrors;
  };

  const verifyIngredient = (searchableData) => {
    let hasErrors = false;
    if (searchableData.value.length > 64) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`, [
        { msg: "Yield Ingredient name must be less than 64 characters" },
      ]);
      hasErrors = true;
    }

    if (searchableData.value.length < 3 && searchableData.value.length > 0) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`, [
        { msg: "Yield Ingredient name must be at least 3 characters" },
      ]);
      hasErrors = true;
    }

    if (!hasErrors) {
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`);
    }

    return hasErrors;
  };

  const onYieldAmountChange = (e) => {
    const value = e.target.value;

    const { hasErrors, preventSetValue } = verifyAmount(value);

    if (!preventSetValue) {
      setYieldObj({ ...yieldObj, amount: value });
    }

    if (!hasErrors) {
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldAmount`);
    }
  };

  const onYieldUnitChange = (newValue) => {
    if (newValue === null || newValue?.value?.length === 0) {
      setYieldObj({ ...yieldObj, unit: [] });
      return;
    }

    const hasErrors = verifyUnit(newValue);
    if (!hasErrors) {
      setYieldObj({ ...yieldObj, unit: newValue });
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldUnit`);
    }
  };

  const onYieldIngredientChange = (newValue) => {
    if (newValue === null || newValue?.value?.length === 0) {
      setYieldObj({ ...yieldObj, ingredient: [] });
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`);
      return;
    }

    let hasErrors = verifyIngredient(newValue);

    if (!hasErrors) {
      setYieldObj({ ...yieldObj, ingredient: newValue });
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`);
    }
  };

  const handleScaleTypeChange = (event) => {
    setScaleType(event.target.value);
  };

  const handleSubmit = () => {
    const scaleFactor = yieldObj.amount / (section.yield?.amount || 1);

    if (scaleType === "section") {
      scaleSection(sectionIndex, scaleFactor);
    } else if (scaleType === "recipe") {
      scaleRecipe(scaleFactor);
    }
    handleClose();
  };

  const calculateScaleFactor = () => {
    if (!yieldObj) {
      return 1;
    } else {
      if (yieldObj?.unit?.value && section.yield?.unit?.name) {
        if (yieldObj.unit.value !== section.yield.unit.name) {
          return 1;
        } else {
          if (!yieldObj.amount || yieldObj.amount === 0) {
            return 1;
          }
          return yieldObj.amount / (section.yield?.amount || 1);
        }
      } else {
        return 1;
      }
    }
  };

  return (
    <div className="w-full max-w-full md:max-w-[600px]">
      <div className="w-full p-4 bg-neutral-100">
        <Typography variant="h6" className="font-normal">
          {section.title || "Edit Yield"}
        </Typography>
      </div>

      <div className="p-4">
        <div className="py-4 flex flex-row items-start gap-2">
          <div className="w-1/2">
            <TextField
              label="Yield Amount"
              value={yieldObj.amount}
              onChange={onYieldAmountChange}
              fullWidth
              error={!!errors?.sections?.[sectionIndex]?.output?.yieldAmount}
              helperText={errors?.sections?.[sectionIndex]?.output?.yieldAmount?.[0]?.msg}
            />
          </div>
          <div className="w-1/2">
            <Searchable
              label="Yield Type/Unit"
              multiple={false}
              freeSolo={true}
              apiUrl="/units/searchable"
              allowAdd={true}
              onItemChange={onYieldUnitChange}
              fullWidth
              selectedItemsFromParent={yieldObj.unit}
              error={!!errors?.sections?.[sectionIndex]?.output?.yieldUnit}
              helperText={
                errors?.sections?.[sectionIndex]?.output?.yieldUnit
                  ? errors?.sections?.[sectionIndex]?.output?.yieldUnit?.map((error) => error.msg).join(", ")
                  : "Eg. ml, cups, cookies"
              }
              maxInputLength={32}
            />
          </div>
        </div>
        <div className="mb-4">
          <Searchable
            label="Yield Ingredient"
            labelWidth={130}
            multiple={false}
            freeSolo={true}
            apiUrl="/ingredients/searchable"
            allowAdd={true}
            onItemChange={onYieldIngredientChange}
            selectedItemsFromParent={yieldObj.ingredient}
            error={!!errors?.sections?.[sectionIndex]?.output?.yieldIngredient}
            helperText={
              errors?.sections?.[sectionIndex]?.output?.yieldIngredient
                ? errors?.sections?.[sectionIndex]?.output?.yieldIngredient?.map((error) => error.msg).join(", ")
                : "What product does the section make? Eg. banana bread"
            }
            maxInputLength={64}
          />
        </div>

        {calculateScaleFactor() !== 1 && !errors?.sections?.[sectionIndex]?.output && (
          <div className="w-full py-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outlined"
                  className="w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                >
                  {scaleType === "none"
                    ? "Edit Yield Only"
                    : `Scale ${
                        scaleType.charAt(0).toUpperCase() + scaleType.slice(1)
                      } (${calculateScaleFactor().toFixed(2)}x)`}
                  <ChevronDown className="w-3 opacity-50 ml-2" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                <DropdownMenuItem
                  onSelect={() => handleScaleTypeChange({ target: { value: "none" } })}
                  className="text-secondaryText-600/90"
                >
                  Edit Yield Only
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleScaleTypeChange({ target: { value: "section" } })}>
                  Scale Section ({calculateScaleFactor().toFixed(2)}x)
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleScaleTypeChange({ target: { value: "recipe" } })}>
                  Scale Recipe ({calculateScaleFactor().toFixed(2)}x)
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}

        <div className="flex justify-end">
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={!!errors?.sections?.[sectionIndex]?.output}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

YieldModal.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  scaleRecipe: PropTypes.func.isRequired,
  scaleSection: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setWalkthroughErrorByPath: PropTypes.func.isRequired,
  clearWalkthroughErrorByPath: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  errors: state.walkthrough.errors,
});

export default connect(mapStateToProps, {
  scaleRecipe,
  scaleSection,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
})(YieldModal);
