import React, { useState } from "react";
import { Typography } from "@mui/material";
import { ChevronDown, ChevronUp } from "lucide-react";
import { decimalToFraction, formatAmountUnit, formatIngredient, formatState } from "../../../utils/recipe";
import RecipeCard from "../../recipes/card/RecipeCard";

const MacroItem = ({ data, servings = 1 }) => {
  const value = servings === 1 ? data.amount : data.amount * servings;

  return (
    <div className="flex flex-row justify-between items-center">
      <Typography variant="subtitle1" className="font-normal leading-snug">
        {data.name}
      </Typography>
      <div className="flex-1 border-b border-separator mx-2"></div>
      <div className="flex flex-row">
        <Typography variant="body2">{value % 1 === 0 ? value.toFixed(0) : value.toFixed(2)}</Typography>
        {data.unit && (
          <Typography variant="body2" className="ml-2">
            {data.unit.name}
          </Typography>
        )}
      </div>
    </div>
  );
};

const IngredientItem = ({ ingredient }) => {
  return (
    <li className="my-3 marker:text-mainGreen">
      <div className="flex flex-col">
        <Typography variant="body1" className="font-medium">
          <span className="font-normal">{formatAmountUnit(ingredient)}</span>
          {formatIngredient(ingredient)}
          {ingredient.state !== "" && (
            <span className="font-normal italic text-secondaryText text-sm">{formatState(ingredient)}</span>
          )}
        </Typography>
        {ingredient.note && ingredient.note !== "" && (
          <Typography variant="body2" className="text-secondaryText">
            ({ingredient.note})
          </Typography>
        )}
      </div>
    </li>
  );
};

const YieldDisplay = ({ yield: yieldObj }) => {
  return (
    <div className="flex flex-row justify-between items-center py-2">
      <Typography variant="subtitle2" className="font-normal leading-snug text-secondaryText">
        Yield:{" "}
        <span className="font-medium">
          {formatAmountUnit(yieldObj)}{" "}
          {yieldObj.ingredient && <span>{formatIngredient(yieldObj.ingredient.name)}</span>}
        </span>
      </Typography>
    </div>
  );
};

const PantrySection = ({ section }) => {
  const [showUsed, setShowUsed] = useState(true);
  const [showAdded, setShowAdded] = useState(true);

  return (
    <div key={`pantry-section-${section.recipeSectionIndex}`} className="mt-4">
      {section.name && (
        <Typography variant="subtitle2" className="font-semibold mb-2">
          {section.name}
        </Typography>
      )}

      {section.yield && <YieldDisplay yield={section.yield} />}

      {section.ingredientsUsed?.length > 0 && (
        <div className="mt-2">
          <div className="flex items-center gap-2 cursor-pointer mb-2" onClick={() => setShowUsed(!showUsed)}>
            {showUsed ? (
              <ChevronUp className="w-4 h-4 text-secondaryText-600" />
            ) : (
              <ChevronDown className="w-4 h-4 text-secondaryText-600" />
            )}
            <Typography variant="subtitle2" className="text-secondaryText-600">
              Ingredients Used ({section.ingredientsUsed.length})
            </Typography>
          </div>
          {showUsed && (
            <ul className="list-disc list-outside ml-4">
              {section.ingredientsUsed
                .filter(
                  (ingredient) => ingredient.unit?.display?.magnitude || ingredient.unit?.display?.text === "self"
                )
                .map((ingredient, j) => (
                  <IngredientItem key={`used-${j}`} ingredient={ingredient} />
                ))}
            </ul>
          )}
        </div>
      )}

      {section.ingredientsAdded?.length > 0 && (
        <div className="mt-4">
          <div className="flex items-center gap-2 cursor-pointer mb-2" onClick={() => setShowAdded(!showAdded)}>
            {showAdded ? (
              <ChevronUp className="w-4 h-4 text-secondaryText-600" />
            ) : (
              <ChevronDown className="w-4 h-4 text-secondaryText-600" />
            )}
            <Typography variant="subtitle2" className="text-secondaryText-600">
              Ingredients Added ({section.ingredientsAdded.length})
            </Typography>
          </div>
          {showAdded && (
            <ul className="list-disc list-outside ml-4">
              {section.ingredientsAdded.map((ingredient, j) => (
                <IngredientItem key={`added-${j}`} ingredient={ingredient} />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

const CollapsibleSection = ({ title, children, expanded, onToggle, count }) => {
  return (
    <div className="p-4 border-t border-separator">
      <div className="flex items-center cursor-pointer" onClick={onToggle}>
        <div className="flex-1 flex items-center gap-2">
          <Typography variant="subtitle1" className="font-normal leading-snug text-primaryText-600">
            {title} {count && <span className="text-secondaryText-600">({count})</span>}
          </Typography>
          {expanded ? (
            <ChevronUp className="w-5 h-5 text-secondaryText-600" />
          ) : (
            <ChevronDown className="w-5 h-5 text-secondaryText-600" />
          )}
        </div>
      </div>
      {expanded && <div className="mt-4">{children}</div>}
    </div>
  );
};

const RecipeEvent = ({ data }) => {
  const [showMacros, setShowMacros] = useState(false);
  const [showPantry, setShowPantry] = useState(false);

  return (
    <div className="w-full border border-separator rounded-md shadow-sm bg-background flex flex-col">
      <div className="bg-neutral-100 rounded-md md:block">
        <div className="w-[250px]">
          <RecipeCard recipeid={data.recipe.id} rounded="rounded-md" />
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        {data?.macros && (
          <CollapsibleSection title="Macronutrients" expanded={showMacros} onToggle={() => setShowMacros(!showMacros)}>
            <div className="flex flex-col justify-between mb-2">
              {data.macros.servingsEaten && (
                <Typography variant="caption" className="text-secondaryText-600">
                  Servings Eaten: {data.macros.servingsEaten || 1}
                </Typography>
              )}
              {data.macros.servingSize && (
                <Typography variant="caption" className="text-secondaryText-600">
                  Serving Size: {data.macros.servingSize}
                </Typography>
              )}
            </div>
            {data.macros.sections?.map((section, i) => (
              <div key={`section-${i}`} className="mt-2">
                <Typography variant="subtitle2" className="font-semibold mb-2">
                  {section.name}
                </Typography>
                <div className="flex flex-col gap-1">
                  {section.macros.map((macro, j) => (
                    <MacroItem key={`macro-${i}-${j}`} data={macro} servings={data.macros.servingsEaten || 1} />
                  ))}
                </div>
              </div>
            ))}
          </CollapsibleSection>
        )}

        {data?.pantry && (
          <CollapsibleSection
            title="Ingredients"
            expanded={showPantry}
            onToggle={() => setShowPantry(!showPantry)}
            count={data.pantry.sections?.reduce(
              (acc, section) => acc + (section.ingredientsUsed?.length || 0) + (section.ingredientsAdded?.length || 0),
              0
            )}
          >
            {data.pantry.sections?.map((section, i) => (
              <PantrySection key={`pantry-section-${i}`} section={section} />
            ))}
          </CollapsibleSection>
        )}
      </div>
    </div>
  );
};

const MealSectionItem = ({ course, data }) => {
  console.log(data);
  return <div>{data.recipe ? <RecipeEvent data={data} /> : <div></div>}</div>;
};

export default MealSectionItem;
