import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";

//Components
import Update from "./Update";

const UpdateModal = ({ updates: initialUpdates, initialHasMore = true, scrollToTop }) => {
  const [updates, setUpdates] = useState(initialUpdates);
  const [hasMore, setHasMore] = useState(initialHasMore);

  const fetchMoreData = async () => {
    if (!hasMore) return;

    try {
      const lastUpdate = updates[updates.length - 1];
      const res = await api.get(`/updates/next/${lastUpdate._id}`);
      const { updates: newUpdates, hasMore: moreAvailable } = res.data;

      if (newUpdates.length === 0) {
        setHasMore(false);
      } else {
        setUpdates((prevUpdates) => [...prevUpdates, ...newUpdates]);
        setHasMore(moreAvailable);
      }
    } catch (error) {
      console.error("Error loading more updates:", error);
      setHasMore(false);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div id="scrollableDiv" className="max-h-[80vh] overflow-y-auto">
      <InfiniteScroll
        dataLength={updates.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center p-4">
            <CircularProgress />
          </div>
        }
        endMessage={<div className="text-center text-secondaryText-600 p-4">You're all up to date!</div>}
        scrollableTarget="scrollableDiv"
      >
        {updates.map((update) => (
          <Update key={update._id} update={update} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default UpdateModal;
