import React from "react";
import SectionIngredients from "./SectionIngredients";
import SectionSteps from "./SectionSteps";

import { Typography } from "@mui/material";

const WalkthroughSection = ({ section, sectionIndex, showTitle = false }) => {
  return (
    <div>
      {showTitle && section?.title && section.title !== "" && (
        <div className="w-full bg-stone-800 px-4 md:px-8 py-3 md:rounded-lg md:border md:border-separator md:mb-2">
          <Typography variant="subtitle1" className="text-background font-medium">
            {section.title}
          </Typography>
        </div>
      )}
      <div className="w-full flex flex-col md:gap-4 md:items-start lg:flex-row">
        <div className="w-full lg:w-fit md:h-fit">
          <SectionIngredients section={section} sectionIndex={sectionIndex} />
        </div>
        <div className="w-full md:h-fit">
          <SectionSteps section={section} sectionIndex={sectionIndex} />
        </div>
      </div>
    </div>
  );
};

export default WalkthroughSection;
