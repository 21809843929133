import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

import ProfileImg from "../../assets/default/user.jpg";

import { ReactComponent as HeartIcon } from "../../assets/icons/svg/heart.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/svg/comment.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/svg/new-message.svg";

const PostSkeleton = ({ profile: { currentProfile } }) => {
  return (
    <div className="w-full flex flex-col md:border-separator md:border md:rounded-lg bg-background">
      <div className="w-full flex flex-row p-4 py-2 justify-between">
        <div className="flex flex-row items-center w-[80%]">
          <Skeleton variant="circular" className="mr-4" width={32} height={32} animation="wave" />
          <Skeleton variant="text" className="mr-4 h-4" width={150} animation="wave" />
        </div>

        <div>
          <div>
            <div className="relative flex items-center justify-center h-full">
              <IconButton size="large" disableRipple className="hover:bg-transparent px-0">
                <FontAwesomeIcon icon={faEllipsis} className="w-[1rem] h-auto block text-primaryText" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Skeleton variant="rectangular" className="w-full h-0 pb-[100%]" animation="wave" />
      <div className="w-full flex-row justify-between p-4">
        <div className="flex flex-row">
          <div className="mr-4 flex flex-col items-center">
            <div className="w-full relative h-[1.5rem]">
              <IconButton className="p-0 hover:bg-transparent" disableRipple disabled={true} size="large">
                <HeartIcon className="w-[1.5rem] hover:fill-secondaryText" />
              </IconButton>
            </div>

            <Skeleton variant="text" width={16} animation="wave" className="h-4" />
          </div>
          <div className="mr-4 flex flex-col items-center">
            <div className="w-full relative h-[1.5rem]">
              <IconButton className="p-0 hover:bg-transparent" disableRipple disabled={true} size="large">
                <CommentIcon className="w-[1.5rem] hover:fill-secondaryText" />
              </IconButton>
            </div>

            <Skeleton variant="text" width={16} animation="wave" className="h-4" />
          </div>
          <div className="mr-4 flex flex-col items-center">
            <div className="w-full relative h-[1.5rem]">
              <IconButton className="p-0 hover:bg-transparent" disableRipple disabled={true} size="large">
                <ShareIcon className="w-[1.5rem] hover:fill-secondaryText" />
              </IconButton>
            </div>

            <Skeleton variant="text" width={16} animation="wave" className="h-4" />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center p-4 md:pt-2 cursor-pointer bg-neutral-100 md:bg-background md:rounded-b-lg">
        <div>
          {currentProfile && currentProfile.picture !== "" ? (
            <img
              src={currentProfile.picture}
              alt={"My Profile Picture"}
              className="rounded-[50%] border border-separator h-[1.5rem] w-[1.5rem] object-cover mr-4"
            ></img>
          ) : (
            <img
              src={ProfileImg}
              alt={"My Profile Picture"}
              className="rounded-[50%] border border-separator h-[1.5rem] w-[1.5rem] object-cover mr-4"
            ></img>
          )}
        </div>
        <Typography variant="subtitle2" className="font-normal text-secondaryText">
          Add a comment...
        </Typography>
      </div>
    </div>
  );
};

PostSkeleton.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {})(PostSkeleton);
