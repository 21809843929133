import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import api from "../../../api/api";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//MUI
import { Button, IconButton, Typography, Divider, TextField, Chip } from "@mui/material";
import { Badge } from "../../ui/badge";

//Default PFP
import DefaultPFP from "../../../assets/default/profile_picture.svg";

//Icons
import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/user-light.svg";
import profile from "../../../reducers/profile";
import { ReactComponent as GoogleIcon } from "../../auth/res/google.svg";
import { ReactComponent as AppleIcon } from "../../../assets/icons/svg/apple-brands-solid.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/svg/link.svg";

//Actions
import { setAlert } from "../../../actions/alert";
import { logout } from "../../../actions/auth";

//Components
import Spinner from "../../layout/Spinner";

const Account = ({ profile: { currentProfile }, auth: { user }, setAlert, logout }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState(user.email);

  const [accountType, setAccountType] = useState("");
  const [accountTypeLoading, setAccountTypeLoading] = useState(true);
  const [accountTypeError, setAccountTypeError] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handlePassword1Change = (value) => {
    setPassword1(value);
  };

  const handlePassword2Change = (value) => {
    setPassword2(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (email !== user.email) {
      if (!currentPassword) {
        setAlert("Please enter your current password to change your email", "warning");
        return;
      }

      // Email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Check if email is valid
      if (!emailRegex.test(email)) {
        setAlert("Please enter a valid email address", "warning");
        return;
      }
    }

    if (password1.length > 0 || password2.length > 0) {
      // Check if passwords match
      if (password1 !== password2) {
        setAlert("Passwords do not match", "warning");
        return;
      }

      // Check if password is at least 8 characters long
      if (password1.length < 8) {
        setAlert("Password must be at least 8 characters long", "warning");
        return;
      }

      if (password1.length > 64) {
        setAlert("Password must be less than 64 characters", "warning");
        return;
      }

      // Check if password contains at least one number
      if (!/\d/.test(password1)) {
        setAlert("Password must contain at least one number", "warning");
        return;
      }

      // Check if password contains at least one special character
      if (!/[@$!%*?&]/.test(password1)) {
        setAlert("Password must contain at least one special character", "warning");
        return;
      }

      // Check if password contains at least one uppercase letter
      if (!/[A-Z]/.test(password1)) {
        setAlert("Password must contain at least one uppercase letter", "warning");
        return;
      }

      // Check if password contains at least one lowercase letter
      if (!/[a-z]/.test(password1)) {
        setAlert("Password must contain at least one lowercase letter", "warning");
        return;
      }

      // Check if new password is different from the current password
      if (password1 === currentPassword) {
        setAlert("New password must be different from the current password", "warning");
        return;
      }
    }

    try {
      const res = await api.post("/auth/account", {
        email,
        password: currentPassword,
        newPassword: password1,
      });

      setSubmitLoading(false);
      setAlert("Account Information Updated", "success");
      logout();
    } catch (error) {
      setSubmitLoading(false);
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        setAlert(error.response.data.msg, "error");
      } else {
        setAlert("An error occurred while updating your account. Please try again.", "error");
      }
    }

    // If all checks pass, proceed with form submission
  };

  const getAccountType = async () => {
    try {
      const res = await api.get("/auth/type");
      setAccountType(res.data.type);
      setAccountTypeLoading(false);
      setAccountTypeError(false);
    } catch (error) {
      setAccountTypeLoading(false);
      setAccountTypeError(true);
    }
  };

  const handleCurrentPasswordChange = (value) => {
    setCurrentPassword(value);
  };

  useEffect(() => {
    getAccountType();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:pt-6 bg-neutral-50 min-h-screen">
        <div className="w-full md:pl-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] border-b md:border border-separator md:rounded-[16px] bg-background">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <div className="mr-4">
                    <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                  </div>
                  <div className="mb-4">
                    <Typography variant="h6" className="font-normal leading-none mb-1">
                      Account Settings
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                      Authentication and Account Information
                    </Typography>
                  </div>
                </div>
                <Link to="/settings/delete">
                  <Typography variant="subtitle2" className="font-normal text-rose-700">
                    Delete
                  </Typography>
                </Link>
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-4 my-8">
                <Link to={`/${currentProfile.username}`}>
                  <img
                    src={
                      currentProfile && currentProfile.picture && currentProfile.picture !== ""
                        ? currentProfile.picture
                        : DefaultPFP
                    }
                    alt="Your profile"
                    className="h-[4rem] w-[4rem] object-cover rounded-[50%] "
                  />
                </Link>
                <Link to={`/${currentProfile.username}`}>
                  <Typography variant="subtitle1" className="font-normal">
                    {currentProfile && currentProfile.name}
                  </Typography>
                  <Typography variant="subtitle1" className="text-mainGreen leading-tight font-medium">
                    {currentProfile && currentProfile.username && `@${currentProfile.username}`}
                  </Typography>
                </Link>
              </div>
              <div className="my-8 flex flex-col justify-between">
                <Link to="/editprofile">
                  <Typography variant="subtitle2" className="font-normal text-secondaryText">
                    Edit Profile
                  </Typography>
                </Link>
              </div>
            </div>

            {accountTypeLoading ? (
              <Spinner />
            ) : accountTypeError ? (
              <div className="text-center">
                <Typography variant="subtitle1" className="leading-tight text-primaryText font-normal">
                  An error occurred while fetching your account type.<br></br>Please refresh the page and try again.
                </Typography>
              </div>
            ) : (
              <>
                {accountType === "local" && (
                  <>
                    <div className="flex flex-col gap-4 my-4">
                      <TextField
                        value={email}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        fullWidth
                        label="Email"
                      />
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-4 my-2">
                      <div className="mt-2">
                        <Typography variant="subtitle1" className="leading-none text-primaryText font-normal">
                          Change Password
                        </Typography>
                      </div>

                      <TextField
                        value={currentPassword}
                        onChange={(e) => handleCurrentPasswordChange(e.target.value)}
                        type="password"
                        fullWidth
                        label="Current Password"
                      />
                      <TextField
                        value={password1}
                        onChange={(e) => handlePassword1Change(e.target.value)}
                        type="password"
                        fullWidth
                        label="New Password 1"
                      />
                      <TextField
                        value={password2}
                        onChange={(e) => handlePassword2Change(e.target.value)}
                        type="password"
                        fullWidth
                        label="New Password 2"
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleSubmit}
                        disabled={submitLoading}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </>
                )}
                {accountType === "google" && (
                  <div className="text-center py-8 border-t border-separator">
                    <Typography variant="subtitle1" className="leading-snug text-primaryText font-normal mb-4">
                      Your account is connected to your Google Account.<br></br>Edit your Google Account settings here:
                    </Typography>
                    <a href="https://myaccount.google.com" target="_blank" rel="noopener noreferrer">
                      <Button variant="contained" color="secondary" disableElevation>
                        <Typography variant="subtitle1" className="leading-none font-normal text-primaryText text-xs">
                          Manage your Google Account
                        </Typography>
                        <LinkIcon className="h-2 ml-2 fill-primaryText" />
                      </Button>
                    </a>
                  </div>
                )}
                {accountType === "apple" && (
                  <div className="text-center py-8 border-t border-separator">
                    <Typography variant="subtitle1" className="leading-snug text-primaryText font-normal mb-4">
                      Your account is connected to your Apple Account.<br></br>Edit your Apple Account settings here:
                    </Typography>
                    <a href="https://account.apple.com" target="_blank" rel="noopener noreferrer">
                      <Button variant="contained" color="secondary" disableElevation>
                        <Typography variant="subtitle1" className="leading-none font-normal text-primaryText text-xs">
                          Manage your Apple Account
                        </Typography>
                        <LinkIcon className="h-2 ml-2 fill-primaryText" />
                      </Button>
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Account.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(Account);
