import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button } from "@mui/material";

import Ingredient from "./Ingredient";
import { formatIngredient, decimalToFraction } from "../../../../../utils/recipe";

//Modals
import CustomModal from "../../../../modal/CustomModal";
import MacrosModal from "../modals/MacrosModal";
import TimingModal from "../modals/TimingModal";
import YieldModal from "../modals/YieldModal";
import ServingsModal from "../modals/ServingsModal";
//TODO:
//Loading
//Error
//Show servings (section based, or entire section), open to scaling modal

const SectionIngredients = ({ section, sectionIndex }) => {
  const [showMacrosModal, setShowMacrosModal] = useState(false);
  const [showServingsModal, setShowServingsModal] = useState(false);
  const [showYieldModal, setShowYieldModal] = useState(false);

  const handleMacrosModalClose = () => {
    setShowMacrosModal(false);
  };

  const handleServingsModalClose = () => {
    setShowServingsModal(false);
  };

  const handleYieldModalClose = () => {
    setShowYieldModal(false);
  };

  return (
    <>
      <CustomModal open={showMacrosModal} handleClose={handleMacrosModalClose} type="bottomToCentered">
        <MacrosModal
          macros={section.macros}
          servingSize={section.servingSize}
          title={section.title}
          totalServings={section.servings}
          section={section}
          sectionIndex={sectionIndex}
          handleClose={handleMacrosModalClose}
        />
      </CustomModal>
      <CustomModal open={showServingsModal} handleClose={handleServingsModalClose} type="bottomToCentered">
        <ServingsModal section={section} sectionIndex={sectionIndex} handleClose={handleServingsModalClose} />
      </CustomModal>
      <CustomModal open={showYieldModal} handleClose={handleYieldModalClose} type="bottomToCentered">
        <YieldModal section={section} sectionIndex={sectionIndex} handleClose={handleYieldModalClose} />
      </CustomModal>
      <div className="w-full lg:w-fit md:rounded-lg md:border md:border-separator bg-background">
        <div className="flex flex-col">
          <div className="w-full bg-neutral-100 px-4 md:px-8 py-3 md:rounded-t-lg md:border-b md:border-separator">
            <Typography variant="subtitle1" className="font-normal">
              Ingredients:
            </Typography>
          </div>
          {section.servings && (
            <div className="px-4 md:px-8 pt-4 w-full border-b border-separator pb-4">
              {section.yield && (
                <Button
                  onClick={() => setShowYieldModal(true)}
                  className="p-0 hover:bg-transparent justify-start items-start text-left"
                  disableRipple
                >
                  <div className="pb-2">
                    <Typography variant="subtitle2" className="font-normal">
                      Yield:
                    </Typography>
                    <div className="flex flex-grow-0 gap-1">
                      {section.yield.amount && (
                        <Typography variant="subtitle2" className="font-normal leading-snug">{`${decimalToFraction(
                          section.yield.amount
                        )} `}</Typography>
                      )}
                      {section.yield.unit && section.yield.unit.shortName && (
                        <Typography
                          variant="subtitle2"
                          className="font-normal leading-snug"
                        >{`${section.yield.unit.shortName} `}</Typography>
                      )}
                      {section.yield.ingredient && section.yield.ingredient.name && (
                        <Typography variant="subtitle2" className="font-medium leading-snug">
                          {formatIngredient(
                            section.yield.ingredient,
                            section?.yield?.amount ? section.yield.amount : 1,
                            section?.yield?.unit?.type ? section?.yield?.unit?.type : null
                          )}
                        </Typography>
                      )}
                    </div>
                    <Button className="p-0 hover:bg-transparent justify-start items-start text-left" disableRipple>
                      <Typography variant="subtitle2" className="text-mainGreen text-medium text-xs leading-none">
                        Edit Yield
                      </Typography>
                    </Button>
                  </div>
                </Button>
              )}
              <div className="flex flex-col xs:flex-row justify-between gap-2">
                <Button
                  className="p-0 hover:bg-transparent justify-start items-start text-left"
                  onClick={() => setShowServingsModal(true)}
                  disableRipple
                >
                  <div className="">
                    <Typography
                      variant="subtitle2"
                      className="font-normal leading-snug"
                    >{`${section.servings} servings`}</Typography>
                    {section.servingSize && section.servingSize !== "" && (
                      <div className="flex flex-col">
                        <Typography variant="subtitle2" className="font-normal text-secondaryText-600 text-xs">
                          ({section.servingSize})
                        </Typography>
                        <Button
                          className="p-0 hover:bg-transparent justify-start items-start text-left"
                          onClick={() => setShowServingsModal(true)}
                          disableRipple
                        >
                          <Typography variant="subtitle2" className="text-mainGreen text-medium text-xs mt-1">
                            Edit Servings
                          </Typography>
                        </Button>
                      </div>
                    )}
                  </div>
                </Button>
                {section?.macros && section?.macros?.length > 0 && (
                  <Button
                    className="p-0 hover:bg-transparent flex flex-col justify-start items-start xs:justify-end xs:items-end"
                    disableRipple
                    onClick={() => setShowMacrosModal(true)}
                  >
                    {section?.servingsEaten && (
                      <Typography variant="subtitle2" className="font-normal leading-snug">
                        Eating <span className="font-medium text-mainGreen">{section.servingsEaten}</span> serving
                        {section.servingsEaten !== 1 ? "s" : ""}
                      </Typography>
                    )}

                    {section.macros[0] && section.macros[0].name === "Calories" ? (
                      <Typography
                        variant="subtitle2"
                        className="font-normal leading-snug text-xs text-secondaryText-600"
                      >
                        <span className="font-medium">{section.macros[0].amount}</span> kcal/serving
                      </Typography>
                    ) : (
                      <div></div>
                    )}
                    <Button
                      className="p-0 hover:bg-transparent mt-1"
                      disableRipple
                      onClick={() => setShowMacrosModal(true)}
                    >
                      <Typography variant="subtitle2" className="text-mainGreen leading-snug text-xs font-medium">
                        Log Macros
                      </Typography>
                    </Button>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="px-4 md:px-8 py-4">
          <ul className="ml-4 pl-0 lg:min-w-[400px] xl:min-w-[500px]">
            {section && section?.ingredients && section?.ingredients.length > 0 ? (
              section.ingredients.map((ingredient, i) => {
                return (
                  <Ingredient section={section} sectionIndex={sectionIndex} ingredient={ingredient} key={i} index={i} />
                );
              })
            ) : (
              <li className="py-4">
                <Typography>No ingredients in this section</Typography>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

SectionIngredients.propTypes = {
  walkthrough: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps)(SectionIngredients);
