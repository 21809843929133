import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getNewUpdates, closeUpdateModal } from "../../actions/updates";

//Modal
import CustomModal from "../modal/CustomModal";

//Components
import UpdateModal from "./modals/UpdateModal";

const UpdateManager = ({ updates, getNewUpdates, closeUpdateModal }) => {
  useEffect(() => {
    getNewUpdates();
  }, []);

  const { showModal, newUpdates, hasMore, loading, error } = updates;

  return (
    <Fragment>
      <CustomModal open={showModal} handleClose={closeUpdateModal} type="bottomToCentered" border={false}>
        <UpdateModal updates={newUpdates} initialHasMore={hasMore} />
      </CustomModal>
    </Fragment>
  );
};

UpdateManager.propTypes = {
  updates: PropTypes.object.isRequired,
  getNewUpdates: PropTypes.func.isRequired,
  closeUpdateModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  updates: state.updates,
});

export default connect(mapStateToProps, { getNewUpdates, closeUpdateModal })(UpdateManager);
