import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DateSelectModal = ({ onClose, onDateSelect, dateFromParent }) => {
  const [selectedDate, setSelectedDate] = useState(dateFromParent);

  //Current timezone Intl.DateTimeFormat().resolvedOptions().timeZone

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleSubmit = () => {
    onDateSelect(selectedDate);
    onClose();
  };

  const formatDate = (date) => {
    const now = new Date();
    const isSameYear = date.getFullYear() === now.getFullYear();
    const options = { month: "long", day: "numeric" };
    return isSameYear
      ? date.toLocaleDateString("en-US", options)
      : date.toLocaleDateString("en-US", { ...options, year: "numeric" });
  };

  return (
    <div className="p-4 pt-0">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateCalendar value={selectedDate} onChange={handleDateChange} />
      </LocalizationProvider>
      <div className="mt-4">
        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth disableElevation>
          Go to {formatDate(selectedDate)}
        </Button>
      </div>
    </div>
  );
};

export default DateSelectModal;
