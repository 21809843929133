import React, { useState } from "react";
import { Typography, TextField, Button } from "@mui/material";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../../ui/tabs";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//Actions
import { setServingsEaten } from "../../../../../actions/walkthrough";

//TODO:
// Pass and update eaten servings in redux
// Max 2 decimal places allowed on eaten amount
//

const MacrosItem = ({ data, servings = 1 }) => {
  const amount = servings === 1 ? data.amount : data.amount * servings;

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Typography variant="subtitle1" className="font-normal leading-snug">
          {data.name}
        </Typography>
        <div className="flex-1 border-b border-separator mx-2"></div>
        <div className="flex flex-row">
          <Typography variant="body2">{amount % 1 === 0 ? amount.toFixed(0) : amount.toFixed(2)}</Typography>
          {data.unit && (
            <Typography variant="body2" className="ml-2">
              {data.unit.name}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

const MacrosModal = ({
  macros,
  servingSize,
  title,
  totalServings,
  handleClose,
  setServingsEaten,
  section,
  errors,
  sectionIndex,
}) => {
  //TODO: Error highlighting
  const [localServingsEaten, setLocalServingsEaten] = useState(section?.servingsEaten ? section.servingsEaten : 0);
  const [activeTab, setActiveTab] = useState("total");

  const handleServingsChange = (e) => {
    const value = e.target.value;
    if (value === "" || isNaN(value)) {
      setLocalServingsEaten("");
      return;
    }

    const numValue = parseFloat(value);
    if (numValue <= 0) {
      setLocalServingsEaten("");
      return;
    }

    setLocalServingsEaten(numValue);
  };

  const handleSave = () => {
    setServingsEaten(sectionIndex, localServingsEaten);
    handleClose();
  };

  return (
    <div className="w-full max-w-full md:max-w-[600px]">
      <div className="w-full p-4 bg-neutral-100">
        <Typography variant="h6" className="font-normal">
          {title}
        </Typography>
      </div>

      <div className="p-4">
        <div className="mb-4 pt-2">
          <TextField
            label="Servings Eaten"
            value={localServingsEaten}
            onChange={handleServingsChange}
            inputProps={{
              maxLength: 2,
            }}
            fullWidth
            helperText={`Max: ${totalServings} serving${totalServings > 1 ? "s" : ""}`}
          />
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="w-full mb-4">
            <TabsTrigger value="total" className="flex-1">
              Total Macros
            </TabsTrigger>
            <TabsTrigger value="single" className="flex-1">
              Single Serving
            </TabsTrigger>
          </TabsList>

          <TabsContent value="single">
            <div>
              <Typography variant="subtitle1" className="font-normal leading-snug">
                1 Serving
              </Typography>
              <div className="pb-4 border-b border-secondaryText-600">
                <Typography variant="body2" className="font-normal leading-snug text-secondaryText-600">
                  ({servingSize})
                </Typography>
              </div>
              <div className="mt-4">
                {macros.map((macro) => (
                  <MacrosItem key={macro.id} data={macro} />
                ))}
              </div>
            </div>
          </TabsContent>

          <TabsContent value="total">
            <div>
              <Typography variant="subtitle1" className="font-medium leading-snug">
                {`${localServingsEaten && localServingsEaten !== "" ? localServingsEaten : 0} ${
                  localServingsEaten === 1 ? "Serving" : "Servings"
                }`}
              </Typography>

              <div className="pb-4 border-b border-secondaryText">
                <Typography variant="body2" className="font-normal leading-snug text-secondaryText-600">
                  ({servingSize})
                </Typography>
              </div>

              <div className="mt-4">
                {macros.map((macro) => (
                  <MacrosItem key={macro.id} data={macro} servings={localServingsEaten} />
                ))}
              </div>
            </div>
          </TabsContent>
        </Tabs>
        <div className="mt-4">
          <Button variant="contained" color="primary" fullWidth disableElevation onClick={() => handleSave()}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

MacrosModal.propTypes = {
  macros: PropTypes.array.isRequired,
  servingSize: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalServings: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  setServingsEaten: PropTypes.func.isRequired,
  servingsEaten: PropTypes.number.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  errors: state.walkthrough.errors,
});

export default connect(mapStateToProps, { setServingsEaten })(MacrosModal);
