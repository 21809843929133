import React from "react";
import { Typography } from "@mui/material";

const TimingModal = ({ timing, title }) => {
  return (
    <div className="w-full max-w-full md:max-w-[600px]">
      <div className="w-full p-4 bg-neutral-100">
        <Typography variant="h6" className="font-normal">
          {title}
        </Typography>
      </div>

      <div className="p-4 flex flex-col gap-2">
        {timing && timing.prep && (
          <div>
            <Typography variant="body2" component="p" className="font-normal">
              Prep:
            </Typography>
            <Typography variant="body2" component="p" className="text-secondaryText font-normal">
              {`${timing.prep.hours > 0 ? `${timing.prep.hours} hrs ` : ""}${timing.prep.minutes} mins`}
            </Typography>
          </div>
        )}
        {timing && timing.rest && (
          <div>
            <Typography variant="body2" component="p" className="font-normal">
              Rest:
            </Typography>
            <Typography variant="body2" component="p" className="text-secondaryText font-normal">
              {`${timing.rest.hours > 0 ? `${timing.rest.hours} hrs ` : ""}${timing.rest.minutes} mins`}
            </Typography>
          </div>
        )}
        {timing && timing.cook && (
          <div>
            <Typography variant="body2" component="p" className="font-normal">
              Cook:
            </Typography>
            <Typography variant="body2" component="p" className="text-secondaryText font-normal">
              {`${timing.cook.hours > 0 ? `${timing.cook.hours} hrs ` : ""}${timing.cook.minutes} mins`}
            </Typography>
          </div>
        )}
        {timing && timing.total && (
          <div>
            <Typography variant="body2" component="p" className="font-normal">
              Total:
            </Typography>
            <Typography variant="body2" component="p" className="text-secondaryText font-normal">
              {`${timing.total.hours > 0 ? `${timing.total.hours} hrs ` : ""}${timing.total.minutes} mins`}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimingModal;
