import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, IconButton, TextField, Button, Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../ui/dropdown-menu";
import { ChevronDown } from "lucide-react";

import CustomModal from "../../../../modal/CustomModal";
import EditIngredientInfo from "./EditIngredientInfo";

//Icons
import { ReactComponent as PlusLight } from "../../../../../assets/icons/svg/plus-light.svg";
import { ReactComponent as MinusLight } from "../../../../../assets/icons/svg/minus-light.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/svg/edit.svg";

//Actions
import { updateSectionIngredient, scaleRecipe, scaleSection } from "../../../../../actions/walkthrough";

//Utility Functions
import { decimalToFraction, convertToTitleCase } from "../../../../../utils/recipe";

const IngredientModal = ({
  section,
  sectionIndex,
  ingredient,
  index,
  updateSectionIngredient,
  scaleRecipe,
  scaleSection,
  handleClose,
  errors,
}) => {
  const [amount, setAmount] = useState(ingredient.unit.display.magnitude);
  const [unit, setUnit] = useState(ingredient.unit.display.text);
  const [scaleType, setScaleType] = useState("none");
  const [isEditing, setIsEditing] = useState(false);
  const [isUnitEditing, setIsUnitEditing] = useState(false);
  const inputRef = useRef(null);
  const unitInputRef = useRef(null);
  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false);

  const calculateScaleFactor = () => {
    if (!ingredient.unit.display.magnitude) return 1;
    const magnitude = parseFloat(ingredient.unit.display.magnitude);
    if (magnitude === 0) return 0;
    return parseFloat(amount) / magnitude;
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleSubmit = () => {
    const scaleFactor = calculateScaleFactor();

    if (scaleType === "none") {
      // Update single ingredient
      updateSectionIngredient(sectionIndex, index, {
        ...ingredient,
        unit: {
          ...ingredient.unit,
          display: {
            ...ingredient.unit.display,
            magnitude: amount,
            text: unit,
          },
        },
      });
    } else {
      // Dispatch scale action based on type
      if (scaleType === "section") {
        scaleSection(sectionIndex, scaleFactor);
      } else if (scaleType === "recipe") {
        scaleRecipe(scaleFactor);
      }
    }
    handleClose();
  };

  const handleScaleTypeChange = (event) => {
    setScaleType(event.target.value);
  };

  const incrementAmount = () => {
    const newAmount = parseFloat(amount) + 1;
    setAmount(newAmount);
  };

  const decrementAmount = () => {
    if (parseFloat(amount) > 1) {
      const newAmount = parseFloat(amount) - 1;
      setAmount(newAmount);
    } else {
      setAmount(0);
    }
  };

  const handleAmountClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <div className="flex flex-col items-center p-6">
      <CustomModal open={editInfoModalOpen} handleClose={() => setEditInfoModalOpen(false)} type="bottom">
        <EditIngredientInfo
          ingredient={ingredient}
          section={section}
          sectionIndex={sectionIndex}
          index={index}
          errors={errors?.sections?.[sectionIndex]?.ingredients?.[index]}
        />
      </CustomModal>
      {ingredient.id && ingredient.unit.id && (
        <div className="flex items-center mb-6 text-secondaryText">
          <FontAwesomeIcon icon={faCheckCircle} className="text-mainGreen h-[1.25rem] mr-2" />
          <Typography variant="body2">This ingredient will be subtracted from your on-hand ingredients</Typography>
        </div>
      )}

      <div className="flex flex-col items-center w-full max-w-[300px]">
        <div className="flex items-center justify-between w-full mb-4">
          <IconButton onClick={decrementAmount}>
            <MinusLight className="w-6 h-6 fill-secondaryText" />
          </IconButton>

          {isEditing ? (
            <TextField
              ref={inputRef}
              value={amount}
              onChange={handleAmountChange}
              onBlur={() => setIsEditing(false)}
              type="number"
              variant="standard"
              className="w-24 text-center"
              autoFocus
              InputProps={{
                className: "text-center text-3xl font-medium",
                min: 0,
                max: 10000,
              }}
            />
          ) : (
            <Typography
              variant="h4"
              className="font-medium cursor-pointer w-24 text-center"
              onClick={handleAmountClick}
            >
              {decimalToFraction(amount)}
            </Typography>
          )}

          <IconButton onClick={incrementAmount}>
            <PlusLight className="w-6 h-6 fill-secondaryText" />
          </IconButton>
        </div>

        <Typography variant="h6" className="text-secondaryText mb-6 font-normal">
          {ingredient.unit.display.text &&
          ingredient.unit.display.text !== "" &&
          ingredient.unit.display.text !== "self"
            ? ingredient.unit.display.text
            : ""}
        </Typography>
      </div>
      <Button
        className="w-full p-0 hover:bg-transparent justify-start items-start text-left flex flex-col"
        onClick={() => setEditInfoModalOpen(true)}
        disableRipple
      >
        <Typography variant="h6" className="font-medium">
          {convertToTitleCase(ingredient.name)}
          {ingredient.state && ingredient.state !== "" && (
            <span className="font-normal italic text-secondaryText">, {convertToTitleCase(ingredient.state)}</span>
          )}
          <EditIcon className="h-3 w-3 fill-secondaryText-600 inline ml-2"></EditIcon>
        </Typography>

        {ingredient.note && ingredient.note !== "" && (
          <Typography variant="body1" className="text-secondaryText">
            ({ingredient.note})
          </Typography>
        )}
      </Button>
      {calculateScaleFactor() !== 1 && (
        <div className="w-full mt-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outlined"
                className="w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
              >
                {scaleType === "none"
                  ? "Edit Ingredient Only"
                  : `Scale ${scaleType.charAt(0).toUpperCase() + scaleType.slice(1)} (${calculateScaleFactor().toFixed(
                      2
                    )}x)`}
                <ChevronDown className="w-3 opacity-50 ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
              <DropdownMenuItem
                onSelect={() => handleScaleTypeChange({ target: { value: "none" } })}
                className="text-secondaryText-600/90"
              >
                Edit Ingredient Only
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => handleScaleTypeChange({ target: { value: "section" } })}>
                Scale Section ({calculateScaleFactor().toFixed(2)}x)
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => handleScaleTypeChange({ target: { value: "recipe" } })}>
                Scale Recipe ({calculateScaleFactor().toFixed(2)}x)
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}

      <div className="w-full mt-4">
        <Button variant="contained" fullWidth disableElevation onClick={handleSubmit}>
          Update Ingredient
        </Button>
      </div>
    </div>
  );
};

IngredientModal.propTypes = {
  ingredient: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  scaleRecipe: PropTypes.func.isRequired,
  scaleSection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
  errors: state.walkthrough.errors,
});

export default connect(mapStateToProps, { updateSectionIngredient, scaleRecipe, scaleSection })(IngredientModal);
