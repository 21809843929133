import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//MUI
import { Drawer, IconButton, Badge } from "@mui/material";

import { ReactComponent as LeafLogo } from "../../../assets/logos/icon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/svg/home.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/svg/search.svg";
import { ReactComponent as CookbookIcon } from "../../../assets/icons/svg/book.svg";
import { ReactComponent as PantryIcon } from "../../../assets/icons/svg/carrot.svg";
import { ReactComponent as InsightsIcon } from "../../../assets/icons/svg/chart-line.svg";
import { ReactComponent as NotificationsIcon } from "../../../assets/icons/svg/heart.svg";
import { ReactComponent as CreateIcon } from "../../../assets/icons/svg/create.svg";

import { ReactComponent as DefaultPFP } from "../../../assets/default/profile_picture.svg";

//Actions
import { getUnreadNotifications } from "../../../actions/notifications";
import { toggleCreateModal } from "../../../actions/navigation";

//Modals
import CreateOptionsModal from "../modals/CreateOptionsModal";

//Capacitor
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

const Desktop = ({
  profile: { currentProfile },
  notifications: { unread },
  auth: { user },
  isAuthenticated,
  getUnreadNotifications,
  toggleCreateModal,
}) => {
  const [capacitorPlatform, setCapacitorPlatform] = useState(null);

  const getCapacitorPlatform = () => {
    const platform = Capacitor.getPlatform();

    if (platform) {
      setCapacitorPlatform(platform);
      return platform;
    } else {
      setCapacitorPlatform("web");
      return "web";
    }
  };

  useEffect(() => {
    getCapacitorPlatform();
  }, []);
  //Set status bar for dark bg, reset on return
  const setStatusBar = async (styleType) => {
    const platform = getCapacitorPlatform();

    if (platform === "ios") {
      if (styleType === "light") {
        await StatusBar.setStyle({ style: Style.Light });
      } else if (styleType === "dark") {
        await StatusBar.setStyle({ style: Style.Dark });
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    setStatusBar("light");
  }, []);

  const location = useLocation();

  const onNavigation = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUnreadNotifications();
    }
  }, [location]);

  const onCreateModalClose = () => {
    toggleCreateModal(false);
  };

  return (
    <Drawer variant="permanent" className="w-[5rem] flex-shrink-0 overflow-x-hidden">
      <CreateOptionsModal onClose={onCreateModalClose} />
      <div className="flex flex-col justify-between h-full w-full max-w-[5rem] bg-background py-4 pt-[calc(1rem+env(safe-area-inset-top))] overflow-x-hidden">
        <div className="flex items-center w-full justify-center mb-4">
          <Link to="/" onClick={() => onNavigation()}>
            <IconButton className="hover:bg-transparent" size="large">
              <LeafLogo className="fill-mainGreen h-[2.5rem]" />
            </IconButton>
          </Link>
        </div>
        <div className="flex flex-col w-full h-full justify-between">
          <div className="flex flex-col w-full">
            <div className="mx-4">
              <Link to="/" className="flex flex-col items-center" onClick={() => onNavigation()}>
                <IconButton className="text-primaryText" size="large">
                  <HomeIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
                </IconButton>
              </Link>
            </div>
            <div className="mx-4">
              <Link to="/search" className="flex flex-col items-center" onClick={() => onNavigation()}>
                <IconButton className="text-primaryText" size="large">
                  <SearchIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
                </IconButton>
              </Link>
            </div>
            <div className="mx-4">
              <Link to="/cookbook" className="flex flex-col items-center" onClick={() => onNavigation()}>
                <IconButton className="text-primaryText" size="large">
                  <CookbookIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
                </IconButton>
              </Link>
            </div>
            {(capacitorPlatform !== "ios" || (user && user.isAdmin)) && (
              <>
                <div className="mx-4">
                  <Link to="/insights" className="flex flex-col items-center" onClick={() => onNavigation()}>
                    <IconButton className="text-primaryText" size="large">
                      <InsightsIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
                    </IconButton>
                  </Link>
                </div>
                <div className="mx-4">
                  <Link to="/pantry" className="flex flex-col items-center" onClick={() => onNavigation()}>
                    <IconButton className="text-primaryText" size="large">
                      <PantryIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
                    </IconButton>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col w-full">
            <div className="mx-4">
              <Link to="/notifications" className="flex flex-col items-center" onClick={() => onNavigation()}>
                <IconButton className="text-primaryText" size="large">
                  <Badge badgeContent={unread} color="primary" max={99}>
                    <NotificationsIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
                  </Badge>
                </IconButton>
              </Link>
            </div>
            <div className="mx-4">
              <IconButton className="text-primaryText" size="large" onClick={() => toggleCreateModal(true)}>
                <CreateIcon className="h-[1.75rem] w-full fill-primaryText text-primaryText" />
              </IconButton>
            </div>
            <div className="my-4 flex items-center flex-col">
              <Link to={currentProfile ? `/${currentProfile.username}` : "/"} onClick={() => onNavigation()}>
                {currentProfile && currentProfile.picture && currentProfile.picture !== "" ? (
                  <img
                    alt={"My Profile picture"}
                    src={currentProfile.picture}
                    className="h-[3rem] w-[3rem] object-cover rounded-[50%]"
                  ></img>
                ) : (
                  <DefaultPFP className="h-[3rem] w-[3rem] object-cover rounded-[50%]" />
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

Desktop.propTypes = {
  profile: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getUnreadNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
  toggleCreateModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  notifications: state.notifications,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getUnreadNotifications, toggleCreateModal })(Desktop);
