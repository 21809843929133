import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import BackgroundImage from "./res/EndOfRecipes.jpg";
import { preventWidows } from "../../../../utils/text";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/svg/import.svg";
import { ReactComponent as GenerateIcon } from "../../../../assets/icons/svg/magic-wand-solid.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/plus.svg";

export const EndOfRecipes = ({ loading, subtitle = "Be the first to add it!" }) => {
  const onNavigation = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {!loading && (
        <div className="mb-[4rem] md:mb-0 min-[500px]:m-0 md:mx-8">
          <div className="relative py-8 px-4 md:px-8 flex flex-col items-start border-t border-separator md:rounded-lg w-full bg-cover bg-stone-800">
            <div className="relative">
              <div>
                <Typography variant="h5" className="font-semibold text-background">
                  {preventWidows("Can't find the recipe you're looking for?", 2)}
                </Typography>
                <Typography variant="h6" className="font-normal text-background mb-8">
                  {subtitle}
                </Typography>
              </div>

              <div className="flex flex-col gap-2 items-center w-full md:w-auto lg:min-w-[500px]">
                <Link to="/create/recipe" onClick={() => onNavigation()} className="w-full">
                  <Button className="p-0 bg-background hover:bg-neutral-200" fullWidth>
                    <div className="px-4 py-3 border border-separator rounded-md w-full">
                      <div className="flex flex-row items-center gap-2">
                        <PlusIcon className="w-4 h-4 mr-2 fill-primaryText" />
                        <div className="flex flex-col">
                          <div className="flex items-start text-start">
                            <Typography variant="subtitle1" className="font-normal leading-tight text-primaryText">
                              Create a Recipe
                            </Typography>
                          </div>
                          <div className="flex items-start text-start">
                            <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                              Share your kitchen's best kept secret
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                </Link>
                <Link to="/create/recipe?type=import" onClick={() => onNavigation()} className="w-full">
                  <Button className="p-0 bg-background hover:bg-neutral-200" fullWidth>
                    <div className="px-4 py-3 border border-separator rounded-md w-full">
                      <div className="flex flex-row items-center gap-2">
                        <ImportIcon className="w-4 h-4 mr-2 fill-primaryText" />
                        <div className="flex flex-col">
                          <div className="flex items-start text-start">
                            <Typography variant="subtitle1" className="font-normal leading-tight text-primaryText">
                              Import a Recipe
                            </Typography>
                          </div>
                          <div className="flex items-start text-start">
                            <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                              Quickly add a recipe to your cookbook
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                </Link>
                <Link to="/create/recipe?type=generate" onClick={() => onNavigation()} className="w-full">
                  <Button className="p-0 bg-background hover:bg-neutral-200" fullWidth>
                    <div className="px-4 py-3 border border-separator rounded-md w-full">
                      <div className="flex flex-row items-center gap-2">
                        <GenerateIcon className="w-4 h-4 mr-2 fill-primaryText" />
                        <div className="flex flex-col">
                          <div className="flex items-start text-start">
                            <Typography variant="subtitle1" className="font-normal leading-tight text-primaryText">
                              Generate a Recipe
                            </Typography>
                          </div>
                          <div className="flex items-start text-start">
                            <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                              with thisPantry<span className="text-mainGreen font-semibold">+</span>
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EndOfRecipes;
