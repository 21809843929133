import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { Typography, Button, Divider, useMediaQuery } from "@mui/material";

//Infinite scroll
import InfiniteScroll from "react-infinite-scroll-component";

//Components
import Spinner from "../layout/Spinner";
import ProfilePostManager from "./ProfilePostManager";
import RecipeCard from "../recipes/card/RecipeCard";
import ProfilePinned from "./ProfilePinned";

//Actions
import { fetchProfilePosts, fetchProfileRecipes } from "../../actions/profile";
import { toggleCreateModal } from "../../actions/navigation";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRss } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PinIcon } from "../../assets/icons/svg/pin-filled.svg";
const ProfileFeedManager = ({
  fetchProfilePosts,
  fetchProfileRecipes,
  viewedProfile: { posts, recipes, username, pinned, postCount, recipeCount, viewedMine },
  showPosts,
  setShowPosts,
  toggleCreateModal,
}) => {
  const showSeparateFeeds = useMediaQuery("(min-width:1401px)");
  const [showPinned, setShowPinned] = useState(true);

  const determineEmpty = (type) => {
    const phrases = [
      [`No ${type} shared yet.`, `Maybe @${username} is just marinating on some ideas!`],
      [`No ${type} here yet.`, `Maybe @${username} is just marinating on some ideas!`],
      [`No ${type} shared yet.`, `Maybe @${username} is still cooking up something special!`],
      [`No ${type} here yet.`, `Maybe @${username} is still cooking up something special!`],
      [`No ${type} shared yet.`, `Maybe @${username} is still gathering the ingredients!`],
      [`No ${type} here yet.`, `Maybe @${username} is still gathering the ingredients!`],
      [`No ${type} shared yet.`, `Maybe @${username} is still prepping the ingredients!`],
      [`No ${type} here yet.`, `Maybe @${username} is still prepping the ingredients!`],
    ];
    return phrases[Math.floor(Math.random() * phrases.length)];
  };
  const [emptyRecipesText, setEmptyRecipesText] = useState(determineEmpty("recipes"));
  const [emptyFeedText, setEmptyFeedText] = useState(determineEmpty("posts"));

  const handlePostFetch = () => {
    if (!posts.loading) {
      fetchProfilePosts();
    }
  };

  const handleRecipeFetch = () => {
    if (!recipes.loading) {
      fetchProfileRecipes();
    }
  };

  const determineDefaultFeed = () => {
    if (pinned && pinned?.pinned && pinned.pinned.length > 0) {
      setShowPinned(true);
    } else {
      setShowPinned(false);
    }
  };

  useEffect(() => {
    determineDefaultFeed();
  }, []);

  //TO DO:
  //Feed and Recipes state
  //Feed and recipes display
  //Get chunked feed and recipes on scroll
  //If scroll past first post on no auth, show sign up modal
  //If interCreateOptionsModal through user's recipes
  //Sort posts by top, trending, new
  return (
    <div className="flex-1 flex flex-col basis-1/2">
      <div className="flex flex-col justify-between bg-background w-full md:rounded-lg md:shadow-sm border border-separator z-10 md:top-[calc(4rem+0.5rem)] md:px-4 py-4 md:mt-4">
        <div className="flex flex-row justify-between items-center pr-[0.625rem] md:pr-0">
          <div className="flex flex-row self-start">
            <Button className="px-4 py-0 hover:bg-transparent" disableRipple onClick={() => setShowPosts(true)}>
              <Typography
                variant="subtitle1"
                className={
                  showPosts
                    ? "font-medium text-primaryText text-sm"
                    : "font-normal text-secondaryText hover:underline text-sm"
                }
              >
                Posts{" "}
                <span
                  className={
                    showPosts ? "font-normal  text-mainGreen text-xs" : "font-normal  text-secondaryText text-xs"
                  }
                >
                  {postCount ? `(${postCount})` : ""}
                </span>
              </Typography>
            </Button>
            <Divider flexItem orientation="vertical" />
            <Button className="px-4 py-0 hover:bg-transparent" disableRipple onClick={() => setShowPosts(false)}>
              <Typography
                variant="subtitle1"
                className={
                  !showPosts
                    ? "font-medium text-primaryText text-sm"
                    : "font-normal text-secondaryText hover:underline text-sm"
                }
              >
                Recipes{" "}
                <span
                  className={
                    !showPosts ? "font-normal  text-mainGreen text-xs" : "font-normal  text-secondaryText text-xs"
                  }
                >
                  {recipeCount ? `(${recipeCount})` : ""}
                </span>
              </Typography>
            </Button>
          </div>
          {viewedMine && (
            <Button disableRipple className="hover:bg-transparent p-0" onClick={() => toggleCreateModal(true)}>
              <Typography variant="subtitle2" className="text-secondaryText font-normal text-xs">
                <span className="text-mainGreen font-medium">+</span>&nbsp;Create
              </Typography>
            </Button>
          )}
        </div>
        {showPosts && (
          <div className="w-full rounded-lg flex flex-row mt-2 mx-0">
            <Button onClick={() => setShowPinned(true)} className="px-4 py-0 hover:bg-transparent" disableRipple>
              <PinIcon
                className={`mr-2 h-[0.625rem] md:h-[0.75rem] align-middle${
                  showPinned ? " fill-mainGreen" : " fill-secondaryText"
                }`}
              />
              <Typography
                variant="subtitle2"
                className={
                  showPinned
                    ? "font-medium text-primaryText text-sm"
                    : "font-normal text-secondaryText hover:underline text-sm"
                }
              >
                Pinned
              </Typography>
            </Button>

            <Button onClick={() => setShowPinned(false)} className="px-4 py-0 hover:bg-transparent" disableRipple>
              <FontAwesomeIcon
                icon={faRss}
                className={`h-[0.625rem] md:h-[0.75rem] mr-2${!showPinned ? " text-mainGreen" : " text-secondaryText"}`}
              />
              <Typography
                variant="subtitle2"
                className={
                  !showPinned
                    ? "font-medium text-primaryText text-sm"
                    : "font-normal text-secondaryText hover:underline text-sm"
                }
              >
                Feed
              </Typography>
            </Button>
          </div>
        )}
      </div>
      {showPosts ? (
        <>
          <div>
            {showPinned ? (
              <ProfilePinned posts={pinned ? pinned : []} username={username} />
            ) : (
              <>
                {posts && posts.posts && posts.posts.length === 0 ? (
                  <div className="mt-6 mx-4 md:mx-0 md:mt-2 px-8 py-4 border border-separator rounded-lg shadow-sm text-center bg-background">
                    <Typography variant="h6" className="text-primaryText font-normal">
                      {emptyFeedText[0]}
                    </Typography>
                    {emptyFeedText[1] && (
                      <Typography variant="subtitle1" className="text-secondaryText font-normal mt-2">
                        {emptyFeedText[1]}
                      </Typography>
                    )}
                  </div>
                ) : (
                  <div>
                    <InfiniteScroll
                      dataLength={posts ? posts.posts.length : 0}
                      next={handlePostFetch}
                      hasMore={!posts.isEndOfFeed}
                      loader={posts.loading && <Spinner />}
                      endMessage={<></>}
                      className="overflow-hidden"
                    >
                      {posts &&
                        posts.posts &&
                        posts.posts.map((post, i) => <ProfilePostManager index={i} key={i} fullWidth={true} />)}
                    </InfiniteScroll>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div className="w-full flex flex-col md:pt-2 items-start md:px-0 [&_>_div:first-child]:w-full [&_>_div:first-child]:overflow-x-hidden">
          {recipes && recipes.recipes && recipes.recipes.length > 0 ? (
            <InfiniteScroll
              dataLength={posts ? posts.posts.length : 0}
              next={handlePostFetch}
              hasMore={!posts.isEndOfFeed}
              loader={posts.loading && <Spinner />}
              endMessage={null}
              className="overflow-hidden"
            >
              <div
                className={`grid md:gap-2  ${
                  recipes && recipes.recipes && recipes.recipes.length > 2
                    ? "[grid-template-columns:repeat(auto-fit,minmax(266px,1fr))]"
                    : "grid-cols-1 sm:grid-cols-2"
                }`}
              >
                {recipes &&
                  recipes.recipes &&
                  recipes.recipes.map((recipe, i) => (
                    <RecipeCard recipeid={recipe.id} showUsername={false} openType="link" rounded="md:rounded-lg" />
                  ))}
              </div>
            </InfiniteScroll>
          ) : (
            <div className="px-2 py-4 border border-separator rounded-lg shadow-sm text-center bg-background">
              <Typography variant="h6" className="text-primaryText font-normal">
                {emptyRecipesText[0]}
              </Typography>
              {emptyRecipesText[1] && (
                <Typography variant="subtitle1" className="text-secondaryText font-normal mt-2">
                  {emptyRecipesText[1]}
                </Typography>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ProfileFeedManager.propTypes = {
  // isAuthenticated: PropTypes.bool,
  fetchProfilePosts: PropTypes.func.isRequired,
  fetchProfileRecipes: PropTypes.func.isRequired,
  viewedProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  viewedProfile: state.profile.viewedProfile,
});

export default connect(mapStateToProps, { fetchProfilePosts, fetchProfileRecipes, toggleCreateModal })(
  ProfileFeedManager
);
