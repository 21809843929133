import React from "react";

import { Typography } from "@mui/material";

//Icons
import { ReactComponent as NewUpdatesIcon } from "../../../assets/icons/svg/sparkles-duo.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/svg/heart-solid.svg";
import { ReactComponent as BugFixIcon } from "../../../assets/icons/svg/bug-slash-duo.svg";

//Utility Functions
const formatDateForDisplay = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
};

//TODO:
// - Max height for modal
// - Infinite scroll for updates, fetch next based on update ID

const Update = ({ update }) => {
  const { date, title, image, text, updates, bugFixes } = update;

  return (
    <div>
      <div className="bg-stone-800 p-4">
        <Typography variant="h5" className="font-semibold text-background">
          {title}
        </Typography>
        <Typography variant="subtitle2" className="text-secondaryText-100 leading-snug text-xs">
          {formatDateForDisplay(date)}
        </Typography>
      </div>

      <div className="w-full h-auto">
        <img src={image} className="w-full"></img>
      </div>

      <div>
        <div className="px-4 py-6 bg-neutral-50">
          <Typography variant="body1">{text}</Typography>
        </div>

        <div className="px-4 py-6 bg-background">
          <div className="flex flex-row items-center">
            <div className="mb-2 flex flex-row items-center gap-2">
              <NewUpdatesIcon className="h-5 fill-mainGreen" />
              <Typography variant="subtitle1" className="font-medium">
                What's new?
              </Typography>
            </div>
            <div className="h-[2px] flex-grow bg-mainGreen mx-4 mb-1"></div>
          </div>

          <ul>
            {updates.map((u, i) => {
              return (
                <li key={i} className="ml-8 mr-4 marker:text-mainGreen mb-2">
                  <Typography variant="subtitle1" className="font-medium">
                    {u.title}
                  </Typography>
                  <Typography variant="body2" className="text-secondaryText-600">
                    {u.text}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
        {bugFixes && bugFixes.length > 0 && (
          <div className="px-4 py-6 bg-neutral-50">
            <div className="flex flex-col">
              <div className="mb-2 flex flex-row items-center gap-2">
                <BugFixIcon className="h-4 fill-mainGreen" />

                <div className="flex flex-col">
                  <Typography variant="subtitle2" className="font-normal">
                    Bug Fixes:
                  </Typography>
                </div>
              </div>
            </div>
            <ul>
              {bugFixes.map((u, i) => {
                return (
                  <li key={i} className="ml-8 mr-4 marker:text-mainGreen mb-2">
                    <Typography variant="body2" className="text-primaryText flex items-start">
                      {u.text}
                      {u.reportedByUser && (
                        <span className="ml-1">
                          <HeartIcon className="h-3 fill-mainGreen mt-1" />
                        </span>
                      )}
                    </Typography>
                  </li>
                );
              })}
            </ul>
            {bugFixes.some((u) => u.reportedByUser) && (
              <Typography variant="body1" className="text-xs">
                The bug fixes marked with a heart were reported by users. Thank you!
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Update;
