import React, { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";

// FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

//MUI Components
import { Button, Typography } from "@mui/material";

//Redux actions
import { showRegisterModal } from "../../actions/auth";
import { setSignupStage } from "../../actions/register";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

//Compoents
import Initial from "./register/Initial";
import BasicInfoStandalone from "./register/BasicInfoStandalone";
import Spinner from "../layout/Spinner";

import { ReactComponent as BackIcon } from "../../assets/icons/svg/chevron-left.svg";

import MaskableIcon from "../../assets/logos/logo512_maskable.png";

import RegisterImage from "./res/register.jpg";

//utils
import { preventWidows } from "../../utils/text";
import Footer from "../layout/Footer";

//Stages
import { SIGNUP_STAGES } from "./register/config/RegisterConfig";

const Register = ({
  showRegisterModal,
  setSignupStage,
  auth: { isAuthenticated, loading },
  register: { stage },
  device: { platform },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div>
      <Helmet>
        <title>Sign Up - thisPantry</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : isAuthenticated ? (
        <Navigate to="/"></Navigate>
      ) : (
        <div className="w-full flex flex-col justify-center">
          <div className="w-full mx-auto">
            <div className="w-full h-full max-h-device flex flex-col md:flex-row">
              <div className="w-full flex-1 h-full min-h-device relative hidden md:flex items-end">
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{ backgroundImage: `url(${RegisterImage})` }}
                ></div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
                <div className="flex flex-col items-start justify-end h-full px-4 py-8 md:px-8 md:py-12 relative z-10 ">
                  <Typography variant="h2" className="mb-2 text-white font-medium text-3xl md:text-4xl lg:text-5xl">
                    {preventWidows("Let's get cookin'")}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="leading-snug text-secondaryText-100 text-sm sm:text-base md:text-lg"
                  >
                    {preventWidows("Be the first to experience the future of your own kitchen")}
                  </Typography>
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                className="md:w-[45vw] lg:w-[40vw] xl:w-[30vw] h-full min-h-device flex justify-center items-center px-4 sm:px-8 xl:px-12"
              >
                {stage === SIGNUP_STAGES.INITIAL && <Initial />}
                {stage === SIGNUP_STAGES.BASIC_INFO && <BasicInfoStandalone />}
              </form>
            </div>
          </div>
          {platform === "web" && <Footer />}
        </div>
      )}
    </div>
  );
};

Register.propTypes = {
  showRegisterModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
  register: state.register,
  device: state.device,
});

export default connect(mapStateToProps, { showRegisterModal, setSignupStage })(Register);
