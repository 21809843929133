import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  toggleRecipeOutputModal,
  setSectionOutput,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
} from "../../../../actions/createrecipe";
import CustomModal from "../../../modal/CustomModal";
import Searchable from "../../../layout/searchable/Searchable";
import IngredientInfoModal from "./IngredientInfoModal";
import { setAlert } from "../../../../actions/alert";

//MUI
import { Typography, TextField, Button } from "@mui/material";

//Icons
import { ReactComponent as InfoIcon } from "../../../../assets/icons/svg/info.svg";

//Edit macros for a section, or compiled macros for a recipe
const RecipeOutputModal = ({
  visible,
  sectionIndex,
  sections,
  toggleRecipeOutputModal,
  setAlert,
  setSectionOutput,
  errors,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
}) => {
  const verifyAmount = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    // Handle empty value check first
    if (value === "") {
      return { hasErrors, preventSetValue };
    }

    // Match the same pattern as EditIngredientModal
    const fractionRegex = /^(\d{1,3}\/\d{1,3})$|^(\d{1,3}\/)$|^(\d{1,3}\s+\d{1,3}\/\d{1,3})$|^(\d{1,3})$/;

    if (!fractionRegex.test(value)) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
        { msg: "Amount must be a number or valid fraction" },
      ]);
      hasErrors = true;
      // Don't prevent value while typing
      preventSetValue = false;
    } else {
      // Only validate numeric bounds if we have a complete number
      if (typeof value === "string" && value.includes(" ") && value.includes("/")) {
        // Handle compound fraction (e.g., "1 1/2")
        const [whole, fraction] = value.split(" ");
        const [numerator, denominator] = fraction.split("/");
        const numericValue = parseInt(whole) + parseInt(numerator) / parseInt(denominator);

        if (numericValue > 10000) {
          setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be less than or equal to 10,000" },
          ]);
          preventSetValue = true;
          hasErrors = true;
        } else if (numericValue < 1) {
          setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be greater than or equal to 1" },
          ]);
          hasErrors = true;
        }
      } else if (typeof value === "string" && value.includes("/")) {
        // Handle simple fraction (e.g., "1/2")
        const [numerator, denominator] = value.split("/");
        if (denominator) {
          const fractionValue = parseInt(numerator) / parseInt(denominator);
          if (fractionValue > 10000) {
            setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
              { msg: "Amount must be less than or equal to 10,000" },
            ]);
            preventSetValue = true;
            hasErrors = true;
          } else if (fractionValue < 1) {
            setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
              { msg: "Amount must be greater than or equal to 1" },
            ]);
            hasErrors = true;
          }
        }
      } else if (!isNaN(value)) {
        // Handle plain numbers
        const numericValue = parseFloat(value);
        if (numericValue > 10000) {
          setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be less than or equal to 10,000" },
          ]);
          preventSetValue = true;
          hasErrors = true;
        } else if (numericValue < 1) {
          setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
            { msg: "Amount must be greater than or equal to 1" },
          ]);
          hasErrors = true;
        }
      } else {
        setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [{ msg: "Invalid amount" }]);
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`);
    }
    return { hasErrors, preventSetValue };
  };
  const verifyUnit = (searchableData) => {
    let hasErrors = false;

    if (searchableData.value.length > 32) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldUnit`, [
        { msg: "Yield Unit name must be less than 32 characters" },
      ]);
      hasErrors = true;
    }

    if (searchableData.value.length < 3 && searchableData.value.length > 0) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldUnit`, [
        { msg: "Yield Unit name must be at least 3 characters" },
      ]);
      hasErrors = true;
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldUnit`);
    }

    return hasErrors;
  };

  const verifyIngredient = (searchableData) => {
    let hasErrors = false;
    if (searchableData.value.length > 64) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`, [
        { msg: "Yield Ingredient name must be less than 64 characters" },
      ]);
      hasErrors = true;
    }

    if (searchableData.value.length < 3 && searchableData.value.length > 0) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`, [
        { msg: "Yield Ingredient name must be at least 3 characters" },
      ]);
      hasErrors = true;
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`);
    }

    return hasErrors;
  };

  const verifyServings = (value) => {
    let hasErrors = false;
    if (isNaN(value)) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.servings`, [{ msg: "Servings must be a number" }]);
      hasErrors = true;
    } else if (value < 0) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.servings`, [
        { msg: "Servings must be greater than or equal to 0" },
      ]);
      hasErrors = true;
    } else if (value > 100) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.servings`, [
        { msg: "Servings must be less than or equal to 100" },
      ]);
      hasErrors = true;
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.servings`);
    }

    return hasErrors;
  };

  const verifyServingSize = (value) => {
    let hasErrors = false;
    if (value.length > 48) {
      setCreateRecipeErrorByPath(`sections.${sectionIndex}.servingSize`, [
        { msg: "Serving size must be less than 48 characters" },
      ]);
      hasErrors = true;
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.servingSize`);
    }

    return hasErrors;
  };

  const handleClose = () => {
    //Verify values are valid
    let returnYieldAmount = yieldAmount;
    let returnYieldUnit = yieldUnit;
    let returnYieldIngredient = yieldIngredient;

    //If array values are empty, set to empty strings. If not, verify connections. Base values are verified in update and on backend
    if (typeof yieldAmount === "object" && yieldAmount.length === 0) {
      returnYieldAmount = "";
    } else {
      returnYieldAmount = yieldAmount;
    }
    if (typeof yieldUnit === "object" && yieldUnit.length === 0) {
      returnYieldUnit = "";
    } else {
      returnYieldUnit = yieldUnit;
    }
    if (typeof yieldIngredient === "object" && yieldIngredient.length === 0) {
      returnYieldIngredient = "";
    } else {
      returnYieldIngredient = yieldIngredient;
    }

    let hasErrors = false;

    if (returnYieldAmount) {
      const amountErrors = verifyAmount(returnYieldAmount);
      if (amountErrors.hasErrors) {
        hasErrors = true;
      }
    }
    if (returnYieldUnit) {
      const unitErrors = verifyUnit(returnYieldUnit);
      if (unitErrors) {
        hasErrors = true;
      }

      if (!returnYieldIngredient) {
        setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`, [
          { msg: "Yield ingredient is required if a unit is selected" },
        ]);
        hasErrors = true;
      }
    }
    if (returnYieldIngredient && returnYieldIngredient?.value?.length > 0) {
      const ingredientErrors = verifyIngredient(returnYieldIngredient);
      if (ingredientErrors) {
        hasErrors = true;
      }

      if (!returnYieldAmount) {
        setCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`, [
          { msg: "An output ingredient must have an amount" },
        ]);
        hasErrors = true;
      }
    }

    if (servings) {
      const servingsErrors = verifyServings(servings);
      if (servingsErrors) {
        hasErrors = true;
      }
    }

    if (servingSize) {
      const servingSizeErrors = verifyServingSize(servingSize);
      if (servingSizeErrors) {
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setAlert("Please resolve errors before saving", "warning");
      return;
    } else {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output`);
    }

    setSectionOutput(
      { servings, yield: { amount: returnYieldAmount, unit: returnYieldUnit, ingredient: returnYieldIngredient } },
      servingSize,
      sectionIndex
    );
    toggleRecipeOutputModal(false);
  };

  const [servings, setServings] = useState("");
  const [servingSize, setServingSize] = useState("");
  const [yieldAmount, setYieldAmount] = useState([]);
  const [yieldUnit, setYieldUnit] = useState([]);
  const [yieldIngredient, setYieldIngredient] = useState([]);

  const [showOutputExplainerModal, setShowOutputExplainerModal] = useState(false);

  //Local state handling
  useEffect(() => {
    if (sectionIndex !== null) {
      setServings(sections[sectionIndex]?.output?.servings || "");
      setServingSize(sections[sectionIndex]?.servingSize || "");
      setYieldAmount(sections[sectionIndex]?.output?.yield?.amount || "");
      setYieldUnit(sections[sectionIndex]?.output?.yield?.unit || []);
      setYieldIngredient(sections[sectionIndex]?.output?.yield?.ingredient || []);
    }

    return () => {
      setServings("");
      setServingSize("");
      setYieldAmount([]);
      setYieldUnit([]);
      setYieldIngredient([]);
    };
  }, [sectionIndex]);

  const onYieldAmountChange = (e) => {
    const value = e.target.value;

    const { hasErrors, preventSetValue } = verifyAmount(value);

    if (!preventSetValue) {
      setYieldAmount(value);
    }

    if (!hasErrors) {
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldAmount`);
    }
  };

  const onYieldUnitChange = (newValue) => {
    if (newValue === null || newValue?.value?.length === 0) {
      setYieldUnit([]);
      return;
    }

    const hasErrors = verifyUnit(newValue);
    if (!hasErrors) {
      setYieldUnit(newValue);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldUnit`);
    }
  };

  const onYieldIngredientChange = (newValue) => {
    if (newValue === null || newValue?.value?.length === 0) {
      setYieldIngredient([]);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`);
      return;
    }

    let hasErrors = verifyIngredient(newValue);

    if (!hasErrors) {
      setYieldIngredient(newValue);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.yieldIngredient`);
    }
  };

  const onServingsChange = (e) => {
    const value = e.target.value;

    const hasErrors = verifyServings(value);
    if (!hasErrors) {
      setServings(value);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.output.servings`);
    }
  };

  const onServingSizeChange = (e) => {
    const value = e.target.value;

    const hasErrors = verifyServingSize(value);
    if (!hasErrors) {
      setServingSize(value);
      clearCreateRecipeErrorByPath(`sections.${sectionIndex}.servingSize`);
    }
  };

  return (
    <CustomModal open={visible} handleClose={handleClose} type="bottomToCentered">
      <div className="flex flex-col px-4">
        <CustomModal
          open={showOutputExplainerModal}
          handleClose={() => setShowOutputExplainerModal(false)}
          type="bottomToCentered"
          parentClassNameOverride="md:max-w-[600px] lg:max-w-[700px] xl:max-w-[800px]"
        >
          <IngredientInfoModal initialViewType="output" />
        </CustomModal>
        <div className="flex flex-row justify-between items-center gap-2">
          <Typography variant="h6" className="mb-1 font-normal leading-none">
            {sections[sectionIndex]?.title ? `${sections[sectionIndex]?.title} Output` : "Section Output"}
          </Typography>
          <div>
            {!!errors?.sections?.[sectionIndex]?.output ? (
              <Button
                onClick={() => setShowOutputExplainerModal(true)}
                disableRipple
                variant="contained"
                color="primary"
                disableElevation
                className="flex flex-row items-center gap-1"
              >
                <Typography variant="caption" className="text-background font-medium text-xs">
                  Help
                </Typography>
                <InfoIcon className="h-3 w-3 fill-background" />
              </Button>
            ) : (
              <Button
                onClick={() => setShowOutputExplainerModal(true)}
                disableRipple
                variant="contained"
                color="secondary"
                disableElevation
                className="flex flex-row items-center gap-1"
              >
                <Typography variant="caption" className="text-primaryText text-xs">
                  Help
                </Typography>
                <InfoIcon className="h-3 w-3 fill-primaryText" />
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-4 py-4">
          <div className="flex flex-col gap-2">
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Total Yield:
              </Typography>
            </div>
            <div className="flex flex-row w-full gap-2">
              <div className="w-1/2">
                <TextField
                  label="Yield Amount"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 7,
                  }}
                  onChange={(e) => onYieldAmountChange(e)}
                  value={yieldAmount}
                  error={!!errors?.sections?.[sectionIndex]?.output?.yieldAmount}
                  helperText={
                    errors?.sections?.[sectionIndex]?.output?.yieldAmount
                      ? errors?.sections?.[sectionIndex]?.output?.yieldAmount?.map((error) => error.msg).join(", ")
                      : "Eg. 12, 1/2, 0.75"
                  }
                />
              </div>
              <div className="w-1/2">
                <Searchable
                  label="Yield Type/Unit"
                  labelWidth={125}
                  multiple={false}
                  freeSolo={true}
                  apiUrl="/units/searchable"
                  allowAdd={true}
                  onItemChange={onYieldUnitChange}
                  fullWidth
                  selectedItemsFromParent={yieldUnit}
                  error={!!errors?.sections?.[sectionIndex]?.output?.yieldUnit}
                  helperText={
                    errors?.sections?.[sectionIndex]?.output?.yieldUnit
                      ? errors?.sections?.[sectionIndex]?.output?.yieldUnit?.map((error) => error.msg).join(", ")
                      : "Eg. ml, cups, cookies"
                  }
                  maxInputLength={32}
                />
              </div>
            </div>

            <div>
              <Searchable
                label="Yield Ingredient"
                labelWidth={130}
                multiple={false}
                freeSolo={true}
                apiUrl="/ingredients/searchable"
                allowAdd={true}
                onItemChange={onYieldIngredientChange}
                selectedItemsFromParent={yieldIngredient}
                error={!!errors?.sections?.[sectionIndex]?.output?.yieldIngredient}
                helperText={
                  errors?.sections?.[sectionIndex]?.output?.yieldIngredient
                    ? errors?.sections?.[sectionIndex]?.output?.yieldIngredient?.map((error) => error.msg).join(", ")
                    : "What product does the section make? Eg. banana bread"
                }
                maxInputLength={64}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <Typography variant="subtitle1" className="mb-2 font-normal">
                Servings Info:
              </Typography>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <TextField
                label="Servings"
                variant="outlined"
                fullWidth
                value={servings}
                onChange={onServingsChange}
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                error={!!errors?.sections?.[sectionIndex]?.output?.servings}
                helperText={
                  errors?.sections?.[sectionIndex]?.output?.servings
                    ? errors?.sections?.[sectionIndex]?.output?.servings?.map((error) => error.msg).join(", ")
                    : "How many people does this serve?"
                }
              />
              <TextField
                label="Serving Size"
                variant="outlined"
                fullWidth
                value={servingSize}
                onChange={onServingSizeChange}
                error={!!errors?.sections?.[sectionIndex]?.servingSize}
                helperText={
                  errors?.sections?.[sectionIndex]?.servingSize
                    ? errors?.sections?.[sectionIndex]?.servingSize?.map((error) => error.msg).join(", ")
                    : "Size of one serving. Eg. 1 cup, 100g"
                }
                inputProps={{
                  maxLength: 48,
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end mb-4">
          <Button variant="contained" color="primary" onClick={handleClose} disableElevation fullWidth>
            Save
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

RecipeOutputModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  setAlert: PropTypes.func.isRequired,
  setSectionOutput: PropTypes.func.isRequired,
  errors: PropTypes.object,
  clearCreateRecipeErrorByPath: PropTypes.func.isRequired,
  setCreateRecipeErrorByPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  visible: state.recipe.create.modals.editOutput.open,
  sectionIndex: state.recipe.create.modals.editOutput.sectionIndex,
  sections: state.recipe.create.sections,
  errors: state.recipe.create?.errors,
});

export default connect(mapStateToProps, {
  toggleRecipeOutputModal,
  setAlert,
  setSectionOutput,
  clearCreateRecipeErrorByPath,
  setCreateRecipeErrorByPath,
})(RecipeOutputModal);
