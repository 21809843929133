import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Select, MenuItem, Button, IconButton, Slide, useScrollTrigger } from "@mui/material";
import { ChevronDown } from "lucide-react";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../../ui/dropdown-menu";
import { Button as ShadcnButton } from "../../../../ui/button";
//Actions
import { setWalkthroughSection } from "../../../../../actions/walkthrough";

import { Link } from "react-router-dom";
import { DropdownMenuCheckboxItem } from "@radix-ui/react-dropdown-menu";
const WalkthroughNav = ({
  walkthrough: { recipe, user, currentSection },
  setWalkthroughSection,
  handleFinishRecipe,
  setNavHeight,
}) => {
  const trigger = useScrollTrigger();

  const navRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (navRef.current) {
        const height = Math.floor(navRef.current.getBoundingClientRect().height);
        setNavHeight(height);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the height

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Slide in={!trigger} direction="down">
      <div
        className={`fixed top-0 left-0 right-0 bg-background z-50 md:ml-[5rem] flex border-b border-separator ${
          recipe?.sections && recipe?.sections?.length > 1 ? "flex-col" : "flex-col md:flex-row md:justify-between"
        }`}
        ref={navRef}
      >
        <div className="px-4 md:px-8 pb-4 pt-[calc(env(safe-area-inset-top)+1rem)]">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col flex-grow">
              <Link to={user && recipe && user.username && recipe.url ? `/${user.username}/${recipe.url}` : `/`}>
                <Typography variant="h5" className="font-semibold md:max-w-70%">
                  {recipe && recipe.name}
                </Typography>
              </Link>

              <Typography variant="subtitle1" className="font-normal text-mainGreen">
                {user && "@" + user.username}
              </Typography>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-end px-4 md:px-8 pb-4 md:pb-8 flex-col md:flex-row">
          {recipe?.sections && recipe?.sections?.length > 1 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ShadcnButton
                  variant="outline"
                  className="w-full md:w-auto md:min-w-[400px] justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                >
                  {currentSection === "all"
                    ? "All Sections"
                    : recipe && recipe?.sections?.[currentSection]?.title
                    ? recipe.sections[currentSection].title
                    : "No sections"}
                  <ChevronDown className="w-3 opacity-50 ml-2" />
                </ShadcnButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                <DropdownMenuItem
                  onSelect={() => {
                    const selectedIndex = "all";
                    setWalkthroughSection(selectedIndex);
                  }}
                >
                  All Sections
                </DropdownMenuItem>
                {recipe && recipe.sections ? (
                  recipe.sections.map((section) => (
                    <DropdownMenuItem
                      key={section.id}
                      onSelect={() => {
                        const selectedIndex = recipe.sections.findIndex((s) => s.title === section.title);
                        setWalkthroughSection(selectedIndex);
                      }}
                    >
                      {section.title}
                    </DropdownMenuItem>
                  ))
                ) : (
                  <DropdownMenuItem>No sections</DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          <div className="w-full md:w-auto">
            <Button
              variant="contained"
              className="mt-2 md:mt-0 bg-mainGreen text-background w-full md:w-auto"
              disableElevation
              size="small"
              onClick={() => handleFinishRecipe()}
            >
              <Typography variant="subtitle1">Finish Recipe</Typography>
            </Button>
          </div>
        </div>
      </div>
    </Slide>
  );
};

WalkthroughNav.propTypes = {
  walkthrough: PropTypes.object.isRequired,
  setWalkthroughSection: PropTypes.func.isRequired,
  handleFinishRecipe: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, { setWalkthroughSection })(WalkthroughNav);
