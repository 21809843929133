import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pluralize } from "inflection";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  List,
  ListItem,
  Divider,
  IconButton,
} from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronDown, faChevronUp, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/svg/edit.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/leaf-solid.svg";
import { ReactComponent as ErrorTriangleIcon } from "../../../../assets/icons/svg/error-triangle.svg";

//Components
import CustomModal from "../../../modal/CustomModal";
import RecipeSectionIngredient from "./RecipeSectionIngredient";
import RecipeSectionStep from "./RecipeSectionStep";
import RecipeEditSectionModal from "./RecipeEditSectionModal";
import RecipeSectionVideoModal from "./RecipeSectionVideoModal";
//Actions
import {
  processIngredients,
  addStep,
  toggleRecipeMacrosModal,
  toggleRecipeOutputModal,
  toggleRecipeTimingModal,
  moveIngredient,
} from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

//Utils
import { convertToTitleCase } from "../../../../utils/recipe";

//DnD
import { Droppable } from "@hello-pangea/dnd";

//ReactPlayer
import ReactPlayer from "react-player";

const RecipeSection = ({
  processIngredients,
  addStep,
  toggleRecipeMacrosModal,
  toggleRecipeOutputModal,
  toggleRecipeTimingModal,
  section,
  sectionIndex,
  recipe,
  setAlert,
  errors,
  moveIngredient,
}) => {
  //Ingredient Input State
  const [ingredientInput, setIngredientInput] = useState("");

  //Focus state
  const [highlightIngredient, setHighlightIngredient] = useState(false);
  const [highlightStep, setHighlightStep] = useState(false);

  const [showTiming, setShowTiming] = useState(false);
  const [showYield, setShowYield] = useState(false);

  const handleIngredientsSubmit = (ingredientText, sectionIndex) => {
    // Split the text into lines and process each line
    var lines = ingredientText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0 && !/^[\s\p{P}]*$/.test(line));

    const added = processIngredients(lines, sectionIndex);

    if (added) {
      setIngredientInput("");
      setHighlightIngredient(false);
    }
  };

  const handleIngredientsChange = (e) => {
    setIngredientInput(e.target.value);
  };

  //Steps Input State
  const [stepInput, setStepInput] = useState("");

  const handleStepSubmit = (index) => {
    //Trim text
    let trimmed = stepInput.trim();

    if (trimmed === "") {
      setAlert("Step cannot be empty", "warning");
      return;
    }

    if (trimmed.length > 1024) {
      setAlert("Step cannot be longer than 1024 characters", "warning");
      return;
    }

    addStep(index, trimmed);

    setStepInput("");
    setHighlightStep(false);
  };

  const handleStepChange = (e) => {
    if (e.target.value.length > 1024) {
      setAlert("Step cannot be longer than 1024 characters", "warning");
      return;
    }
    setStepInput(e.target.value);
  };

  const [titleHover, setTitleHover] = useState(false);

  //Modal state
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleShowEditSectionModal = () => {
    setShowEditSectionModal(true);
  };

  const handleCloseEditSectionModal = () => {
    setShowEditSectionModal(false);
  };

  const handleShowVideoModal = () => {
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const handleTitleMouseEnter = () => {
    setTitleHover(true);
  };

  const handleTitleMouseLeave = () => {
    setTitleHover(false);
  };

  const checkShowTiming = () => {
    let shouldShow = false;
    if (section.timing) {
      if (section.timing.prep) {
        if (section.timing.prep.hours !== "" || section.timing.prep.minutes !== "") {
          shouldShow = true;
        }
      }
      if (section.timing.cook) {
        if (section.timing.cook.hours !== "" || section.timing.cook.minutes !== "") {
          shouldShow = true;
        }
      }
      if (section.timing.rest) {
        if (section.timing.rest.hours !== "" || section.timing.rest.minutes !== "") {
          shouldShow = true;
        }
      }
      if (section.timing.total) {
        if (section.timing.total.hours !== "" || section.timing.total.minutes !== "") {
          shouldShow = true;
        }
      }
    }
    setShowTiming(shouldShow);
  };

  const checkShowYield = () => {
    let shouldShow = false;
    if (section.output) {
      if (section.output.yield) {
        if (section?.output?.yield?.amount && section?.output?.yield?.amount?.length > 0) {
          shouldShow = true;
        }
        if (section?.output?.yield?.ingredient?.name && section?.output?.yield?.ingredient?.name?.length > 0) {
          shouldShow = true;
        }
        if (section?.output?.yield?.unit?.name && section?.output?.yield?.unit?.name?.length > 0) {
          shouldShow = true;
        }
      }
    }
    setShowYield(shouldShow);
  };

  useEffect(() => {
    checkShowTiming();
    checkShowYield();
  }, [section]);

  const countErrors = (errors) => {
    if (!errors) {
      return 0;
    }

    const deepCountKeys = (obj) => {
      let count = 0;

      // Return 0 if obj is null/undefined or an empty object
      if (!obj || (typeof obj === "object" && Object.keys(obj).length === 0)) {
        return 0;
      }

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];

          if (Array.isArray(value)) {
            // For arrays, only count non-null elements that contain error messages
            value.forEach((item) => {
              if (item && typeof item === "object") {
                if (item.msg) {
                  // Direct error message
                  count++;
                } else {
                  // Nested object within array
                  const nestedCount = deepCountKeys(item);
                  count += nestedCount;
                }
              }
            });
          } else if (value && typeof value === "object") {
            // For objects, recursively count their errors
            const nestedCount = deepCountKeys(value);
            count += nestedCount;
          }
        }
      }
      return count;
    };

    return deepCountKeys(errors);
  };

  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    setErrorCount(countErrors(errors));
  }, [errors]);

  const showOutputErrorHighlight = () => {
    if (errors?.output?.yieldAmount || errors?.output?.yieldUnit || errors?.output?.yieldIngredient) {
      return true;
    }
    return false;
  };

  return (
    <div key={sectionIndex} className="border border-separator md:rounded-md shadow-sm bg-background">
      <CustomModal open={showEditSectionModal} handleClose={handleCloseEditSectionModal} type="bottomToCentered">
        <RecipeEditSectionModal
          section={section}
          sectionIndex={sectionIndex}
          handleClose={handleCloseEditSectionModal}
        />
      </CustomModal>
      <CustomModal open={showVideoModal} handleClose={handleCloseVideoModal} type="bottomToCentered">
        <RecipeSectionVideoModal section={section} sectionIndex={sectionIndex} handleClose={handleCloseVideoModal} />
      </CustomModal>

      <div className="flex flex-col p-4 md:px-8 md:py-6 bg-neutral-100 rounded-t-md">
        <div className="flex flex-row items-start ">
          <Typography
            variant="h5"
            className="text-primaryText cursor-pointer leading-none break-words whitespace-normal"
            onClick={handleShowEditSectionModal}
            onMouseEnter={handleTitleMouseEnter}
            onMouseLeave={handleTitleMouseLeave}
          >
            {section.title}
          </Typography>
          {titleHover && <EditIcon className="h-3 fill-secondaryText-600 ml-3 shrink-0 my-[0.375rem]" />}
        </div>
        {errorCount !== 0 && (
          <div className="flex flex-row gap-2 items-start mt-2">
            <ErrorTriangleIcon className="h-4 shrink-0 fill-rose-600" />
            <Typography variant="subtitle2" className="text-rose-600 font-normal leading-none text-xs">
              {errorCount} error{errorCount > 1 ? "s" : ""}! Please fix {errorCount > 1 ? "them" : "it"} before
              proceeding.
            </Typography>
          </div>
        )}
        {errors?.info && errors?.info?.length > 0 && (
          <div className="flex flex-col mt-4 w-full">
            <div>
              <Typography variant="subtitle2" className="text-primaryText leading-none text-xs font-normal">
                General Errors:
              </Typography>
            </div>
            <div className="flex flex-col mt-2">
              <List className="list-disc pl-4 py-0">
                {errors.info.map((error, index) => (
                  <ListItem key={index} className="marker:text-rose-600 list-item">
                    <Typography variant="subtitle2" className="text-rose-600 font-normal leading-none text-xs">
                      <span>{error.msg}</span>
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        )}
      </div>

      {section &&
        section?.source &&
        (section.source?.type ? (
          <div className="px-4 pt-2 pb-4 md:px-8 border-b border-separator">
            <Typography variant="caption" className="text-secondaryText leading-none">
              {section.source.type}:
            </Typography>
            <div className="pt-1">
              {section.source.text === "thisPantry+" ? (
                <div className="flex flex-row gap-1 items-center">
                  <Typography variant="caption" className="text-primaryText leading-none">
                    thisPantry<span className="font-semibold text-mainGreen">+</span>
                  </Typography>
                  {section.source.text === "thisPantry+" && <PlusIcon className="w-3 fill-mainGreen" />}
                </div>
              ) : (
                <div className="text-xs">
                  <Typography variant="caption" className="text-secondaryText-700 leading-none">
                    {section.source.text}
                  </Typography>
                </div>
              )}

              {section.source.url && (
                <a href={section.source.url} target="_blank" rel="noopener noreferrer" className="truncate text-xs">
                  <Typography variant="caption" className="font-normal text-mainGreen leading-none">
                    {section.source.url}
                  </Typography>
                </a>
              )}
            </div>
          </div>
        ) : null)}

      <div className="p-4 md:px-8 md:py-6">
        <div>
          <div className="text-primaryText">
            <Typography variant="subtitle1" className="font-normal">
              Ingredients:
            </Typography>
          </div>
          <div>
            {section.ingredients.length > 0 && (
              <Droppable droppableId={`section-${sectionIndex}`}>
                {(provided, snapshot) => (
                  <List
                    className={`list-disc pl-4 ${snapshot.isDraggingOver ? "bg-gray-50 rounded-md" : ""}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {section.ingredients.map((ingredient, ingredientIndex) => (
                      <RecipeSectionIngredient
                        key={`ingredient-${sectionIndex}-${ingredientIndex}`}
                        ingredient={ingredient}
                        ingredientIndex={ingredientIndex}
                        sectionIndex={sectionIndex}
                        errors={errors?.ingredients?.[ingredientIndex]}
                      />
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            )}
          </div>
          <div className="my-2">
            <TextField
              fullWidth
              label="Add Ingredient"
              variant="outlined"
              value={ingredientInput}
              onChange={(e) => handleIngredientsChange(e)}
              onFocus={() => setHighlightIngredient(true)}
              onBlur={() => setHighlightIngredient(ingredientInput.length > 0)}
              InputProps={{
                startAdornment:
                  recipe.create &&
                  recipe.create.ingredientsProcessing &&
                  recipe.create.ingredientsProcessing.loading ? (
                    <InputAdornment position="start">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ) : null,
              }}
              disabled={recipe.create.ingredientsProcessing.loading}
              inputProps={{ maxLength: 1024 }}
            />
            {highlightIngredient ? (
              <Button
                className="mt-2"
                variant="contained"
                color="primary"
                disableElevation
                disableRipple
                onClick={() => handleIngredientsSubmit(ingredientInput, sectionIndex)}
                disabled={recipe.create.ingredientsProcessing.loading}
                size="small"
                fullWidth
              >
                <Typography variant="caption">+ Add Ingredient</Typography>
              </Button>
            ) : (
              <Button
                className="mt-2 border-secondaryText-200 hover:border-secondaryText"
                variant="contained"
                color="secondary"
                disableElevation
                disableRipple
                onClick={() => handleIngredientsSubmit(ingredientInput, sectionIndex)}
                disabled={recipe.create.ingredientsProcessing.loading}
                size="small"
                fullWidth
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Ingredient
                </Typography>
              </Button>
            )}
          </div>
        </div>

        <div className="py-4">
          <div className="text-primaryText">
            <Typography variant="subtitle1" className="font-normal">
              Steps:
            </Typography>
          </div>
          <div>
            {section.steps.length > 0 && (
              <List className="list-decimal pl-4">
                {section.steps.map((step, stepIndex) => (
                  <RecipeSectionStep
                    key={stepIndex}
                    step={step}
                    stepIndex={stepIndex}
                    sectionIndex={sectionIndex}
                    errors={errors?.steps?.[stepIndex]}
                  />
                ))}
              </List>
            )}
          </div>
          <div className="my-2">
            <TextField
              fullWidth
              label="Add Step"
              variant="outlined"
              onFocus={() => setHighlightStep(true)}
              onBlur={() => setHighlightStep(stepInput.length > 0)}
              value={stepInput}
              onChange={(e) => handleStepChange(e)}
              multiline
              inputProps={{ maxLength: 1024 }}
            />
            {highlightStep ? (
              <Button
                className="mt-2"
                variant="contained"
                color="primary"
                disableElevation
                disableRipple
                onClick={() => handleStepSubmit(sectionIndex)}
                size="small"
                fullWidth
              >
                <Typography variant="caption">+ Add Step</Typography>
              </Button>
            ) : (
              <Button
                className="mt-2 border-secondaryText-200 hover:border-secondaryText"
                variant="contained"
                color="secondary"
                disableElevation
                disableRipple
                onClick={() => handleStepSubmit(sectionIndex)}
                size="small"
                fullWidth
              >
                <Typography variant="caption" className="text-primaryText">
                  + Add Step
                </Typography>
              </Button>
            )}
          </div>
        </div>
        <div className="py-4">
          <div className="mb-4">
            <Typography variant="subtitle1" className="font-normal">
              Output:
            </Typography>
          </div>
          <div>
            <div
              className="flex flex-col gap-2 cursor-pointer"
              onClick={() => toggleRecipeOutputModal(true, sectionIndex)}
            >
              <div className="flex flex-col md:flex-row justify-between gap-2 items-start mb-4">
                <div className="flex flex-col gap-2 mb-4 md:mb-0 w-full md:w-1/2">
                  <div className="flex flex-row items-center gap-2">
                    <Typography
                      variant="subtitle2"
                      className={`leading-none font-normal ${showOutputErrorHighlight() ? "text-rose-600" : ""}`}
                    >
                      Total Yield:
                    </Typography>
                    {showOutputErrorHighlight() && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                  </div>
                  {showYield ? (
                    <Typography variant="body2" className="leading-none font-normal">
                      <span className="font-normal text-secondaryText-600">
                        {`${section.output?.yield?.amount} ${
                          section.output.yield?.unit?.name ? section.output.yield.unit.name : " "
                        }`}
                      </span>

                      <span className="font-medium">
                        {` ${convertToTitleCase(
                          section.output.yield?.ingredient?.name ? section.output.yield.ingredient.name : ""
                        )}`}
                      </span>
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="leading-none font-normal text-secondaryText">
                      -
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col gap-4 w-full md:w-1/2">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <Typography
                        variant="subtitle2"
                        className={`leading-none font-normal ${errors?.output?.servings ? "text-rose-600" : ""}`}
                      >
                        Servings:
                      </Typography>
                      {errors?.output?.servings && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                    </div>

                    <Typography variant="body2" className="font-normal text-secondaryText-600">
                      {section?.output?.servings ? section.output.servings : "-"}
                    </Typography>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <Typography
                        variant="subtitle2"
                        className={`leading-none font-normal ${errors?.servingSize ? "text-rose-600" : ""}`}
                      >
                        Serving Size:
                      </Typography>
                      {errors?.servingSize && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                    </div>
                    <Typography variant="body2" className="font-normal text-secondaryText-600">
                      {section?.servingSize ? section.servingSize : "-"}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disableElevation
                onClick={() => toggleRecipeOutputModal(true, sectionIndex)}
                size="small"
              >
                <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                  <EditIcon className="h-3 mr-2" />
                  Edit Output
                </Typography>
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mt-4 gap-4">
          <div>
            <Typography variant="subtitle1" className="leading-none font-normal">
              Additional Info:
            </Typography>
          </div>

          {showTiming && (
            <div className="pt-4">
              <div
                className="flex flex-col gap-2 cursor-pointer"
                onClick={() => toggleRecipeTimingModal(true, sectionIndex)}
              >
                <Typography variant="subtitle1" className="leading-none font-normal">
                  Timing:
                </Typography>
                <div className="grid grid-cols-2 gap-4 py-2">
                  {section?.timing &&
                    section?.timing?.prep &&
                    (section?.timing?.prep?.hours > 0 || section?.timing?.prep?.minutes > 0) && (
                      <div>
                        <div className="flex flex-row items-center gap-2">
                          <Typography
                            variant="body2"
                            component="p"
                            className={`font-normal ${errors?.timing?.prep ? "text-rose-600" : ""}`}
                          >
                            Prep:
                          </Typography>
                          {errors?.timing?.prep && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                        </div>

                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.prep.hours > 0 ? `${section?.timing?.prep.hours} hrs ` : ""}${
                            section?.timing?.prep.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                  {section?.timing &&
                    section?.timing?.rest &&
                    (section?.timing?.rest?.hours > 0 || section?.timing?.rest?.minutes > 0) && (
                      <div>
                        <div className="flex flex-row items-center gap-2">
                          <Typography
                            variant="body2"
                            component="p"
                            className={`font-normal ${errors?.timing?.rest ? "text-rose-600" : ""}`}
                          >
                            Rest:
                          </Typography>
                          {errors?.timing?.rest && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                        </div>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.rest.hours > 0 ? `${section?.timing?.rest.hours} hrs ` : ""}${
                            section?.timing?.rest.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                  {section?.timing &&
                    section?.timing?.cook &&
                    (section?.timing?.cook?.hours > 0 || section?.timing?.cook?.minutes > 0) && (
                      <div>
                        <div className="flex flex-row items-center gap-2">
                          <Typography
                            variant="body2"
                            component="p"
                            className={`font-normal ${errors?.timing?.cook ? "text-rose-600" : ""}`}
                          >
                            Cook:
                          </Typography>
                          {errors?.timing?.cook && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                        </div>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.cook.hours > 0 ? `${section?.timing?.cook.hours} hrs ` : ""}${
                            section?.timing?.cook.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                  {section?.timing &&
                    section?.timing?.total &&
                    (section?.timing?.total?.hours > 0 || section?.timing?.total?.minutes > 0) && (
                      <div>
                        <div className="flex flex-row items-center gap-2">
                          <Typography
                            variant="body2"
                            component="p"
                            className={`font-normal ${errors?.timing?.total ? "text-rose-600" : ""}`}
                          >
                            Total:
                          </Typography>
                          {errors?.timing?.total && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                        </div>
                        <Typography variant="body2" component="p" className="text-secondaryText font-normal">
                          {`${section?.timing?.total.hours > 0 ? `${section?.timing?.total.hours} hrs ` : ""}${
                            section?.timing?.total.minutes
                          } mins`}
                        </Typography>
                      </div>
                    )}
                </div>
              </div>
              <div className="mt-2">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disableElevation
                  onClick={() => toggleRecipeTimingModal(true, sectionIndex)}
                  size="small"
                >
                  <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                    <EditIcon className="h-3 mr-2" />
                    Edit Timing
                  </Typography>
                </Button>
              </div>
            </div>
          )}

          {section.macros && section.macros.length > 0 && (
            <div className="pt-4">
              <div
                className="flex flex-col gap-2 cursor-pointer mb-4"
                onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}
              >
                <Typography variant="subtitle1" className="leading-none font-normal">
                  Macronutrients:
                </Typography>
                <Typography variant="subtitle2" className="leading-none font-normal text-secondaryText-600">
                  Serving Size: <span className="italic">{section.servingSize ? section.servingSize : "-"}</span>
                </Typography>
              </div>
              <div className="py-2 mb-2 cursor-pointer" onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}>
                {section.macros.map((macro, i) => (
                  <div
                    className="flex flex-row justify-between items-center"
                    key={`${sectionIndex}-${macro.name}-${i}`}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <Typography
                        variant="subtitle2"
                        className={`font-normal ${errors?.macros?.[i] ? "text-rose-600" : ""}`}
                      >
                        {macro.macronutrient?.display ? macro.macronutrient.display : macro.macronutrient.name}
                      </Typography>
                      {errors?.macros?.[i] && <ErrorTriangleIcon className="h-3 fill-rose-600" />}
                    </div>

                    <div
                      className={`flex-1 border-b border-separator mx-2 ${
                        errors?.macros?.[i] ? "border-rose-600/50" : ""
                      }`}
                    ></div>
                    <div className="flex flex-row">
                      <Typography variant="body2" className={`${errors?.macros?.[i] ? "text-rose-600" : ""}`}>
                        {macro.amount}
                      </Typography>
                      {macro.macronutrient?.unit && (
                        <Typography variant="body2" className={`ml-2 ${errors?.macros?.[i] ? "text-rose-600" : ""}`}>
                          {macro.macronutrient.unit}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disableElevation
                onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}
                size="small"
              >
                <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                  <EditIcon className="h-3 mr-2" />
                  Edit Macros
                </Typography>
              </Button>
            </div>
          )}

          {section && section?.video && section?.video.length > 0 && ReactPlayer.canPlay(section.video) && (
            <>
              <div className="w-full mt-4" style={{ position: "relative", paddingTop: "56.25%", borderRadius: "16px" }}>
                <ReactPlayer
                  url={section.video}
                  controls={true}
                  width="100%"
                  height="100%"
                  style={{ position: "absolute", top: 0, left: 0 }}
                />
              </div>
              <div>
                <Button
                  className="border-secondaryText-200 hover:border-secondaryText"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disableRipple
                  onClick={handleShowVideoModal}
                  size="small"
                  fullWidth
                >
                  <Typography variant="caption" className="text-primaryText flex flex-row items-center">
                    <EditIcon className="h-3 mr-2" />
                    Edit Embedded Video
                  </Typography>
                </Button>
              </div>
            </>
          )}

          {!showTiming && (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disableElevation
              onClick={() => toggleRecipeTimingModal(true, sectionIndex)}
              size="small"
            >
              <Typography variant="caption" className="text-primaryText">
                + Add Timing
              </Typography>
            </Button>
          )}

          {!(section.macros && section.macros.length > 0) && (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disableElevation
              onClick={() => toggleRecipeMacrosModal(true, sectionIndex)}
              size="small"
            >
              <Typography variant="caption" className="text-primaryText">
                + Add Macros
              </Typography>
            </Button>
          )}

          {!section?.video && (
            <Button
              className="border-secondaryText-200 hover:border-secondaryText"
              variant="contained"
              color="secondary"
              disableElevation
              disableRipple
              onClick={handleShowVideoModal}
              size="small"
              fullWidth
            >
              <Typography variant="caption" className="text-primaryText">
                + Embed Video
              </Typography>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

RecipeSection.propTypes = {
  processIngredients: PropTypes.func.isRequired,
  addStep: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  recipe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  toggleRecipeMacrosModal: PropTypes.func.isRequired,
  toggleRecipeOutputModal: PropTypes.func.isRequired,
  toggleRecipeTimingModal: PropTypes.func.isRequired,
  moveIngredient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, {
  processIngredients,
  addStep,
  setAlert,
  toggleRecipeMacrosModal,
  toggleRecipeOutputModal,
  toggleRecipeTimingModal,
  moveIngredient,
})(RecipeSection);
