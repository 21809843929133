//Create a MUI v4 modal template that matches the style of the LoginModal
import React, { useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { connect } from "react-redux";

//Breakpoints
import { useMediaQuery, useTheme } from "@mui/material";

import { ReactComponent as ExitIcon } from "../../assets/icons/svg/exit.svg";

//Hook to block scrolling when modal is open
import { useModalScrollLock } from "../../hooks/useModalScrollLock";

//TO DO:
//Types -> centered, bottom (at right edge for desktop?)
//Framer appear animations (for centered or bottom)

//Account for desktop nav on centered (set as true/false to do so?)
const CustomModal = ({
  open,
  handleClose,
  children,
  showExit = true,
  contentStyleOverride = {},
  contentClassNameOverride = "",
  parentClassNameOverride = "",
  type = "centered",
  disableScrollLock = false,
  disableBodyScroll = true,
  border = true,
  device: { platform },
  iOSBottomPadding = true,
}) => {
  //TODO:Stop background scrolling when modal is open
  const contentRef = useRef(null);

  //Block scrolling when modal is open
  useModalScrollLock(open && !disableScrollLock);

  //Pass scrollToTop functionality to child components (to scroll to the top of the content)
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  //Breakpoints
  const theme = useTheme();
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up("md"));

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { scrollToTop: scrollToTop });
    }
    return child;
  });

  const centeredVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  const bottomVariants = {
    hidden: { opacity: 0, y: "100%", x: "-50%" },
    visible: { opacity: 1, y: "0%", x: "-50%" },
    exit: { opacity: 0, y: "100%", x: "-50%" },
  };

  const BottomContent = (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableScrollLock={disableScrollLock}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <motion.div
          initial="hidden"
          animate={open ? "visible" : "hidden"}
          exit="exit"
          variants={bottomVariants}
          transition={{ duration: 0.175 }}
          className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-[100vw] md:max-w-[80vw] lg:max-w-[60vw] xl:max-w-[50vw] md:ml-[2.5rem] ${parentClassNameOverride}`}
        >
          <Paper className="outline-none mx-auto w-full " elevation={0}>
            <div
              className={`w-full bg-background rounded-t-lg ${border ? "border border-solid border-separator" : ""}`}
            >
              {showExit && (
                <div className="w-full h-fit flex items-center justify-between flex-row top-0 py-2 fixed px-4 bg-background rounded-t-lg">
                  <div></div>
                  <IconButton
                    aria-label="exit"
                    onClick={() => handleClose()}
                    className="text-none hover:bg-transparent px-0 py-1"
                    size="large"
                    disableRipple
                  >
                    <ExitIcon className="h-[1rem] fill-primaryText" />
                  </IconButton>
                </div>
              )}

              <div
                className={`w-full min-w-0 min-h-[10vh] max-h-[95vh] overflow-y-auto ${
                  platform && platform === "ios" && iOSBottomPadding ? "pb-[env(safe-area-inset-bottom)]" : ""
                } ${showExit ? "mt-10" : "mt-0"} ${contentClassNameOverride}`}
                style={contentStyleOverride}
                ref={contentRef}
              >
                {childrenWithProps}
              </div>
            </div>
          </Paper>
        </motion.div>
      </Modal>
    </div>
  );

  const CenteredContent = (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableScrollLock={disableScrollLock}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <motion.div
          initial="hidden"
          animate={open ? "visible" : "hidden"}
          exit="exit"
          variants={centeredVariants}
          transition={{ duration: 0.125 }}
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:ml-[2.5rem] ${parentClassNameOverride}`}
        >
          <Paper
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 outline-none mx-auto"
            elevation={0}
          >
            <div className={`w-full bg-background rounded-lg ${border ? "border border-solid border-separator" : ""}`}>
              {showExit && (
                <div className="w-full h-fit flex items-center justify-between flex-row top-0 py-2 fixed px-4 mb-2 ">
                  <div></div>
                  <IconButton
                    aria-label="exit"
                    onClick={() => handleClose()}
                    className="text-none hover:bg-transparent px-0 py-1"
                    size="large"
                    disableRipple
                  >
                    <ExitIcon className="h-[1rem] fill-primaryText" />
                  </IconButton>
                </div>
              )}

              <div
                className={`w-[90vw] min-w-0 min-h-[10vh] max-h-[85vh] overflow-y-auto md:w-[70vw] md:max-w-[600px] ${
                  showExit ? "mt-9 " : "mt-0 "
                }
                     ${contentClassNameOverride}`}
                style={contentStyleOverride}
                ref={contentRef}
              >
                {childrenWithProps}
              </div>
            </div>
          </Paper>
        </motion.div>
      </Modal>
    </div>
  );

  if (type === "centered") {
    return CenteredContent;
  } else if (type === "bottom") {
    return BottomContent;
  } else if (type === "bottomToCentered") {
    if (isMediumOrLarger) {
      return CenteredContent;
    } else {
      return BottomContent;
    }
  }
};

CustomModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  showExit: PropTypes.bool,
  contentStyleOverride: PropTypes.object,
  contentClassNameOverride: PropTypes.string,
  type: PropTypes.oneOf(["centered", "bottom", "bottomToCentered"]),
  disableScrollLock: PropTypes.bool,
  disableBodyScroll: PropTypes.bool,
  device: PropTypes.object,
};

const mapStateToProps = (state) => ({
  device: state.device,
});

export default connect(mapStateToProps)(CustomModal);
