import React, { useState, Fragment, useEffect } from "react";
import { Button, TextField, Typography } from "@mui/material";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button as ShadcnButton } from "../ui/button";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Helmet from "react-helmet";

//Components
import RecipeSectionManager from "./components/recipe/RecipeSectionManager";
import ImageDropzone from "./components/images/ImageDropzone";
import ImageReorderPreview from "./components/images/ImageReorderPreview";
import RecipeSectionTags from "./components/recipe/RecipeSectionTags";
import Searchable from "../layout/searchable/Searchable";

//Icons
import { ReactComponent as ChevronDown } from "../../assets/icons/svg/chevron-down.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/plus-solid.svg";

//Actions
import {
  processIngredients,
  fetchRecipeToEdit,
  resetCreateRecipe,
  setCreateRecipeErrors,
  resetCreateRecipeErrors,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
} from "../../actions/createrecipe";
import { setAlert } from "../../actions/alert";
import { imageUploadReset, setOriginalImages } from "../../actions/media";
import { createRecipe, editRecipe } from "../../actions/recipe";
import { togglePlusUpgradeModal } from "../../actions/plus";

//Modals
import CustomModal from "../modal/CustomModal";
import AddRecipeSectionModal from "./components/recipe/AddRecipeSectionModal";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import PlusModal from "../plus/PlusModal";
import RecipeMacrosModal from "./components/recipe/RecipeMacrosModal";
import RecipeOutputModal from "./components/recipe/RecipeOutputModal";
import RecipeTimingModal from "./components/recipe/RecipeTimingModal";
import Spinner from "../layout/Spinner";
//TO DO: Make sure if ounce unit and first time for ingredient that the ounce type is logged\

//TO DO:
//Horizontal scrollable carousel for uploaded images with delete button on top right, click to edit crop?

const CreateRecipe = ({
  recipe: { create, edit },
  user,
  setAlert,
  setOriginalImages,
  imageUploadReset,
  createRecipe,
  editRecipe,
  fetchRecipeToEdit,
  resetCreateRecipe,
  media: { images, originalImages },
  plus,
  togglePlusUpgradeModal,
  setCreateRecipeErrors,
  resetCreateRecipeErrors,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
}) => {
  //Limits

  const { recipeid } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startType = queryParams.get("type");
  const startPrompt = queryParams.get("prompt");
  const importUrl = location.state?.importUrl;
  const [initialAddType, setInitialAddType] = useState("add");
  const [initialAddPrompt, setInitialAddPrompt] = useState("");

  useEffect(() => {
    if (importUrl) {
      setInitialAddPrompt(importUrl);
      // Open the import section if there's a URL
      setInitialAddType("import");
    }
  }, [importUrl]);

  const handleAddSectionModalClose = () => {
    setInitialAddType("add");
    setShowAddSection(false);
  };

  const [formView, setFormView] = useState("info");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [tags, setTags] = useState([]);
  const [isPublic, setIsPublic] = useState(true);

  const [recipeSource, setRecipeSource] = useState("");
  const [recipeSourceType, setRecipeSourceType] = useState("Add a source");
  const [recipeSourceURL, setRecipeSourceURL] = useState("");
  const [recipeSourceRefs, setRecipeSourceRefs] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [editRecipeLoading, setEditRecipeLoading] = useState(true);

  const onRecipeSourceChange = (e) => {
    const value = e.target.value;

    if (value.length > 256) {
      setAlert("Recipe source must be less than 256 characters", "warning");
    } else {
      setRecipeSource(e.target.value);
    }
  };

  const onRecipeSourceURLChange = (e) => {
    const value = e.target.value;

    if (value.length > 256) {
      setAlert("Recipe source must be less than 256 characters", "warning");
    } else {
      setRecipeSourceURL(e.target.value);
    }
  };

  //Cuisine State
  const [cuisine, setCuisine] = useState([]);

  const verifyCuisine = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 32) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath("info.cuisine", [{ msg: "Cuisine must be less than 32 characters" }]);
    }

    return { hasErrors, preventSetValue };
  };

  const onCuisineChange = (items) => {
    if (items === null || items?.value?.length === 0) {
      setCuisine([]);
      clearCreateRecipeErrorByPath("info.cuisine");
      return;
    }

    const { hasErrors, preventSetValue } = verifyCuisine(items.value);

    if (!hasErrors) {
      if (create?.errors?.info?.cuisine) {
        clearCreateRecipeErrorByPath("info.cuisine");
      }
    }

    if (!preventSetValue) {
      setCuisine(items);
    }
  };

  //Course state
  const [course, setCourse] = useState([]);

  const verifyCourse = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 32) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath("info.course", [{ msg: "Course must be less than 32 characters" }]);
    }

    return { hasErrors, preventSetValue };
  };

  const onCourseChange = (items) => {
    if (items === null || items?.value?.length === 0) {
      setCourse([]);
      clearCreateRecipeErrorByPath("info.course");
      return;
    }

    const { hasErrors, preventSetValue } = verifyCourse(items.value);

    if (!hasErrors) {
      if (create?.errors?.info?.course) {
        clearCreateRecipeErrorByPath("info.course");
      }
    }

    if (!preventSetValue) {
      setCourse(items);
    }
  };

  //Allergen state
  //TO DO: Detect from ingredients in sections
  const [allergens, setAllergens] = useState([]);
  const [allergenMayContain, setAllergenMayContain] = useState([]);

  const [showAllergens, setShowAllergens] = useState(false);
  const onAllergenChange = (items) => {
    setAllergens(items);
  };

  const onAllergenMayContainChange = (items) => {
    setAllergenMayContain(items);
  };

  const handleRemoveAllergens = () => {
    //Reset allergen state
    setAllergens([]);
    setAllergenMayContain([]);
    setShowAllergens(false);
  };

  //Friendly Diet State
  //TO DO: Detect from ingredients in sections
  const [friendlyDiets, setFriendlyDiets] = useState([]);
  const [showFriendlyDiets, setShowFriendlyDiets] = useState(false);
  const onFriendlyDietChange = (items) => {
    setFriendlyDiets(items);
  };

  const handleRemoveFriendlyDiets = () => {
    setFriendlyDiets([]);
    setShowFriendlyDiets(false);
  };

  //Modal Handling
  const [showAddSection, setShowAddSection] = useState(false);

  const verifyRecipeName = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 100) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath("info.name", [{ msg: "Name must be less than 100 characters" }]);
    }

    return { hasErrors, preventSetValue };
  };

  const handleNameChange = (e) => {
    const name = e.target.value;

    const { hasErrors, preventSetValue } = verifyRecipeName(name);

    if (!hasErrors) {
      if (create?.errors?.info?.name) {
        clearCreateRecipeErrorByPath("info.name");
      }
    }

    if (!preventSetValue) {
      setName(name);
    }
  };

  const verifyDescription = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 2048) {
      hasErrors = true;
      preventSetValue = true;
      setCreateRecipeErrorByPath("info.description", [{ msg: "Description must be less than 2048 characters" }]);
    }

    return { hasErrors, preventSetValue };
  };

  const handleDescriptionChange = (e) => {
    const desc = e.target.value;

    const { hasErrors, preventSetValue } = verifyDescription(desc);

    if (!hasErrors) {
      if (create?.errors?.info?.description) {
        clearCreateRecipeErrorByPath("info.description");
      }
    }

    if (!preventSetValue) {
      setDescription(desc);
    }
  };

  const presubmitVerification = (recipe) => {
    let errors = {
      info: {},
      sections: [],
      dietary: {},
    };
    if (name.length < 3) {
      if (!errors.info.name) errors.info.name = [];
      errors.info.name.push({ msg: "Name must be at least 3 characters" });
    }

    if (name.length > 128) {
      if (!errors.info.name) errors.info.name = [];
      errors.info.name.push({ msg: "Name must be less than 128 characters" });
    }

    //Description
    if (!description) {
      recipe.description = "";
    } else {
      recipe.description = description;
    }

    recipe.description = recipe.description.trim();
    if (recipe.description.length > 2048) {
      if (!errors.info.description) errors.info.description = [];
      errors.info.description.push({ msg: "Description must be less than 2048 characters" });
    }

    //Tags
    if (tags.length > 20) {
      if (!errors.info.tags) errors.info.tags = [];
      errors.info.tags.push({ msg: "Maximum of 20 tags allowed" });
    }

    //Recipe source
    if (recipe.source) {
      if (recipe.source.url.length > 1024) {
        if (!errors.info.source) errors.info.source = [];
        errors.info.source.push({ msg: "Source link must be less than 1024 characters in length" });
      }

      if (recipe.source.text.length > 128) {
        if (!errors.info.source) errors.info.source = [];
        errors.info.source.push({ msg: "Source title must be less than 128 characters in length" });
      }
    }

    let tagLengthLimit = false;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].length > 30) {
        tagLengthLimit = true;
        break;
      }
    }

    if (tagLengthLimit) {
      if (!errors.info.tags) errors.info.tags = [];
      errors.info.tags.push({ msg: "Tags must be less than 30 characters" });
    }

    if (recipe.source) {
      if (recipe.source.length > 256) {
        if (!errors.info.source) errors.info.source = [];
        errors.info.source.push({ msg: "Recipe source must be less than 256 characters" });
      }
    }

    //Course
    //Strip values from searchables
    recipe.course && recipe.course.value ? (recipe.course = recipe.course.value) : (recipe.course = "");

    if (recipe.course && recipe.course.length > 32) {
      if (!errors.info.course) errors.info.course = [];
      errors.info.course.push({ msg: "Course must be less than 32 characters" });
    }

    //Cuisine
    //Strip values from searchables
    recipe.cuisine && recipe.cuisine.value ? (recipe.cuisine = recipe.cuisine.value) : (recipe.cuisine = "");

    if (recipe.cuisine && recipe.cuisine.length > 32) {
      if (!errors.info.cuisine) errors.info.cuisine = [];
      errors.info.cuisine.push({ msg: "Cuisine must be less than 32 characters" });
    }

    //Allergens
    //Strip values from searchables
    if (recipe.allergens) {
      recipe.allergens.mayContain = recipe.allergens.mayContain.map((item) => item.value);
      recipe.allergens.contains = recipe.allergens.contains.map((item) => item.value);

      if (recipe.allergens.length > 20) {
        if (!errors.dietary.allergens) errors.dietary.allergens = {};
        if (!errors.dietary.allergens.contains) errors.dietary.allergens.contains = [];
        errors.dietary.allergens.contains.push({ msg: "Maximum of 20 allergens allowed" });
      }

      if (recipe.allergens.mayContain.length > 20) {
        if (!errors.dietary.allergens) errors.dietary.allergens = {};
        if (!errors.dietary.allergens.mayContain) errors.dietary.allergens.mayContain = [];
        errors.dietary.allergens.mayContain.push({ msg: "Maximum of 20 potential allergens allowed" });
      }

      for (let i = 0; i < recipe.allergens.contains.length; i++) {
        if (recipe.allergens.contains[i].length > 32) {
          if (!errors.dietary.allergens) errors.dietary.allergens = {};
          if (!errors.dietary.allergens.contains) errors.dietary.allergens.contains = [];
          errors.dietary.allergens.contains.push({ msg: "Allergens must be less than 32 characters" });
        }

        if (recipe.allergens.contains[i].length < 3) {
          if (!errors.dietary.allergens) errors.dietary.allergens = {};
          if (!errors.dietary.allergens.contains) errors.dietary.allergens.contains = [];
          errors.dietary.allergens.contains.push({ msg: "Allergens must be at least 3 characters" });
        }
      }

      for (let i = 0; i < recipe.allergens.mayContain.length; i++) {
        if (recipe.allergens.mayContain[i].length > 32) {
          if (!errors.dietary.allergens) errors.dietary.allergens = {};
          if (!errors.dietary.allergens.mayContain) errors.dietary.allergens.mayContain = [];
          errors.dietary.allergens.mayContain.push({ msg: "Potential allergen must be less than 32 characters" });
        }

        if (recipe.allergens.mayContain[i].length < 3) {
          if (!errors.dietary.allergens) errors.dietary.allergens = {};
          if (!errors.dietary.allergens.mayContain) errors.dietary.allergens.mayContain = [];
          errors.dietary.allergens.mayContain.push({ msg: "Potential allergen must be at least 3 characters" });
        }
      }
    }

    //Friendly Diets
    //Strip values from searchables
    if (recipe.friendlyDiets) {
      recipe.friendlyDiets = recipe.friendlyDiets.map((item) => item.value);

      for (let i = 0; i < recipe.friendlyDiets.length; i++) {
        if (recipe.friendlyDiets[i].length > 32) {
          if (!errors.dietary.friendlyDiets) errors.dietary.friendlyDiets = [];
          errors.dietary.friendlyDiets.push({ msg: "Friendly Diets must be less than 32 characters" });
        }

        if (recipe.friendlyDiets[i].length < 3) {
          if (!errors.dietary.friendlyDiets) errors.dietary.friendlyDiets = [];
          errors.dietary.friendlyDiets.push({ msg: "Friendly Diets must be at least 3 characters" });
        }
      }
    }

    //Loop over sections, verify each section has a title and *some* content
    let hasIngredients = false;
    let hasInstructions = false;
    let hasTiming = false;
    let hasServings = false;

    if (recipe.sections.length === 0) {
      if (!errors.info.sections) errors.info.sections = [];
      errors.info.sections.push({ msg: "Recipes must have at least one section" });
    }

    if (recipe.sections.length > 10) {
      if (!errors.info.sections) errors.info.sections = [];
      errors.info.sections.push({ msg: "A recipe cannot have more than 10 sections" });
    }

    for (let i = 0; i < recipe.sections.length; i++) {
      let section = recipe.sections[i];

      let sectionErrors = {};

      if (section.title.length === 0) {
        if (!sectionErrors.title) sectionErrors.title = [];
        sectionErrors.title.push({ msg: "Sections must have a title" });
      }

      if (section.title.length > 50) {
        if (!sectionErrors.title) sectionErrors.title = [];
        sectionErrors.title.push({ msg: "Section title must be less than 50 characters" });
      }

      if (section.ingredients && section.ingredients.length > 0) {
        hasIngredients = true;
        if (section.ingredients.length > 100) {
          if (!sectionErrors.info) sectionErrors.info = [];
          sectionErrors.info.push({ msg: "A section cannot have more than 100 ingredients" });
        } else {
          for (let ing = 0; ing < section.ingredients.length; ing++) {
            let sectionIngredient = section.ingredients[ing];

            //Ingredient name
            if (sectionIngredient.name.length === 0) {
              if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
              if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
              if (!sectionErrors.ingredients[ing].name) sectionErrors.ingredients[ing].name = [];
              sectionErrors.ingredients[ing].name.push({ msg: "Ingredients must have a name" });
            }

            if (sectionIngredient.name.length < 3) {
              if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
              if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
              if (!sectionErrors.ingredients[ing].name) sectionErrors.ingredients[ing].name = [];
              sectionErrors.ingredients[ing].name.push({ msg: "Ingredient name must be at least 3 characters" });
            }

            if (sectionIngredient.name.length > 64) {
              if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
              if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
              if (!sectionErrors.ingredients[ing].name) sectionErrors.ingredients[ing].name = [];
              sectionErrors.ingredients[ing].name.push({ msg: "Ingredient name must be less than 64 characters" });
            }

            if (sectionIngredient.name === "Unknown Ingredient" || sectionIngredient.name === "") {
              if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
              if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
              if (!sectionErrors.ingredients[ing].name) sectionErrors.ingredients[ing].name = [];
              sectionErrors.ingredients[ing].name.push({ msg: "Please specify the Unknown Ingredient" });
            }

            //Ingredient quantity
            // Check if the amount is a valid fraction
            if (sectionIngredient.quantity !== "") {
              if (sectionIngredient.measured) {
                const fractionRegex = /^\d{1,3}\/\d{1,3}$/;
                if (fractionRegex.test(sectionIngredient.quantity)) {
                  const [numerator, denominator] = sectionIngredient.quantity.split("/");
                  if (!isNaN(numerator) && !isNaN(denominator)) {
                    const decimalValue = parseFloat(numerator) / parseFloat(denominator);
                    if (decimalValue <= 0 && sectionIngredient.quantity !== "") {
                      if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
                      if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
                      if (!sectionErrors.ingredients[ing].quantity) sectionErrors.ingredients[ing].quantity = [];
                      sectionErrors.ingredients[ing].quantity.push({ msg: "Ingredient amount must be greater than 0" });
                    } else {
                      sectionIngredient.quantity = decimalValue.toFixed(3);
                    }
                  }
                } else if (isNaN(sectionIngredient.quantity) || sectionIngredient.quantity <= 0) {
                  if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
                  if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
                  if (!sectionErrors.ingredients[ing].quantity) sectionErrors.ingredients[ing].quantity = [];
                  sectionErrors.ingredients[ing].quantity.push({
                    msg: "Ingredient amount must be a number or a fraction greater than 0",
                  });
                }
              }
            }

            //Ingredient unit
            if (sectionIngredient.unit) {
              if (sectionIngredient.unit.length > 32) {
                if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
                if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
                if (!sectionErrors.ingredients[ing].unit) sectionErrors.ingredients[ing].unit = [];
                sectionErrors.ingredients[ing].unit.push({ msg: "Ingredient unit must be less than 32 characters" });
              }
            }

            //Ingredient state
            if (sectionIngredient.state) {
              if (sectionIngredient.state.length > 32) {
                if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
                if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
                if (!sectionErrors.ingredients[ing].state) sectionErrors.ingredients[ing].state = [];
                sectionErrors.ingredients[ing].state.push({ msg: "Ingredient state must be less than 32 characters" });
              }
            }

            //Note
            if (sectionIngredient.note) {
              if (sectionIngredient.note.length > 128) {
                if (!sectionErrors.ingredients) sectionErrors.ingredients = [];
                if (!sectionErrors.ingredients[ing]) sectionErrors.ingredients[ing] = {};
                if (!sectionErrors.ingredients[ing].note) sectionErrors.ingredients[ing].note = [];
                sectionErrors.ingredients[ing].note.push({ msg: "Ingredient note must be less than 128 characters" });
              }
            }
          }
        }
      }

      if (section.steps && section.steps.length > 0) {
        hasInstructions = true;

        if (section.steps.length > 100) {
          if (!sectionErrors.info) sectionErrors.info = [];
          sectionErrors.info.push({ msg: "A section cannot have more than 100 steps" });
        } else {
          for (let s = 0; s < section.steps.length; s++) {
            let step = section.steps[s];

            //Step text
            if (step.text.length === 0) {
              if (!sectionErrors.steps) sectionErrors.steps = [];
              if (!sectionErrors.steps[s]) sectionErrors.steps[s] = {};
              if (!sectionErrors.steps[s].text) sectionErrors.steps[s].text = [];
              sectionErrors.steps[s].text.push({ msg: "Steps must have text" });
            }

            if (step.text.length > 1024) {
              if (!sectionErrors.steps) sectionErrors.steps = [];
              if (!sectionErrors.steps[s]) sectionErrors.steps[s] = {};
              if (!sectionErrors.steps[s].text) sectionErrors.steps[s].text = [];
              sectionErrors.steps[s].text.push({ msg: "A step's text must be less than 1024 characters" });
            }
            //Step tip
            if (step.tips && step.tips.length > 0) {
              if (step.tips.length > 1) {
                if (!sectionErrors.steps) sectionErrors.steps = [];
                if (!sectionErrors.steps[s]) sectionErrors.steps[s] = {};
                if (!sectionErrors.steps[s].tips) sectionErrors.steps[s].tips = [];
                sectionErrors.steps[s].tips.push({ msg: "A step can only have one tip" });
              } else {
                if (step.tips[0].length > 256) {
                  if (!sectionErrors.steps) sectionErrors.steps = [];
                  if (!sectionErrors.steps[s]) sectionErrors.steps[s] = {};
                  if (!sectionErrors.steps[s].tips) sectionErrors.steps[s].tips = [];
                  sectionErrors.steps[s].tips.push({ msg: "A step's tip must be less than 256 characters" });
                }
              }
            }
          }
        }
      }

      //Timing
      if (section.timing) {
        const validateTime = (type) => {
          if (section.timing[type]) {
            let hours = section.timing[type].hours;
            let minutes = section.timing[type].minutes;

            if (hours && minutes) {
              hours = parseInt(section.timing[type].hours);
              minutes = parseInt(section.timing[type].minutes);

              if ((isNaN(hours) || hours < 0) && (isNaN(minutes) || minutes < 0)) {
                if (!sectionErrors.timing) sectionErrors.timing = [];
                sectionErrors.timing.push({
                  msg: `Section ${i + 1}, ${type} time must have valid hours or minutes`,
                  timingType: type,
                });
              } else {
                if (type === "total") {
                  hasTiming = true;
                }
              }
            }
          }
        };
        validateTime("prep");
        validateTime("cook");
        validateTime("rest");
        validateTime("total");
      }

      //Output

      if (section.output) {
        //Servings + serving size
        if (section.output.servings) {
          const servingsNumber = parseInt(section.output.servings);
          if (isNaN(servingsNumber) || servingsNumber < 1 || servingsNumber > 100) {
            if (!sectionErrors.output) sectionErrors.output = {};
            sectionErrors.output.servings = [];
            sectionErrors.output.servings.push({
              msg: `Section ${i + 1}, servings must be a number between 1 and 100`,
            });
          } else {
            hasServings = true;
          }
        }

        if (section.servingSize) {
          if (section.servingSize.length > 48) {
            if (!sectionErrors.output) sectionErrors.output = {};
            sectionErrors.output.servingSize = [];
            sectionErrors.output.servingSize.push({
              msg: `Section ${i + 1}, serving size must be less than 48 characters`,
            });
          }
        }

        //Yield
        if (section.output.yield) {
          //Map searchable objects to just values for ingredient and unit
          let yieldUnit = "";
          let yieldIngredient = "";
          let hasUnitOrIngredient = false;

          //Map from searchable objects to strings
          if (section.output.yield.unit) {
            if (typeof section.output.yield.unit === "string") {
              yieldUnit = section.output.yield.unit;
            } else if (typeof section.output.yield.unit === "object") {
              if (section.output.yield.unit?.value || section.output.yield.unit?.value === "") {
                yieldUnit = section.output.yield.unit.value;
                section.output.yield.unit = yieldUnit;
              }
            }
          }

          if (section.output.yield.ingredient) {
            if (typeof section.output.yield.ingredient === "string") {
              yieldIngredient = section.output.yield.ingredient;
            } else if (typeof section.output.yield.ingredient === "object") {
              if (section.output.yield.ingredient?.value || section.output.yield.ingredient?.value === "") {
                yieldIngredient = section.output.yield.ingredient.value;
                section.output.yield.ingredient = yieldIngredient;
              }
            }
          }

          if (!(section.output.yield.amount === "" && yieldUnit === "" && yieldIngredient === "")) {
            //Must have either amount and unit or amount and ingredient
            //Amount
            if (!section.output.yield.amount || section.output.yield.amount === "") {
              if (!sectionErrors.output) sectionErrors.output = {};
              sectionErrors.output.yieldAmount = [];
              sectionErrors.output.yieldAmount.push({ msg: "Yield amount is required" });
            }

            // Check if the amount is a valid fraction
            const fractionRegex = /^\d{1,3}\/\d{1,3}$/;
            if (fractionRegex.test(section.output.yield.amount)) {
              const [numerator, denominator] = section.output.yield.amount.split("/");
              if (!isNaN(numerator) && !isNaN(denominator)) {
                const decimalValue = parseFloat(numerator) / parseFloat(denominator);
                if (decimalValue <= 0) {
                  if (!sectionErrors.output) sectionErrors.output = {};
                  sectionErrors.output.yieldAmount = [];
                  sectionErrors.output.yieldAmount.push({ msg: "Yield amount must be greater than 0" });
                } else {
                  section.output.yield.amount = decimalValue.toFixed(3);
                }
              }
            } else if (isNaN(section.output.yield.amount) || section.output.yield.amount <= 0) {
              if (!sectionErrors.output) sectionErrors.output = {};
              sectionErrors.output.yieldAmount = [];
              sectionErrors.output.yieldAmount.push({
                msg: "Yield amount must be a number or a fraction greater than 0",
              });
            }

            if (section.output.yield.unit) {
              if (yieldUnit !== "") {
                hasUnitOrIngredient = true;
                if (yieldUnit.length > 32) {
                  if (!sectionErrors.output) sectionErrors.output = {};
                  sectionErrors.output.yieldUnit = [];
                  sectionErrors.output.yieldUnit.push({ msg: "Yield unit must be less than 32 characters" });
                }
              }

              //Set formatted unit string for backend
              section.output.yield.unit = yieldUnit;
            }

            if (section.output.yield.ingredient) {
              if (yieldIngredient !== "") {
                hasUnitOrIngredient = true;
                if (yieldIngredient.length > 64) {
                  if (!sectionErrors.output) sectionErrors.output = {};
                  sectionErrors.output.yieldIngredient = [];
                  sectionErrors.output.yieldIngredient.push({
                    msg: "Yield ingredient must be less than 64 characters",
                  });
                }
              }

              //Set formatted ingredient string for backend
              section.output.yield.ingredient = yieldIngredient;
            }

            if (!hasUnitOrIngredient) {
              if (!sectionErrors.output) sectionErrors.output = {};
              sectionErrors.output.yieldUnit = [];
              sectionErrors.output.yieldUnit.push({ msg: "Yield must have a unit or ingredient" });
              sectionErrors.output.yieldIngredient = [];
              sectionErrors.output.yieldIngredient.push({ msg: "Yield must have a unit or ingredient" });
            }
          }
        }
      }

      //Nutrition
      //Macronutrients
      if (recipe.macros) {
        for (let m = 0; m < recipe.macros.length; m++) {
          let macro = recipe.macros[m];

          if (isNaN(macro.amount) || macro.amount < 0 || macro.amount > 10000) {
            if (!sectionErrors.macros) sectionErrors.macros = [];
            sectionErrors.macros.push({
              msg: `Macronutrient amount must be a number between 0 and 10,000 (Section ${i + 1}, ${macro.value})`,
              index: m,
            });
          }

          //Verify macro value < 48 characters
          if (macro.value.length > 48) {
            if (!sectionErrors.macros) sectionErrors.macros = [];
            sectionErrors.macros.push({
              msg: `Macronutrient value must be less than 48 characters (Section ${i + 1}, ${macro.value})`,
              index: m,
            });
          }
        }
      }
    }

    if (!hasIngredients || !hasInstructions) {
      if (!errors.info) errors.info = {};
      errors.info.sections = [];
      errors.info.sections.push({ msg: "Recipes must have at least one ingredient and step" });
    }

    return { errors: errors };
  };

  //Check for errors
  const checkHasErrors = (errors) => {
    // Check info errors
    if (errors.info) {
      for (const field in errors.info) {
        if (Array.isArray(errors.info[field]) && errors.info[field].length > 0) {
          return true;
        }
      }
    }

    // Check sections errors
    if (errors.sections && errors.sections.length > 0) {
      for (const section of errors.sections) {
        if (section) {
          // Check if section exists (not null)
          for (const field in section) {
            if (Array.isArray(section[field]) && section[field].length > 0) {
              return true;
            }
            // Check nested objects like output
            if (typeof section[field] === "object") {
              for (const subField in section[field]) {
                if (Array.isArray(section[field][subField]) && section[field][subField].length > 0) {
                  return true;
                }
              }
            }
          }
        }
      }
    }

    // Check dietary errors
    if (errors.dietary && errors.dietary.length > 0) {
      return true;
    }

    return false;
  };

  const handleFormSubmit = async (event) => {
    //TO DO: Add edit handling
    event.preventDefault();

    setSubmitLoading(true);

    const sectionsObj = JSON.parse(JSON.stringify(create.sections));

    //Create recipe object
    let recipe = {
      name,
      description,
      tags,
      isPublic,
      sections: sectionsObj,
      cuisine,
      course,
      allergens: {
        contains: allergens,
        mayContain: allergenMayContain,
      },
      friendlyDiets,
      source:
        recipeSourceType !== "Add a source"
          ? {
              type: recipeSourceType,
              url: recipeSourceURL,
              text: recipeSource.trim(),
              ref: recipeSourceRefs,
            }
          : null,
    };

    //===Data Verification===
    //Name

    const presubmitCheck = presubmitVerification(recipe);

    const hasErrors = checkHasErrors(presubmitCheck.errors);

    if (hasErrors) {
      setSubmitLoading(false);
      setCreateRecipeErrors(presubmitCheck.errors);
      return;
    }

    if (recipeid) {
      try {
        const create = await editRecipe(recipe, recipeid, originalImages);

        if (create.errors) {
          setSubmitLoading(false);
          setCreateRecipeErrors(create.errors);
          return;
        }

        if (create.url) {
          setAlert(`${create.name ? create.name : "Recipe"} updated`, "success");
          resetCreateRecipeErrors();
          navigate(`/${user.username}/${create.url}`);
        }
      } catch (error) {
        console.log(error);
        setAlert("Error editing recipe", "error");
      }
      setSubmitLoading(false);
    } else {
      try {
        const create = await createRecipe(recipe);

        if (create.errors) {
          setSubmitLoading(false);
          setCreateRecipeErrors(create.errors);
          return;
        }

        if (create && create.url) {
          setAlert(`${create.name ? create.name : "Recipe"} created`, "success");
          resetCreateRecipeErrors();
          navigate(`/${user.username}/${create.url}`);
        }
      } catch (error) {
        console.log(error);
        setAlert("Error creating recipe", "error");
      }
      setSubmitLoading(false);
    }
  };

  const prefillRecipeFields = (recipe) => {
    if (recipe.name && recipe.name !== "") {
      if (name === "") {
        setName(recipe.name);
      }
    }

    if (recipe.description && recipe.description !== "") {
      if (description === "") {
        setDescription(recipe.description);
      }
    }

    if (recipe.cuisine) {
      if (cuisine && cuisine.length === 0) {
        setCuisine(recipe.cuisine);
      }
    }

    if (recipe.course) {
      if (course && course.length === 0) {
        setCourse(recipe.course);
      }
    }

    if (recipe.tags) {
      const newTags = [...tags];
      recipe.tags.forEach((tag) => {
        if (!tags.some((existingTag) => existingTag.toLowerCase() === tag.toLowerCase())) {
          if (newTags.length < 20) {
            newTags.push(tag);
          }
        }
      });
      setTags(newTags);
    }

    if (recipe.source) {
      setRecipeSourceType(recipe.source.type);
      setRecipeSourceURL(recipe.source.url ? recipe.source.url : "");
      setRecipeSource(recipe.source.text ? recipe.source.text : "");
      setRecipeSourceRefs(recipe.source.ref ? recipe.source.ref : null);
    }

    if (recipe.nutrition) {
      //If macros not all null, show macros
      //If allergens not empty, show
      if (recipe.nutrition?.allergens?.contains.length > 0) {
        setAllergens(recipe.nutrition.allergens.contains);
      }

      if (recipe.nutrition?.allergens?.mayContain.length > 0) {
        setAllergenMayContain(recipe.nutrition.allergens.mayContain);
      }
      //If diets not empty, show

      if (recipe.nutrition) {
        if (recipe.nutrition.friendlyDiets) {
          if (recipe.nutrition.friendlyDiets.length > 0) {
            setFriendlyDiets(recipe.nutrition.friendlyDiets);
          }
        }
      }
    }
  };

  const getEditRecipe = async (id) => {
    setEditRecipeLoading(true);
    const editRecipe = await fetchRecipeToEdit(id);

    const recipe = editRecipe.recipe;

    if (recipe) {
      //Prefill non-state values
      //TODO: Expand sections for data if necessary
      prefillRecipeFields(recipe);
      setOriginalImages(recipe.data);
    }
    setEditRecipeLoading(false);
  };

  useEffect(() => {
    //Check if is editing a recipe
    if (recipeid) {
      getEditRecipe(recipeid);
      //TO DO:
      //Check if can edit
    } else {
      setEditRecipeLoading(false);
    }

    if (startType) {
      if (startType === "generate") {
        if (plus.isPlus) {
          setFormView("sections");
          setInitialAddType("generate");
          if (startPrompt) {
            setInitialAddPrompt(startPrompt);
          }
          setShowAddSection(true);
        } else {
          togglePlusUpgradeModal(true);
        }
      }

      if (startType === "import") {
        setFormView("sections");
        setInitialAddType("import");
        setShowAddSection(true);
      }
    }
  }, [startType]);

  //Cleanup
  useEffect(() => {
    return () => {
      imageUploadReset();
      resetCreateRecipe();
    };
  }, []);

  //Reset errors on page first load
  useEffect(() => {
    resetCreateRecipeErrors();
  }, []);

  const infoView = (
    <div className="flex flex-col gap-4">
      <div className="px-4 md:px-0">
        <div className="flex flex-col h-full md:flex-row">
          <div className="w-full">
            <TextField
              label="Recipe Name"
              variant="outlined"
              value={name}
              onChange={handleNameChange}
              fullWidth
              className="mb-4"
              error={!!create?.errors?.info?.name}
              helperText={create?.errors?.info?.name?.map((error) => error.msg).join(", ")}
              inputProps={{ maxLength: 64 }}
            />
            <TextField
              label="Description"
              multiline
              minRows={7}
              maxRows={10}
              variant="outlined"
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              error={!!create?.errors?.info?.description}
              helperText={create?.errors?.info?.description?.map((error) => error.msg).join(", ")}
              inputProps={{ maxLength: 1024 }}
            />
          </div>
          <div className="ml-0 mt-4 md:ml-2 md:mt-0">
            <ImageDropzone borderColor="secondaryText-200" />
          </div>
        </div>

        <ImageReorderPreview className={images.length > 0 ? "my-4" : "my-2"} />
        <div className="flex flex-row justify-between w-full [&>*:first-child]:mr-1 [&>*:nth-child(2)]:ml-1">
          <div className="w-1/2 space-y-2">
            <Searchable
              label="Cuisine"
              helperText={
                !!create?.errors?.info?.cuisine
                  ? create?.errors?.info?.cuisine?.map((error) => error.msg).join(", ")
                  : ""
              }
              labelWidth={70}
              multiple={false}
              maxInputLength={32}
              freeSolo={true}
              apiUrl="/cuisines/searchable"
              allowAdd={true}
              onItemChange={onCuisineChange}
              selectedItemsFromParent={cuisine}
              error={!!create?.errors?.info?.cuisine}
            />
          </div>

          <div className="w-1/2 space-y-2">
            <Searchable
              label="Course"
              helperText={
                !!create?.errors?.info?.course ? create?.errors?.info?.course?.map((error) => error.msg).join(", ") : ""
              }
              labelWidth={70}
              maxInputLength={32}
              multiple={false}
              freeSolo={true}
              apiUrl="/recipe-courses/searchable"
              allowAdd={true}
              onItemChange={onCourseChange}
              selectedItemsFromParent={course}
              error={!!create?.errors?.info?.course}
            />
          </div>
        </div>

        <div className="my-4">
          <RecipeSectionTags tags={tags} setTags={setTags} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="mb-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ShadcnButton
                  variant="outline"
                  className="w-full justify-between font-normal text-secondaryText-600/90 rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                >
                  {recipeSourceType}
                  <ChevronDown className="w-3 opacity-50" />
                </ShadcnButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px]">
                {recipeSourceType !== "Add a source" && (
                  <DropdownMenuItem onSelect={() => setRecipeSourceType("Add a source")}>
                    Remove Source
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Inspired by")}>Inspired by</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Adapted from")}>Adapted from</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Based on")}>Based on</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("A take on")}>A take on</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Found in")}>Found in</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Handed down from")}>
                  Handed down from
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Influenced by")}>Influenced by</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Generated with")}>
                  Generated with
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => setRecipeSourceType("Imported from")}>Imported from</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {recipeSourceType !== "Add a source" && (
            <>
              <div className="">
                <TextField
                  label="Source"
                  helperText="Name of the source of the recipe"
                  variant="outlined"
                  fullWidth
                  value={recipeSource}
                  onChange={onRecipeSourceChange}
                  size="small"
                  inputProps={{ maxLength: 128 }}
                />
              </div>
              <div className="">
                <TextField
                  label="Link"
                  helperText="Add a link to the recipe source"
                  variant="outlined"
                  fullWidth
                  value={recipeSourceURL}
                  onChange={onRecipeSourceURLChange}
                  size="small"
                  inputProps={{ maxLength: 256 }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex w-full items-center justify-between px-4 md:px-0">
        {edit.recipe ? (
          <Button
            onClick={(e) => handleFormSubmit(e)}
            variant="contained"
            disableElevation
            fullWidth
            disabled={submitLoading}
            className="bg-stone-800 hover:bg-stone-700 font-medium"
          >
            {submitLoading ? "Saving Recipe" : "Save Recipe"}
          </Button>
        ) : (
          <Button
            onClick={(e) => handleFormSubmit(e)}
            variant="contained"
            disableElevation
            fullWidth
            disabled={submitLoading}
            className="bg-stone-800 hover:bg-stone-700 font-medium"
          >
            <PlusIcon className="w-3 h-3 mr-2 fill-background" />
            {submitLoading ? "Creating Recipe" : "Create Recipe"}
          </Button>
        )}
      </div>
    </div>
  );

  const sectionsView = (
    <div className="flex flex-col gap-4">
      <div className="w-full md:mx-0">
        <RecipeSectionManager
          setShowAddSection={setShowAddSection}
          setInitialAddType={setInitialAddType}
          handleFormSubmit={handleFormSubmit}
          submitLoading={submitLoading}
        />
      </div>
    </div>
  );

  const dietaryView = (
    <div className="w-full md:m-4 md:mx-0">
      <div className="w-full flex flex-col gap-8">
        <div className="px-4 md:px-0 w-full">
          <div>
            <Typography variant="subtitle1" className="font-normal leading-none">
              Allergens:
            </Typography>
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <Searchable
              label="Contains"
              maxInputLength={32}
              multiple={true}
              freeSolo={true}
              apiUrl="/allergens/searchable"
              allowAdd={true}
              onItemChange={onAllergenChange}
              helperText={
                !!create?.errors?.dietary?.allergens?.contains
                  ? create?.errors?.dietary?.allergens?.contains?.map((error) => error.msg).join(", ")
                  : "Add any allergens this recipe contains, such as peanuts or soy"
              }
              selectedItemsFromParent={allergens}
              error={
                !!create?.errors?.dietary?.allergens?.contains &&
                create?.errors?.dietary?.allergens?.contains.length > 0
              }
            />
            <Searchable
              label="May Contain"
              maxInputLength={32}
              multiple={true}
              freeSolo={true}
              apiUrl="/allergens/searchable"
              allowAdd={true}
              onItemChange={onAllergenMayContainChange}
              helperText={
                !!create?.errors?.dietary?.allergens?.mayContain
                  ? create?.errors?.dietary?.allergens?.mayContain?.map((error) => error.msg).join(", ")
                  : "Add any allergens this recipe may contain"
              }
              selectedItemsFromParent={allergenMayContain}
              error={
                !!create?.errors?.dietary?.allergens?.mayContain &&
                create?.errors?.dietary?.allergens?.mayContain.length > 0
              }
            />
          </div>
        </div>
        <div className="px-4 md:px-0 w-full">
          <div>
            <Typography variant="subtitle1" className="font-normal leading-none">
              Friendly Diets:
            </Typography>
          </div>
          <div className="mt-4">
            <Searchable
              label="Friendly Diets"
              maxInputLength={32}
              multiple={true}
              freeSolo={true}
              apiUrl="/diets/searchable"
              allowAdd={true}
              onItemChange={onFriendlyDietChange}
              helperText={
                !!create?.errors?.dietary?.friendlyDiets
                  ? create?.errors?.dietary?.friendlyDiets?.map((error) => error.msg).join(", ")
                  : "Add any friendly diets this recipe adheres to, such as vegan or gluten-free"
              }
              selectedItemsFromParent={friendlyDiets}
              error={!!create?.errors?.dietary?.friendlyDiets && create?.errors?.dietary?.friendlyDiets.length > 0}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>{edit.recipe ? `Edit ${edit.recipe.name}` : "Create Recipe"}</title>
      </Helmet>
      <CustomModal
        open={showAddSection}
        handleClose={() => setShowAddSection(false)}
        contentClassNameOverride="md:w-[70vw] md:max-w-[700px] lg:max-w-[600px]"
        type="bottomToCentered"
      >
        <AddRecipeSectionModal
          handleModalClose={() => handleAddSectionModalClose()}
          setRecipeFields={prefillRecipeFields}
          initialAddType={initialAddType}
          initialAddPrompt={initialAddPrompt}
        />
      </CustomModal>
      <CustomModal
        open={plus.modals.upgrade}
        handleClose={() => togglePlusUpgradeModal(false)}
        border={false}
        type="bottomToCentered"
        parentClassNameOverride="xl:max-w-[600px]"
        contentClassNameOverride="max-h-[80vh]"
      >
        <PlusModal handleClose={() => togglePlusUpgradeModal(false)} />
      </CustomModal>

      <RecipeMacrosModal />
      <RecipeOutputModal />
      <RecipeTimingModal />
      <div className="flex flex-col items-center  md:pb-[5rem] py-[4rem] md:px-4 md:py-4 md:ml-[5rem] md:my-0 min-h-screen bg-neutral-50">
        <div className="max-w-[800px] w-full">
          {editRecipeLoading ? (
            <Spinner />
          ) : (
            <div className="flex flex-col items-center">
              <div className="w-full m-4 space-y-4 md:m-4 md:mx-0">
                <div className="w-full px-4 md:px-0">
                  <Tabs value={formView} onValueChange={(value) => setFormView(value)} className="w-full">
                    <TabsList className="w-full border border-separator p-0">
                      <TabsTrigger value="info" className="w-full h-full">
                        Info
                      </TabsTrigger>
                      <TabsTrigger value="sections" className="w-full h-full">
                        Sections
                      </TabsTrigger>
                      <TabsTrigger value="dietary" className="w-full h-full">
                        Dietary
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
                {formView === "info" && infoView}
                {formView === "sections" && sectionsView}
                {formView === "dietary" && dietaryView}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CreateRecipe.propTypes = {
  processIngredients: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  imageUploadReset: PropTypes.func.isRequired,
  createRecipe: PropTypes.func.isRequired,
  editRecipe: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fetchRecipeToEdit: PropTypes.func.isRequired,
  media: PropTypes.object.isRequired,
  resetCreateRecipe: PropTypes.func.isRequired,
  plus: PropTypes.object.isRequired,
  togglePlusUpgradeModal: PropTypes.func.isRequired,
  setOriginalImages: PropTypes.func.isRequired,
  setCreateRecipeErrorByPath: PropTypes.func.isRequired,
  clearCreateRecipeErrorByPath: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
  user: state.auth.user,
  media: state.media,
  plus: state.plus,
});
export default connect(mapStateToProps, {
  processIngredients,
  setAlert,
  imageUploadReset,
  createRecipe,
  editRecipe,
  fetchRecipeToEdit,
  resetCreateRecipe,
  togglePlusUpgradeModal,
  setOriginalImages,
  setCreateRecipeErrors,
  resetCreateRecipeErrors,
  setCreateRecipeErrorByPath,
  clearCreateRecipeErrorByPath,
})(CreateRecipe);
