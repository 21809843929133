import React from "react";
import Typography from "@mui/material/Typography";
import { ReactComponent as ArrowDown } from "../../../assets/icons/svg/arrow-down.svg";

const PullDownRefresh = () => {
  return (
    <div className="w-full flex flex-row items-center justify-center py-4 relative z-0">
      <Typography variant="body1" className="flex flex-row gap-2 items-center">
        <ArrowDown className="h-6 w-6 fill-mainGreen" /> Pull Down to Refresh
      </Typography>
    </div>
  );
};

export default PullDownRefresh;
