import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, TextField, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

//Components
import Searchable from "../../../../layout/searchable/Searchable";

//Actions
import {
  updateSectionIngredient,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
} from "../../../../../actions/walkthrough";

//Utility Functions
import { convertToTitleCase, decimalToFraction } from "../../../../../utils/recipe";

const EditIngredientInfo = ({
  section,
  sectionIndex,
  ingredient,
  index,
  updateSectionIngredient,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
  errors,
}) => {
  const [amount, setAmount] = useState(decimalToFraction(ingredient?.unit?.display?.magnitude));
  const [unit, setUnit] = useState(
    ingredient?.unit?.display?.text
      ? { ...ingredient.unit.display, name: ingredient.unit.display.text, value: ingredient.unit.display.text }
      : []
  );
  const [name, setName] = useState(
    ingredient?.name ? { id: ingredient?.id ? ingredient.id : null, name: ingredient.name, value: ingredient.name } : []
  );
  const [state, setState] = useState(ingredient.state);
  const [note, setNote] = useState(ingredient.note);
  const [showOptionalFields, setShowOptionalFields] = useState(false);

  console.log(ingredient);

  const verifyQuantity = (value) => {
    let hasErrors = false;
    let preventSetValue = false;
    // Updated regex to handle both simple fractions and compound fractions
    const fractionRegex = /^(\d{1,3}\/\d{1,3})$|^(\d{1,3}\/)$|^(\d{1,3}\s+\d{1,3}\/\d{1,3})$|^(\d{1,3})$/;

    // Handle empty value check first
    if (value === "" && ingredient?.measured) {
      if (ingredient?.unit?.value && ingredient?.unit?.value !== "") {
        hasErrors = true;
        setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
          { msg: "A measured ingredient with a unit must have an amount" },
        ]);
      }
      return { hasErrors, preventSetValue };
    }

    // Handle fraction validation
    if (typeof value === "string") {
      if (fractionRegex.test(value)) {
        if (value.includes(" ")) {
          // Handle compound fraction (e.g., "1 1/2")
          const [whole, fraction] = value.split(" ");
          const [numerator, denominator] = fraction.split("/");
          const totalValue = parseInt(whole) + parseInt(numerator) / parseInt(denominator);

          if (totalValue > 10000) {
            setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
              { msg: "Amount must be less than or equal to 10,000" },
            ]);
            preventSetValue = true;
            hasErrors = true;
          } else if (totalValue < 0.001) {
            setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
              { msg: "Amount must be greater than or equal to 0.001" },
            ]);
            hasErrors = true;
          }
        } else if (value.includes("/")) {
          // Handle simple fraction (e.g., "1/2")
          const [numerator, denominator] = value.split("/");
          if (denominator) {
            const fractionValue = parseInt(numerator) / parseInt(denominator);
            if (fractionValue > 10000) {
              setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
                { msg: "Amount must be less than or equal to 10,000" },
              ]);
              preventSetValue = true;
              hasErrors = true;
            } else if (fractionValue < 0.001) {
              setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
                { msg: "Amount must be greater than or equal to 0.001" },
              ]);
              hasErrors = true;
            }
          }
        }

        if (!hasErrors) {
          clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`);
        }
        return { hasErrors, preventSetValue };
      }
    }

    // Handle numeric validation
    if (isNaN(value)) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
        { msg: "Amount must be a number or basic fraction" },
      ]);
      hasErrors = true;
    } else if (value < 0.001 && value !== "") {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
        { msg: "Amount must be greater than or equal to 0.001" },
      ]);
      hasErrors = true;
    } else if (value > 10000) {
      setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
        { msg: "Amount must be less than or equal to 10,000" },
      ]);
      preventSetValue = true;
      hasErrors = true;
    }

    if (!hasErrors) {
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`);
    }
    return { hasErrors, preventSetValue };
  };
  const verifyUnit = (value) => {
    //Check value requirements (string lengths, etc)
    let hasErrors = false;
    let preventSetValue = false;

    if (value) {
      if (value.length > 32) {
        hasErrors = true;
        preventSetValue = true;
        setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.unit`, [
          { msg: "Unit must be less than 32 characters" },
        ]);
      }

      if (
        (typeof ingredient?.quantity === "string" && ingredient?.quantity?.trim() === "") ||
        ingredient?.quantity === null
      ) {
        if (value !== "" && ingredient.measured) {
          hasErrors = true;
          setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`, [
            { msg: "An ingredient with a unit must have an amount" },
          ]);
        }
      }
    }

    //Check required field parings, eg unit needs non-zero, non-empty quantity
    return {
      hasErrors,
      preventSetValue,
    };
  };

  const verifyName = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value) {
      if (value.length === 0) {
        hasErrors = true;
        setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.name`, [
          { msg: "Ingredient name cannot be empty" },
        ]);
      }

      if (value.length > 64) {
        hasErrors = true;
        preventSetValue = true;
      }

      if (!hasErrors) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.name`);
      }
    } else {
      hasErrors = true;
      setWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.name`, [
        { msg: "Ingredient name cannot be empty" },
      ]);
    }
    return {
      hasErrors,
      preventSetValue,
    };
  };

  const verifyState = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 32) {
      hasErrors = true;
      preventSetValue = true;
    }

    return { hasErrors, preventSetValue };
  };

  const verifyNote = (value) => {
    let hasErrors = false;
    let preventSetValue = false;

    if (value.length > 128) {
      hasErrors = true;
      preventSetValue = true;
    }

    return { hasErrors, preventSetValue };
  };

  //Input handlers
  const handleAmountChange = (e) => {
    const value = e.target.value;
    const { hasErrors, preventSetValue } = verifyQuantity(value);

    console.log(value, hasErrors, preventSetValue);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[index]?.quantity) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.quantity`);
      }
    }

    if (!preventSetValue) {
      setAmount(value);
    }
  };

  const handleUnitChange = (searchableData) => {
    if (searchableData === null || searchableData?.value?.length === 0) {
      setUnit([]);
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.unit`);
      if (
        errors?.sections?.[sectionIndex]?.ingredients?.[index]?.amount?.[0]?.msg ===
        "A measured ingredient with a unit must have an amount"
      ) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.amount`);
      }
      return;
    }

    const { hasErrors, preventSetValue } = verifyUnit(searchableData.value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[index]?.unit) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.unit`);
      }
    }

    if (!preventSetValue) {
      setUnit(searchableData);
    }
  };

  const handleNameChange = (searchableData) => {
    if (searchableData === null || searchableData?.value?.length === 0) {
      setName([]);
      clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.name`);
      return;
    }

    const { hasErrors, preventSetValue } = verifyName(searchableData.value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[index]?.name) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.name`);
      }
    }

    if (!preventSetValue) {
      setName(searchableData);
    }
  };

  const handleStateChange = (e) => {
    const value = e.target.value;

    const { hasErrors, preventSetValue } = verifyState(value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[index]?.state) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.state`);
      }
    }

    if (!preventSetValue) {
      setState(value);
    }
  };

  const handleNoteChange = (e) => {
    const value = e.target.value;

    const { hasErrors, preventSetValue } = verifyNote(value);

    if (!hasErrors) {
      if (errors?.sections?.[sectionIndex]?.ingredients?.[index]?.note) {
        clearWalkthroughErrorByPath(`sections.${sectionIndex}.ingredients.${index}.note`);
      }
    }

    if (!preventSetValue) {
      setNote(value);
    }
  };

  const generateHelperTextForUnit = () => {
    if (unit.value === "not_exact") {
      return "This ingredient is not measured in exact quantities";
    } else if (unit.value === "to_taste") {
      return "This ingredient is measured to taste";
    } else if (unit.value === "self") {
      return "This ingredient is measured by itself. 1 egg, 2 apples";
    } else if (unit.value === "count") {
      return "This ingredient is measured by count. 2 eggs, 3 apples";
    } else {
      return "";
    }
  };

  //Check to display optional fields on modal open
  useEffect(() => {
    if (ingredient.state !== "" || ingredient.note !== "") {
      setShowOptionalFields(true);
    }
  }, []);

  const handleSave = () => {
    console.log(ingredient, unit, name);
    const newIngredient = JSON.parse(
      JSON.stringify({
        ...ingredient,
        unit: {
          ...ingredient.unit,
          display: {
            ...ingredient.unit.display,
            text: unit && unit.value ? unit.value : "",
            id: unit && unit.id ? unit.id : null,
            type: unit && unit.type ? unit.type : null,
            magnitude: unit && unit.magnitude ? unit.magnitude : 0,
          },
          anchor: {
            ...ingredient.unit.anchor,
          },
        },
        id: ingredient.name === name ? ingredient.id : null,
        name: name && name.value ? name.value : "",
        state,
        note,
      })
    );

    updateSectionIngredient(sectionIndex, index, newIngredient);
  };

  return (
    <div className="w-full p-4 pt-0">
      <div className="mb-4">
        <Typography variant="h6" className="font-normal">
          Edit Ingredient
        </Typography>
      </div>
      <div className="flex flex-row items-start gap-2 mb-4">
        <div className="w-1/2">
          <TextField
            label="Amount"
            value={amount}
            onChange={handleAmountChange}
            variant="outlined"
            fullWidth
            error={!!errors?.amount}
            helperText={errors?.amount && errors?.amount?.[0]?.msg ? errors.amount[0].msg : ""}
            maxInputLength={7}
          />
        </div>

        <div className="w-1/2">
          <Searchable
            label="Unit"
            fullWidth
            error={!!errors?.unit}
            helperText={errors?.unit && errors?.unit?.[0]?.msg ? errors.unit[0].msg : generateHelperTextForUnit()}
            freeSolo={true}
            apiUrl="/units/searchable"
            allowAdd={true}
            multiple={false}
            onItemChange={handleUnitChange}
            maxInputLength={32}
            selectedItemsFromParent={unit}
          />
        </div>
      </div>

      <div className="mb-4">
        <Searchable
          fullWidth
          error={!!errors?.name}
          helperText={errors?.name && errors?.name?.[0]?.msg ? errors.name[0].msg : ""}
          apiUrl="/ingredients/searchable"
          allowAdd={true}
          label="Ingredient Name"
          freeSolo={true}
          maxInputLength={64}
          multiple={false}
          onItemChange={handleNameChange}
          selectedItemsFromParent={name}
          convertToTitleCaseOnLoad={true}
        />
      </div>

      <TextField
        label="State"
        value={state}
        onChange={handleStateChange}
        variant="outlined"
        fullWidth
        className="mb-4"
        error={!!errors?.state}
        helperText={errors?.state && errors?.state[0]?.msg ? errors.state[0].msg : ""}
      />
      <TextField
        label="Note"
        value={note}
        onChange={handleNoteChange}
        variant="outlined"
        fullWidth
        className="mb-4"
        error={!!errors?.note}
        helperText={errors?.note && errors?.note[0]?.msg ? errors.note[0].msg : ""}
      />
      <Button variant="contained" color="primary" onClick={handleSave} fullWidth disableElevation>
        Save
      </Button>
    </div>
  );
};

EditIngredientInfo.propTypes = {
  ingredient: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateSectionIngredient: PropTypes.func.isRequired,
  setWalkthroughErrorByPath: PropTypes.func.isRequired,
  clearWalkthroughErrorByPath: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
});

export default connect(mapStateToProps, {
  updateSectionIngredient,
  setWalkthroughErrorByPath,
  clearWalkthroughErrorByPath,
})(EditIngredientInfo);
