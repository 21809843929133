import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { moveIngredient } from "../../../../actions/createrecipe";
import { DragDropContext } from "@hello-pangea/dnd";

import { Typography, Button } from "@mui/material";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../ui/dropdown-menu";
import { Button as ShadcnButton } from "../../../ui/button";
import { ChevronDown } from "lucide-react";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/svg/import.svg";
import { ReactComponent as GenerateIcon } from "../../../../assets/icons/svg/magic-wand-solid.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/svg/plus-solid.svg";
//Components
import RecipeSection from "./RecipeSection";
import { preventWidows } from "../../../../utils/text";

const RecipeSectionManager = ({
  recipe: { create, edit },
  setShowAddSection,
  setInitialAddType,
  moveIngredient,
  handleFormSubmit,
  submitLoading,
  isEditRecipe,
}) => {
  const [selectedSection, setSelectedSection] = useState("all");
  const handleAddSectionClick = () => {
    setInitialAddType("add");
    setShowAddSection(true);
    setSelectedSection("all");
  };

  const handleImportClick = () => {
    setInitialAddType("import");
    setShowAddSection(true);
    setSelectedSection("all");
  };

  const handleGenerateClick = () => {
    setInitialAddType("generate");
    setShowAddSection(true);
    setSelectedSection("all");
  };

  const onDragEnd = (result) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }

    const sourceSectionIndex = parseInt(source.droppableId.split("-")[1]);
    const destSectionIndex = parseInt(destination.droppableId.split("-")[1]);

    moveIngredient(sourceSectionIndex, destSectionIndex, source.index, destination.index);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="mb-4">
        <div className="px-4 md:px-0">
          <div className="flex flex-col gap-4">
            {create && create.sections && create.sections.length === 0 && (
              <div className="p-4 py-6 border border-separator rounded-lg bg-background">
                <Typography variant="h6" className="font-normal leading-none mb-2">
                  Recipe Sections
                </Typography>
                <Typography variant="subtitle2" className="text-secondaryText font-normal leading-tight text-xs">
                  Sections are used to group parts of a recipe together. For example, the icing, batter, and assembly
                  for a cake can be their own sections within the same recipe.
                </Typography>

                <div className="mt-8">
                  <div className="flex flex-col gap-2">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleAddSectionClick}
                      disableElevation
                      fullWidth
                      className="text-primaryText"
                    >
                      <PlusIcon className="w-3 h-3 mr-2 fill-primaryText" />
                      Add
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleImportClick}
                      disableElevation
                      fullWidth
                      className="text-primaryText"
                    >
                      <ImportIcon className="w-4 h-4 mr-2 fill-primaryText" />
                      Import
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleGenerateClick}
                      disableElevation
                      fullWidth
                      className="text-primaryText"
                    >
                      <GenerateIcon className="w-4 h-4 mr-2 fill-primaryText" />
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {create && create.sections && create.sections.length > 0 && (
              <div className="flex flex-row gap-4">
                <div className="flex-grow w-full">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <ShadcnButton
                        variant="outline"
                        className="w-full justify-between font-normal rounded-lg border-secondaryText-200 hover:border-primaryText font-sans text-md"
                      >
                        {create && create.sections && create.sections.length > 0
                          ? selectedSection === "all"
                            ? "All Sections"
                            : create.sections[selectedSection].title
                          : "Add a section"}
                        <ChevronDown className="w-3 opacity-50 ml-2" />
                      </ShadcnButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] rounded-[8px] z-[1301]">
                      {create && create.sections && create.sections.length > 0 ? (
                        <>
                          <DropdownMenuItem onSelect={() => setSelectedSection("all")}>All Sections</DropdownMenuItem>
                          {create.sections.map((section, index) => (
                            <DropdownMenuItem key={index} onSelect={() => setSelectedSection(index)}>
                              {section.title}
                            </DropdownMenuItem>
                          ))}
                          <DropdownMenuItem onSelect={handleAddSectionClick}>+ Add New Section</DropdownMenuItem>
                        </>
                      ) : (
                        <DropdownMenuItem onSelect={handleAddSectionClick}>Add a section</DropdownMenuItem>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSectionClick}
                  disableElevation
                  size="small"
                >
                  + Add
                </Button>
              </div>
            )}
          </div>
        </div>

        {create && create.sections && create.sections.length > 0 && (
          <div>
            <div className="flex flex-col gap-4 mt-4">
              {selectedSection === "all" ? (
                create.sections.map((section, sectionIndex) => (
                  <RecipeSection
                    section={section}
                    sectionIndex={sectionIndex}
                    errors={create?.errors?.sections?.[sectionIndex]}
                    key={sectionIndex}
                  />
                ))
              ) : (
                <RecipeSection
                  section={create.sections[selectedSection]}
                  sectionIndex={selectedSection}
                  errors={create?.errors?.sections?.[selectedSection]}
                />
              )}
            </div>
            <div className="p-4 md:px-0 flex flex-col gap-2">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSectionClick}
                disableElevation
                fullWidth
                className="font-medium"
              >
                <PlusIcon className="w-3 h-3 mr-2 fill-background" />
                Add Section
              </Button>
              {submitLoading ? (
                <Button
                  onClick={(e) => handleFormSubmit(e)}
                  variant="contained"
                  color="primary"
                  disableElevation
                  fullWidth
                  className="rounded-none md:rounded-[0.375rem] font-medium"
                  disabled={submitLoading}
                >
                  {edit && edit?.recipe ? "Saving Recipe" : "Creating Recipe"}
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleFormSubmit(e)}
                  variant="contained"
                  disableElevation
                  fullWidth
                  disabled={submitLoading}
                  className="bg-stone-800 hover:bg-stone-700 font-medium"
                >
                  <PlusIcon className="w-3 h-3 mr-2 fill-background" />
                  {edit && edit?.recipe ? "Save Recipe" : "Create Recipe"}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

RecipeSectionManager.propTypes = {
  recipe: PropTypes.object.isRequired,
  moveIngredient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, { moveIngredient })(RecipeSectionManager);
