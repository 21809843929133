//Import redux types
import { GET_NEW_UPDATES, NEW_UPDATES_ERROR, CLOSE_UPDATE_MODAL } from "../actions/types";

//Initial state object
const initialState = {
  newUpdates: null,
  loading: true,
  error: false,
  showModal: false,
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case GET_NEW_UPDATES:
      return {
        ...state,
        newUpdates: payload.updates,
        loading: false,
        error: true,
        showModal: payload.updates.length > 0 ? true : false,
      };
    case NEW_UPDATES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        error: true,
        showModal: false,
      };
    case CLOSE_UPDATE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
}
