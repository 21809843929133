import api from "../api/api";

import store, { history } from "../store";

//Redux types
import {
  WALKTHROUGH_GET_RECIPE,
  WALKTHROUGH_RECIPE_ERROR,
  WALKTHROUGH_SET_SECTION,
  WALKTHROUGH_SET_INGREDIENT,
  RESET_WALKTHROUGH,
  WALKTHROUGH_SCALE_RECIPE,
  WALKTHROUGH_SCALE_SECTION,
  WALKTHROUGH_SET_SERVINGS_EATEN,
  WALKTHROUGH_SET_SECTION_SERVINGS,
  SET_WALKTHROUGH_ERRORS,
} from "./types";

//Initial get recipe
export const getWalkthroughRecipe = (recipeID) => async (dispatch) => {
  try {
    //Get recipe
    const res = await api.get(`/recipes/walkthrough/${recipeID}`);

    const { recipe, user } = res.data;

    let payload = {
      recipe: recipe,
      user: user,
    };

    dispatch({
      type: WALKTHROUGH_GET_RECIPE,
      payload: payload,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: WALKTHROUGH_RECIPE_ERROR,
    });
  }
};

//Go to section
export const setWalkthroughSection = (sectionIndex) => (dispatch) => {
  dispatch({
    type: WALKTHROUGH_SET_SECTION,
    payload: sectionIndex,
  });
};

//Recipe complete

//Edit ingredient amount
export const updateSectionIngredient = (sectionIndex, ingredientIndex, ingredient) => (dispatch) => {
  const state = store.getState();
  const updatedRecipe = {
    ...state.walkthrough.recipe,
    sections: state.walkthrough.recipe.sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          ingredients: section.ingredients.map((sectionIngredient, index) => {
            if (index === ingredientIndex) {
              console.log(ingredient);
              return ingredient;
            }
            return sectionIngredient;
          }),
        };
      }
      return section;
    }),
  };

  dispatch({
    type: WALKTHROUGH_SET_INGREDIENT,
    payload: updatedRecipe,
  });
};

export const resetWalkthrough = () => (dispatch) => {
  dispatch({
    type: RESET_WALKTHROUGH,
  });
};

//TODO FIRST: Scaling should also scale yield, servings values
export const scaleSection = (sectionIndex, scaleFactor) => (dispatch, getState) => {
  const state = getState();
  const section = JSON.parse(JSON.stringify(state.walkthrough.recipe.sections[sectionIndex]));

  const scaledIngredients = section.ingredients.map((ingredient) => {
    if (!ingredient.unit.display.magnitude) return ingredient;

    return {
      ...ingredient,
      unit: {
        ...ingredient.unit,
        display: {
          ...ingredient.unit.display,
          magnitude: (parseFloat(ingredient.unit.display.magnitude) * scaleFactor).toString(),
        },
        anchor: ingredient.unit.anchor.magnitude
          ? {
              ...ingredient.unit.anchor,
              magnitude: (parseFloat(ingredient.unit.anchor.magnitude) * scaleFactor).toString(),
            }
          : ingredient.unit.anchor,
      },
    };
  });

  // Scale section yield and servings
  const scaledSection = {
    ...section,
    ingredients: scaledIngredients,
    yield: section.yield
      ? {
          ...section.yield,
          amount: (parseFloat(section.yield.amount) * scaleFactor).toString(),
        }
      : undefined,
    servings: section.servings ? Math.round(section.servings * scaleFactor) : undefined,
  };

  console.log(scaledSection);

  dispatch({
    type: WALKTHROUGH_SCALE_SECTION,
    payload: {
      sectionIndex,
      section: scaledSection,
    },
  });

  return scaledSection;
};

export const scaleRecipe = (scaleFactor) => (dispatch, getState) => {
  const state = getState();
  const sections = JSON.parse(JSON.stringify(state.walkthrough.recipe.sections));

  for (let i = 0; i < sections.length; i++) {
    let section = sections[i];

    const scaledIngredients = section.ingredients.map((ingredient) => {
      if (!ingredient.unit.display.magnitude) return ingredient;

      return {
        ...ingredient,
        unit: {
          ...ingredient.unit,
          display: {
            ...ingredient.unit.display,
            magnitude: (parseFloat(ingredient.unit.display.magnitude) * scaleFactor).toString(),
          },
          anchor: ingredient.unit.anchor.magnitude
            ? {
                ...ingredient.unit.anchor,
                magnitude: (parseFloat(ingredient.unit.anchor.magnitude) * scaleFactor).toString(),
              }
            : ingredient.unit.anchor,
        },
      };
    });

    // Scale section yield and servings
    const scaledSection = {
      ...section,
      ingredients: scaledIngredients,
      yield: section.yield
        ? {
            ...section.yield,
            amount: (parseFloat(section.yield.amount) * scaleFactor).toString(),
          }
        : undefined,
      servings: section.servings ? Math.round(section.servings * scaleFactor) : undefined,
    };

    sections[i] = scaledSection;
  }

  dispatch({
    type: WALKTHROUGH_SCALE_RECIPE,
    payload: sections,
  });
};

export const setServingsEaten = (sectionIndex, servingsEaten) => (dispatch) => {
  dispatch({
    type: WALKTHROUGH_SET_SERVINGS_EATEN,
    payload: {
      sectionIndex,
      servingsEaten,
    },
  });
};

export const setSectionServings = (sectionIndex, servings) => (dispatch) => {
  dispatch({
    type: WALKTHROUGH_SET_SECTION_SERVINGS,
    payload: {
      sectionIndex,
      servings,
    },
  });
};

export const clearWalkthroughErrorByPath = (path) => (dispatch, getState) => {
  const state = getState();
  const newErrors = state.walkthrough.errors
    ? JSON.parse(JSON.stringify(state.walkthrough.errors))
    : {
        sections: [],
      };

  const pathArray = path.split(".");
  let current = newErrors;
  let parent = null;
  let parentKey = null;

  // Traverse the path
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];
    if (current[key] === undefined) {
      return; // Path doesn't exist, exit early
    }
    parentKey = key;
    parent = current;
    current = current[key];
  }

  const lastKey = pathArray[pathArray.length - 1];

  // Clear the error at the final path
  delete current[lastKey];

  // Clean up empty parent objects recursively, but preserve top-level structure
  while (parent !== null) {
    if (typeof current === "object" && current !== null) {
      const isEmpty =
        Object.keys(current).length === 0 ||
        (Array.isArray(current) && current.every((item) => item === null || item === undefined));

      // Only delete if not at top level
      if (isEmpty && parent !== newErrors) {
        delete parent[parentKey];
      } else if (isEmpty && parent === newErrors) {
        // At top level, preserve structure but set to empty array/object
        if (parentKey === "sections") {
          parent[parentKey] = [];
        }
      }
    }

    // Move up the chain
    current = parent;
    const nextIndex = pathArray.indexOf(parentKey) - 1;
    parentKey = pathArray[nextIndex];
    parent = nextIndex >= 0 ? newErrors : null;

    for (let i = 0; i < nextIndex; i++) {
      parent = parent[pathArray[i]];
    }
  }

  dispatch({
    type: SET_WALKTHROUGH_ERRORS,
    payload: newErrors,
  });
};

export const setWalkthroughErrorByPath = (path, errorData) => (dispatch, getState) => {
  const state = getState();
  const newErrors = state.walkthrough.errors
    ? JSON.parse(JSON.stringify(state.walkthrough.errors))
    : {
        sections: [],
      };

  const pathArray = path.split(".");
  let current = newErrors;

  // Traverse and create the path if it doesn't exist
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];

    // Handle array indices in path
    if (key.includes("[") && key.includes("]")) {
      const arrayKey = key.split("[")[0];
      const index = parseInt(key.split("[")[1].split("]")[0]);

      if (!current[arrayKey]) {
        current[arrayKey] = [];
      }
      if (!current[arrayKey][index]) {
        current[arrayKey][index] = {};
      }
      current = current[arrayKey][index];
    } else {
      if (!current[key]) {
        // Create object or array based on next path segment
        const nextKey = pathArray[i + 1];
        current[key] = nextKey.includes("[") ? [] : {};
      }
      current = current[key];
    }
  }

  // Set the final value
  const lastKey = pathArray[pathArray.length - 1];
  if (lastKey.includes("[") && lastKey.includes("]")) {
    const arrayKey = lastKey.split("[")[0];
    const index = parseInt(lastKey.split("[")[1].split("]")[0]);
    if (!current[arrayKey]) {
      current[arrayKey] = [];
    }
    current[arrayKey][index] = errorData;
  } else {
    current[lastKey] = errorData;
  }

  dispatch({
    type: SET_WALKTHROUGH_ERRORS,
    payload: newErrors,
  });
};
