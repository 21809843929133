import React from "react";
import Typography from "@mui/material/Typography";
import { ReactComponent as ArrowUp } from "../../../assets/icons/svg/arrow-up.svg";

const ReleaseToRefresh = () => {
  return (
    <div className="w-full flex flex-row items-center justify-center py-4 relative z-0">
      <Typography variant="body1" className="flex flex-row gap-2 items-center">
        <ArrowUp className="h-6 w-6 fill-mainGreen" /> Release to Refresh!
      </Typography>
    </div>
  );
};

export default ReleaseToRefresh;
