import React, { useEffect, useState } from "react";
import api from "../../api/api";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Divider, Skeleton, Button } from "@mui/material";

import Spinner from "../layout/Spinner";

//In App Purchases

import IOSPriceSelector from "./price_selectors/iOS";
import Subscribed from "./price_selectors/Subscribed";
import NoAuth from "./price_selectors/NoAuth";

import { ReactComponent as PlusLogo } from "../../assets/logos/plus/pluslogogreen.svg";
const CheckoutPriceSelector = ({ auth: { isAuthenticated, isPlus, loading }, device: { platform }, type = "page" }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretLoading, setClientSecretLoading] = useState(true);
  const [clientSecretError, setClientSecretError] = useState(null);

  //Get customer session to prefill
  const getCustomerClientSecret = async () => {
    try {
      const response = await api.post("/stripe/create-pricing-table");
      setClientSecret(response.data.clientSecret);
      setClientSecretLoading(false);
      setClientSecretError(null);
    } catch (error) {
      console.error(error);
      setClientSecretLoading(false);
      setClientSecretError(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && platform !== "ios") {
      getCustomerClientSecret();
    }
  }, [isAuthenticated]);

  const stripePriceSelector = (
    <div className="w-full">
      <stripe-pricing-table
        pricing-table-id="prctbl_1QWRbkEG8mQA0pyFIY3BAuro"
        publishable-key="pk_live_51PKuT2EG8mQA0pyFMydoJM5ZQ6mv7MffkLv5QGo3t1DYW4PZVUsVNyqXgcRYNWSYPwkSm3wI3l6eUNMalfckRg5g00bYIPxl7D"
      ></stripe-pricing-table>
    </div>
  );

  const loadingSelector = (
    <div className="w-full flex items-center justify-center">
      <Spinner />
    </div>
  );

  const skeletonPriceSelector = (
    <div className="w-full h-full">
      <div className="flex flex-col gap-4">
        <Skeleton variant="rectangular" width="100%" height={40} />
        <Skeleton variant="rectangular" width="100%" height={40} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </div>
    </div>
  );

  const priceSelectorError = (
    <div className="w-full h-full flex items-center justify-center">
      <Typography variant="h5" className="text-white text-center px-4">
        An error has occurred loading thisPantry+ pricing.<br></br>Please refresh the page and try again.
      </Typography>
    </div>
  );

  const determinePriceSelector = () => {
    if (loading) {
      return loadingSelector;
    }

    if (isAuthenticated) {
      if (isPlus) {
        return <Subscribed />;
      } else {
        if (platform === "ios") {
          return <IOSPriceSelector />;
        } else {
          if (clientSecretLoading) {
            return skeletonPriceSelector;
          } else if (clientSecretError) {
            return priceSelectorError;
          } else {
            return stripePriceSelector;
          }
        }
      }
    } else {
      return <NoAuth />;
    }
  };

  return (
    <div className="bg-primaryText">
      <div className="bg-primaryText">
        <div
          className={`py-16 md:py-24 flex flex-col ${
            type === "page" ? "xl:flex-row gap-8 xl:gap-16" : "gap-4"
          } items-center max-w-6xl mx-auto`}
        >
          {!(loading || (isAuthenticated && isPlus)) && (
            <>
              <div className="w-full max-w-[425px] px-8 flex flex-col">
                <Typography variant="h5" className="text-white">
                  Try
                </Typography>
                <PlusLogo className="h-16 w-auto my-2 md:mx-8 fill-primaryText-700" />
                <Typography variant="h5" className="text-white text-right">
                  free for 7 days
                </Typography>
                <div className="text-center">
                  <Typography
                    variant="subtitle2"
                    className="font-normal mt-4 text-secondaryText-100 w-full leading-snug text-xs"
                  >
                    Then subscribe weekly, monthly, or annually.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className="font-normal text-secondaryText-100 w-full leading-snug text-xs"
                  >
                    Cancel anytime.
                  </Typography>
                </div>
              </div>

              <Divider orientation="vertical" flexItem className="border-secondaryText-100 hidden xl:flex" />
            </>
          )}

          <div className="w-full flex-grow px-4">{determinePriceSelector()}</div>
        </div>
      </div>
    </div>
  );
};

CheckoutPriceSelector.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  device: state.device,
});

export default connect(mapStateToProps)(CheckoutPriceSelector);
