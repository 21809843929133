import React, { useState } from "react";

//MUI
import { Typography, TextField, Button } from "@mui/material";

/*
** Recipe search in name box to prefill these values,  **

-  Name
- Notes
- Picture of food (if phone ate first)

- Tags

- Macros (in sections)
    - Picture of nutrition facts
    - Serving Size
    - Servings

- Date select (default to current viewed date)
*/

//Minor features to add:
// - when changing servings from prefilled values ask to scale macros?
// - Search for recipes/other macro events

const MacroSection = ({ section }) => {
  return (
    <div>
      <div>{section.name}</div>
    </div>
  );
};

const TrackMacroEvent = () => {
  //State
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [image, setImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [macroItems, setMacroItems] = useState([]);

  //Name update functions
  const handleNameChange = (value) => {
    setName(value);
  };

  const handleNotesChange = (value) => {
    setNotes(value);
  };

  const addMacroItem = () => {
    let newSections = JSON.parse(JSON.stringify(macroItems));
    newSections.push({
      name: "test name",
      macros: [],
      servingSize: "",
      servings: "",
    });

    setMacroItems(newSections);
  };

  return (
    <div className="p-4 pt-0">
      <div>
        <Typography variant="h6">Track a Macro Event</Typography>
      </div>
      <div className="flex flex-col gap-4">
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
          //error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount}
          //helperText={errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount
          //  ?.map((error) => error.msg)
          //  .join(", ")}
          inputProps={{ maxLength: 7 }}
          helperText="Name the Macro Event, or search for a recipe to track"
        />
        <TextField
          label="Notes"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          maxRows={5}
          value={notes}
          onChange={(e) => handleNotesChange(e.target.value)}
          //error={!!errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount}
          //helperText={errors?.sections?.[sectionIndex]?.ingredients?.[ingredientIndex]?.amount
          //  ?.map((error) => error.msg)
          //  .join(", ")}
          inputProps={{ maxLength: 7 }}
        />

        {macroItems.length === 0 ? (
          <div>
            <Button onClick={() => addMacroItem()}>Add Macros</Button>
          </div>
        ) : (
          macroItems.map((section, i) => <MacroSection key={i} section={section} />)
        )}

        <div>
          <Button variant="contained" color="primary" fullWidth disableElevation disableRipple>
            Track
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TrackMacroEvent;
