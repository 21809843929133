import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import api from "../../../../api/api";

//Components
import Spinner from "../../../layout/Spinner";
import Ingredient from "./components/Ingredient";
import ReviewModal from "../../../recipes/review/ReviewModal";
import CannotFind from "../../../static/CannotFind";

import WalkthroughNav from "./components/WalkthroughNav";

import WalkthroughSection from "./components/WalkthroughSection";

//Modal
import CustomModal from "../../../modal/CustomModal";
import IntroModal from "./modals/IntroModal";
import FinishRecipeModal from "./modals/FinishRecipeModal";

//Actions
import { getWalkthroughRecipe, setWalkthroughSection, resetWalkthrough } from "../../../../actions/walkthrough";
import { showReviewModal, hideReviewModal } from "../../../../actions/review";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

//MUI Styling
import { Typography, Button, Select, MenuItem } from "@mui/material";

//Utils
import { convertToTitleCase } from "../../../../utils/recipe";
//TO DO:
//Overview screen
//Next button to go through sections
//Edit ingredient

export const Walkthrough = ({
  walkthrough: { recipe, loading, error, user, currentSection },
  review: { recipeReviewModal },
  getWalkthroughRecipe,
  setWalkthroughSection,
  showReviewModal,
  hideReviewModal,
  resetWalkthrough,
}) => {
  const { recipeID } = useParams();

  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showFinishRecipeModal, setShowFinishRecipeModal] = useState(false);

  const onCloseIntroModal = () => {};

  const getRecipe = async (id) => {
    getWalkthroughRecipe(id);
  };

  const handleFinishRecipe = () => {
    setShowFinishRecipeModal(true);
  };

  const [navHeight, setNavHeight] = useState(144);

  let wakeLock = null;

  useEffect(() => {
    window.scrollTo(0, 0);
    getRecipe(recipeID);

    return () => {
      resetWalkthrough();
    };
  }, [recipeID]);

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request("screen");
      } catch (err) {
        console.log("WakeLock Disabled");
      }
    };

    requestWakeLock();

    return () => {
      if (wakeLock) {
        wakeLock.release();
      }
    };
  }, []);

  //Just have a title above nav bar, no intro section
  const walkthroughHTML = (
    <div className="md:bg-neutral-50 md:px-8 md:py-8 md:min-h-screen">
      <WalkthroughNav handleFinishRecipe={handleFinishRecipe} setNavHeight={setNavHeight} />

      {recipe ? (
        <div className="flex flex-col md:gap-8">
          {currentSection === "all" ? (
            recipe.sections.map((section, i) => <WalkthroughSection section={section} key={i} showTitle={true} />)
          ) : (
            <WalkthroughSection section={recipe.sections[currentSection]} sectionIndex={currentSection} />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
  return (
    <div
      className="w-full bg-neutral-50 pb-[calc(4rem+env(safe-area-inset-bottom))] md:m-0 md:w-[calc(100%-5rem)] md:ml-[5rem]"
      style={{ paddingTop: `calc(${navHeight}px - env(safe-area-inset-top))` }}
    >
      {!loading && recipe && user && (
        <CustomModal open={showIntroModal} handleClose={onCloseIntroModal} showExit={false}>
          <IntroModal recipe={recipe} user={user} handleClose={onCloseIntroModal} />
        </CustomModal>
      )}
      <CustomModal
        open={showFinishRecipeModal}
        handleClose={() => setShowFinishRecipeModal(false)}
        showExit={true}
        type="bottom"
      >
        <FinishRecipeModal handleClose={() => setShowFinishRecipeModal(false)} />
      </CustomModal>

      {loading ? <Spinner /> : error ? <CannotFind /> : walkthroughHTML}
    </div>
  );
};

Walkthrough.propTypes = {
  walkthrough: PropTypes.object.isRequired,
  getWalkthroughRecipe: PropTypes.func.isRequired,
  setWalkthroughSection: PropTypes.func.isRequired,
  showReviewModal: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  hideReviewModal: PropTypes.func.isRequired,
  resetWalkthrough: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walkthrough: state.walkthrough,
  review: state.review,
});

export default connect(mapStateToProps, {
  getWalkthroughRecipe,
  setWalkthroughSection,
  showReviewModal,
  hideReviewModal,
  resetWalkthrough,
})(Walkthrough);
