import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  ExpressCheckoutElement,
  LinkAuthenticationElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//UI Components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Spinner from "../layout/Spinner";
//TODO:
//Check if already subscribed, if already subscribed redirect to subscription settings
//Better error/loading handling (skeleton)
//Handle reporting leaving the page to clean up subscription creation, opt to retarget later (discount for first 1? 3? months?) -> infeed, notification, etc
//Select monthly or yearly subscription
//Prefill email address, full name, from user state

const CheckoutForm = ({ subscriptionId, handleCheckoutSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  //Loading, error for elements
  const [linkElementLoading, setLinkElementLoading] = useState(true);
  const [linkElementError, setLinkElementError] = useState(null);

  const handleLinkElementError = (data) => {
    setLinkElementError(data);
  };

  const handleLinkElementReady = () => {
    setLinkElementLoading(false);
  };

  const [addressElementLoading, setAddressElementLoading] = useState(true);
  const [addressElementError, setAddressElementError] = useState(null);

  const handleAddressElementError = (data) => {
    setAddressElementError(data);
  };

  const handleAddressElementReady = () => {
    setAddressElementLoading(false);
  };
  const [paymentElementLoading, setPaymentElementLoading] = useState(true);
  const [paymentElementError, setPaymentElementError] = useState(null);

  const handlePaymentElementError = (data) => {
    setPaymentElementError(data);
  };

  const handlePaymentElementReady = () => {
    setPaymentElementLoading(false);
  };

  const [expressCheckoutLoading, setExpressCheckoutLoading] = useState(true);
  const [expressCheckoutError, setExpressCheckoutError] = useState(null);

  const handleExpressCheckoutError = (data) => {
    setExpressCheckoutError(data);
  };

  const handleExpressCheckoutReady = () => {
    setExpressCheckoutLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/plus/welcome`,
      },
    });

    if (error) {
      console.log("Error:", error);
      setError(error.message);
    }
    handleCheckoutSuccess();
    setProcessing(false);
  };

  const handleExpressCheckout = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/plus/welcome`,
      },
    });

    if (error) {
      console.log("Error:", error);
      setError(error.message);
    }

    handleCheckoutSuccess();
    setProcessing(false);
  };

  const paymentElementOptions = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
    business: {
      name: "thisPantry",
    },
  };

  const expressCheckoutOptions = {
    buttonType: {
      applePay: "subscribe",
      googlePay: "subscribe",
    },
  };

  return (
    <form onSubmit={handleSubmit} className="w-full h-full px-4 pb-16 md:p-0 lg:max-w-[600px]">
      <div className="flex flex-col gap-4">
        <div>
          <Typography variant="h6" className="mb-2">
            Billing Information
          </Typography>
          <div className="flex flex-col gap-4">
            <LinkAuthenticationElement
              onLoaderStart={handleLinkElementReady}
              onError={(data) => handleLinkElementError(data)}
            />
            <AddressElement
              options={{
                mode: "billing",
              }}
              onLoaderStart={handleAddressElementReady}
              onError={(data) => handleAddressElementError(data)}
            />
            {linkElementLoading && addressElementLoading && <Spinner />}
          </div>
        </div>
        <div>
          <Typography variant="h6" className="mb-2">
            Payment Method
          </Typography>
          <div className="flex flex-col gap-4">
            <ExpressCheckoutElement
              options={expressCheckoutOptions}
              onConfirm={handleExpressCheckout}
              onLoaderStart={handleExpressCheckoutReady}
              onError={(data) => handleExpressCheckoutError(data)}
            />
            <PaymentElement
              options={paymentElementOptions}
              onLoaderStart={handlePaymentElementReady}
              onError={(data) => handlePaymentElementError(data)}
            />
            {expressCheckoutLoading && paymentElementLoading && <Spinner />}
          </div>
        </div>
      </div>
      <div>
        <Typography variant="subtitle2" className="mt-2 text-secondaryText leading-snug font-normal text-xs">
          You can edit or cancel your subscription at any time,<br></br>or view our subscription terms in your{" "}
          <Link to="/settings/subscription" className="text-mainGreen font-medium">
            settings
          </Link>
          .
        </Typography>
      </div>

      {error && <div className="text-red-500 mt-2">{error}</div>}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!stripe || processing}
        fullWidth
        disableElevation
        className="mt-4"
        size="large"
      >
        {processing ? "Processing..." : "Subscribe"}
      </Button>
    </form>
  );
};

export default CheckoutForm;
