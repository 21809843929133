import React from "react";
import { Link } from "react-router-dom";

import { Typography, Chip, Tooltip } from "@mui/material";

//Layout
import MaxLinesTextBlock from "../../../layout/maxlinestextblock/MaxLinesTextBlock";

//Icons
import { ReactComponent as StarIcon } from "../../../../assets/icons/svg/star-solid.svg";
import { ReactComponent as TimesCookedIcon } from "../../../../assets/icons/svg/utensils-light.svg";
import { ReactComponent as ChefHat } from "../../../../assets/icons/svg/hat-chef-regular.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/svg/link.svg";

//Utils
import { shortInteractionCount } from "../../../../utils/post";
import { preventWidows } from "../../../../utils/text";

import { Badge } from "../../../ui/badge";

const RecipeInfo = ({ recipe }) => {
  //TO DO:
  //More space below, add description on no image posts?
  return (
    <div className={`w-full px-4 ${recipe && recipe.data && recipe.data.length > 0 && "mt-4"}`}>
      <div className="flex flex-col">
        <Link to={`/${recipe.user.username}/${recipe.url}`}></Link>

        <div className="flex flex-col flex-grow">
          <div>
            <div className="w-full break-words">
              <Link to={`/${recipe.user.username}/${recipe.url}`}>
                <div className="flex flex-row w-full justify-between items-start">
                  <Typography variant="h6" className="font-normal leading-tight">
                    {preventWidows(recipe.name)}
                  </Typography>
                  <div className="ml-4">
                    <div className="flex flex-row gap-2">
                      <Tooltip
                        title={
                          recipe.rating.aggregated
                            ? `This recipe has been rated ${recipe.rating.aggregated} stars in ${shortInteractionCount(
                                recipe.rating.totalRating
                              )} ratings`
                            : "This recipe has not been rated yet"
                        }
                        placement="top-end"
                        arrow
                      >
                        <div className="flex flex-row items-center cursor-pointer select-none">
                          <div className="w-full">
                            <StarIcon className="w-[1rem] fill-rating mr-1" />
                          </div>
                          <div className="w-full flex justify-center">
                            <Typography
                              variant="subtitle2"
                              className="font-normal text-secondaryText-600 break-keep whitespace-nowrap select-none"
                            >
                              {recipe.rating.aggregated ? recipe.rating.aggregated : "-"}
                            </Typography>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="flex flex-row justify-between items-end">
            <div>
              {recipe.tags.length === 0 ? (
                <div className="mt-2">
                  <Typography variant="subtitle2" className="text-secondaryText font-normal mt-1">
                    No tags
                  </Typography>
                </div>
              ) : (
                <div>
                  <div className="my-4 hidden sm:block">
                    {recipe.tags.slice(0, 5).map((tag, index) => (
                      <Badge
                        variant="primary"
                        className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer"
                      >
                        <Typography variant="body2" className="text-xs">
                          {tag.text}
                        </Typography>
                      </Badge>
                    ))}
                    {recipe.tags.length > 4 && (
                      <Typography variant="body2" className="text-secondaryText mt-1">
                        {`+${recipe.tags.length - 4} more`}
                      </Typography>
                    )}
                  </div>
                  <div className="my-4 flex flex-wrap gap-1 items-center sm:hidden">
                    {recipe.tags.slice(0, 5).map((tag, index) => (
                      <Badge variant="primary" className="font-normal py-1 px-3 shadow-sm cursor-pointer">
                        <Typography variant="body2" className="text-xs">
                          {tag.text}
                        </Typography>
                      </Badge>
                    ))}
                    {recipe.tags.length > 4 && (
                      <Typography variant="body2" className="text-secondaryText ml-1">
                        {`+${recipe.tags.length - 4} more`}
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeInfo;
