//Import redux types
import {
  WALKTHROUGH_GET_RECIPE,
  WALKTHROUGH_RECIPE_ERROR,
  WALKTHROUGH_SET_SECTION,
  WALKTHROUGH_SET_INGREDIENT,
  RESET_WALKTHROUGH,
  WALKTHROUGH_SCALE_RECIPE,
  WALKTHROUGH_SCALE_SECTION,
  WALKTHROUGH_SET_SERVINGS_EATEN,
  WALKTHROUGH_SET_SECTION_SERVINGS,
  SET_WALKTHROUGH_ERRORS,
} from "../actions/types";

//Initial state object
const initialState = {
  recipe: null,
  originalRecipe: null,
  loading: true,
  error: false,
  timer: {
    value: 0,
  },
  sections: null,
  currentSection: null,
  user: null,
  errors: {
    sections: [],
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case RESET_WALKTHROUGH: {
      return initialState;
    }
    case WALKTHROUGH_GET_RECIPE: {
      return {
        ...state,
        recipe: payload.recipe,
        originalRecipe: payload.recipe,
        user: payload.user,
        sections: payload.sections,
        loading: false,
        error: false,
        currentSection: payload.recipe.sections.length > 1 ? "all" : 0,
      };
    }
    case WALKTHROUGH_RECIPE_ERROR: {
      return {
        ...state,
        recipe: null,
        originalRecipe: null,
        user: null,
        loading: false,
        error: true,
        currentSection: 0,
      };
    }
    case WALKTHROUGH_SET_SECTION: {
      return {
        ...state,
        currentSection: payload,
      };
    }
    case WALKTHROUGH_SET_INGREDIENT: {
      return {
        ...state,
        recipe: payload,
      };
    }
    case WALKTHROUGH_SCALE_SECTION:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          sections: state.recipe.sections.map((section, index) =>
            index === payload.sectionIndex ? payload.section : section
          ),
        },
      };

    case WALKTHROUGH_SCALE_RECIPE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          sections: payload,
        },
      };

    case WALKTHROUGH_SET_SERVINGS_EATEN:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          sections: state.recipe.sections.map((section, index) =>
            index === payload.sectionIndex ? { ...section, servingsEaten: payload.servingsEaten } : section
          ),
        },
      };
    case SET_WALKTHROUGH_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    default:
      return state;
  }
}
