import React, { useState, useEffect } from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart, CartesianGrid, LabelList, Line, LineChart, XAxis } from "recharts";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChartContainer, ChartConfig, ChartTooltip, ChartTooltipContent } from "../ui/chart";

import { Typography, Button, IconButton } from "@mui/material";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

//Icons
import { ReactComponent as ChevronLeft } from "../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/chart-line.svg";

//Charts
import RadialChart from "../charts/RadialChart";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import MultiAreaChart from "../charts/MultiAreaChart";

//Sections
import RecipeHistory from "./sections/RecipeHistory";
import Nutrition from "./sections/Nutrition";
import Expenses from "./sections/Expenses";

//Modals
import CustomModal from "../modal/CustomModal";
import ComingSoonModal from "../comingsoon/ComingSoonModal";
import DateSelectModal from "./modals/DateSelectModal";

export const InsightsPage = ({ auth: { user } }) => {
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDateSelectModal, setShowDateSelectModal] = useState(false);
  //Todays macro targets at top
  //Planned meals
  //Daily macro history

  const formatDay = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  const handleCalendarDateChange = (date) => {
    const userTimezone = getUserTimezone();
    const localDate = new Date(date.toLocaleString("en-US", { timeZone: userTimezone }));
    setCurrentDate(localDate);
    setShowDateSelectModal(false);
  };

  const handleCloseDateSelectModal = () => {
    setShowDateSelectModal(false);
  };

  const handleCloseComingSoon = () => {
    navigate("/");
  };

  useEffect(() => {
    const userTimezone = getUserTimezone();
    const localDate = new Date(currentDate.toLocaleString("en-US", { timeZone: userTimezone }));
    console.log(localDate);
    setCurrentDate(localDate);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="py-[4rem] md:py-0 md:pl-[5rem]">
      <Helmet>
        <title>Insights</title>
      </Helmet>
      <CustomModal
        open={!(user && user.isAdmin)}
        showExit={false}
        handleClose={handleCloseComingSoon}
        rounded="rounded-2xl"
        border={false}
        type="bottom"
        parentClassNameOverride="md:min-w-[600px]"
      >
        <ComingSoonModal />
      </CustomModal>
      <CustomModal open={showDateSelectModal} handleClose={handleCloseDateSelectModal} type="bottomToCentered">
        <DateSelectModal
          onDateSelect={handleCalendarDateChange}
          onClose={handleCloseDateSelectModal}
          dateFromParent={currentDate}
        />
      </CustomModal>
      <div className="w-full flex flex-col md:p-8 gap-4 bg-neutral-50 min-h-screen">
        <div className="w-full flex flex-row justify-between border border-separator items-center p-4 md:rounded-md shadow-sm bg-background">
          <IconButton onClick={() => changeDate(-1)} className="p-0 hover:bg-transparent" disableRipple>
            <ChevronLeft className="h-4 fill-secondaryText" />
          </IconButton>
          <div className="w-full">
            <Button
              className="p-0 hover:bg-transparent flex flex-col items-start text-start"
              onClick={() => setShowDateSelectModal(true)}
              disableRipple
            >
              <div className="w-full px-8">
                <div>
                  <Typography variant="subtitle1" className="text-secondaryText leading-none">
                    {formatDay(currentDate)}
                  </Typography>
                  <Typography variant="h5">{formatDate(currentDate)}</Typography>
                </div>
              </div>
            </Button>
          </div>

          <IconButton onClick={() => changeDate(1)} className="p-0 hover:bg-transparent" disableRipple>
            <ChevronRight className="h-4 fill-secondaryText" />
          </IconButton>
        </div>
        {user && !user.isAdmin ? (
          <div className=" w-full flex flex-col xl:flex-row gap-4 xl:gap-8">
            <div className="w-full flex flex-col gap-16">
              <div className="w-full">
                <Nutrition currentDate={currentDate} setShowDateSelectModal={setShowDateSelectModal} />
              </div>
              <div className="w-full xl:hidden">
                <RecipeHistory date={currentDate} />
              </div>
              <div className="w-full">
                <Expenses />
              </div>
            </div>
            <div className="w-full hidden xl:block">
              <RecipeHistory />
            </div>
          </div>
        ) : (
          <div className=" w-full flex flex-col xl:flex-row gap-4 xl:gap-8">
            <div className="w-full hidden xl:block">
              <RecipeHistory date={currentDate} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

InsightsPage.propTypes = {
  data: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(InsightsPage);
