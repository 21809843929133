import React, { useEffect, useState } from "react";

import ForYouGrid from "./ForYouGrid";
import Spinner from "../../../layout/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import EndOfFeed from "../../../feed/EndOfFeed";
//API
import api from "../../../../api/api";
const ForYou = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isEndOfFeed, setIsEndOfFeed] = useState(false);

  const fetchPosts = async () => {
    try {
      const res = await api.get(`/search/foryou/${offset}`);
      setPosts((prevPosts) => [...prevPosts, ...res.data]);
      setError(false);
      setLoading(false);
      setOffset((prevOffset) => prevOffset + res.data.length);
      setIsEndOfFeed(res.data.length < 20); // Assuming 20 is the limit per fetch
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const refreshFeed = async () => {
    setOffset(0);
    setPosts([]);
    setIsEndOfFeed(false);
    setLoading(true);
    await fetchPosts();
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      {loading ? (
        <div className="pt-[5.5rem]">
          <Spinner />
        </div>
      ) : error ? (
        <div>Error</div>
      ) : (
        <div className="pt-[5.5rem]">
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchPosts}
            hasMore={!isEndOfFeed}
            loader={loading && <Spinner />}
            pullDownToRefresh
            refreshFunction={refreshFeed}
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={<h3 className="text-center mb-4">&#8595; Pull down to refresh</h3>}
            releaseToRefreshContent={<h3 className="text-center mb-4">&#8593; Release to refresh</h3>}
            endMessage={<EndOfFeed />}
          >
            <ForYouGrid posts={posts} columns={2} />
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default ForYou;
