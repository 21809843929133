import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import api from "../../../../api/api";

import { Link } from "react-router-dom";

//UI
import { Typography, TextField, InputAdornment, IconButton, Divider } from "@mui/material";

//Infinite scroll
import InfiniteScroll from "react-infinite-scroll-component";

//Components
import Spinner from "../../../layout/Spinner";
import RecipeCard from "../../../recipes/card/RecipeCard";
import EndOfRecipes from "../main/EndOfRecipes";

//Actions
import { getCookbook, setCookbookQuery, recipeSearchLovedByFriends } from "../../../../actions/cookbook";

//Icons
import { ReactComponent as TitleIcon } from "../../../../assets/icons/svg/heart.svg";
import { ReactComponent as SearchFiltersIcon } from "../../../../assets/icons/svg/sliders-light.svg";

//Modals
import CustomModal from "../../../modal/CustomModal";
import FiltersModal from "../modal/FiltersModal";

const LovedByFriends = ({
  sectionData: { recipes, loading, error, offset, isEndOfFeed },
  search: { query, preferences, filters },
  getCookbook,
  setCookbookQuery,
  recipeSearchLovedByFriends,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const toggleFilterModal = (state) => {
    setShowFilterModal(state);
  };

  const reportSectionOpened = async () => {
    try {
      await api.post("/cookbook/subsection/open", { section: "lovedByFriends" });
    } catch (error) {
      console.log("Error opening section");
    }
  };

  const clearDefaultSearch = () => {
    if (query === "Search for a recipe...") {
      setCookbookQuery("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    reportSectionOpened();
    clearDefaultSearch();
    //Remove later, just fetching data for now on load to test
    if (loading) {
      getCookbook();
    }
  }, []);

  const fetchMoreData = () => {
    recipeSearchLovedByFriends(query, filters, offset);
  };
  const refreshFeed = () => {
    recipeSearchLovedByFriends(query, filters, 0);
  };
  const getFilterCount = () => {
    return filters?.allergies.length + filters?.diets.length + filters?.include.length + filters?.exclude.length;
  };

  const handleQueryChange = (e) => {
    const newQuery = e.target.value;

    if (newQuery.length > 1024) {
      return;
    }

    setCookbookQuery(newQuery);

    // If the query is empty, fetch trending recipes without filtering
    if (newQuery.length < 2) {
      recipeSearchLovedByFriends("", filters, 0); // Reset to fetch trending recipes
    } else {
      recipeSearchLovedByFriends(newQuery, filters, 0); // Fetch based on the current query
    }
  };

  return (
    <div className="py-[4rem] md:py-0 md:pl-[5rem] bg-neutral-50 min-h-screen">
      <CustomModal open={showFilterModal} handleClose={() => toggleFilterModal(false)} type="bottomToCentered">
        <FiltersModal handleClose={() => toggleFilterModal(false)} />
      </CustomModal>
      <div>
        <div className="p-4 md:px-8 bg-background border-b border-separator">
          <div>
            <Link to="/cookbook">
              <div className="mb-2">
                <Typography
                  variant="subtitle2"
                  className="text-secondaryText-700 font-normal"
                >{`< Go Back`}</Typography>
              </div>
            </Link>

            <div className="flex flex-row gap-4 items-center mb-4">
              <TitleIcon className="h-[1.25rem] fill-secondaryText-700" />
              <Typography variant="h6" className="font-normal leading-none">
                Loved By Friends
              </Typography>
            </div>
          </div>
          <div className="w-full mb-4 mt-8">
            <TextField
              fullWidth
              label="Search..."
              helperText={`${getFilterCount()} filters applied`}
              size="small"
              value={query}
              onChange={handleQueryChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleFilterModal(true)}>
                      <SearchFiltersIcon className="fill-primaryText h-[1rem]" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div>
          <InfiniteScroll
            dataLength={recipes ? recipes.length : 0}
            next={fetchMoreData}
            hasMore={!isEndOfFeed}
            loader={loading && <Spinner />}
            pullDownToRefresh
            refreshFunction={refreshFeed}
            endMessage={<EndOfRecipes loading={loading} />}
            className="overflow-hidden w-full min-[500px]:pb-8"
          >
            <div className="grid min-[500px]:gap-2 auto-fill-minmax-250 md:auto-fill-minmax-300 w-full min-[500px]:p-4 min-[500px]:pb-2 md:p-8 ">
              {recipes &&
                recipes.map((recipe) => (
                  <RecipeCard
                    key={recipe.id}
                    recipeid={recipe.id}
                    errorType="deleted"
                    rounded="min-[500px]:rounded-lg"
                  />
                ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

LovedByFriends.propTypes = {
  sectionData: PropTypes.object.isRequired,
  getCookbook: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  setCookbookQuery: PropTypes.func.isRequired,
  getCookbook: PropTypes.func.isRequired,
  recipeSearchLovedByFriends: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sectionData: state.cookbook.results.lovedByFriends,
  search: state.cookbook.search,
});

export default connect(mapStateToProps, { getCookbook, setCookbookQuery, recipeSearchLovedByFriends })(LovedByFriends);
