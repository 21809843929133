import {
  GET_COOKBOOK_RECIPES,
  COOKBOOK_RECIPES_ERROR,
  GET_DEFAULT_RECIPE_FILTERS,
  GET_DEFAULT_RECIPE_FILTERS_ERROR,
  SEARCH_RECIPES_ERROR,
  RESET_COOKBOOK_FILTERS,
  SEARCH_RECIPES_RESET,
  SET_SEARCH_RECIPES,
  GET_SEARCH_RECIPES,
  SET_COOKBOOK_QUERY,
  SET_ALLERGEN_FILTERS,
  SET_DIETARY_FILTERS,
  SET_INCLUDE_FILTERS,
  SET_EXCLUDE_FILTERS,
  SET_INCLUDE_INGRED_TYPE,
} from "./types";
import api from "../api/api";

export const getCookbook = () => async (dispatch) => {
  try {
    const res = await api.get("/cookbook");

    dispatch({
      type: GET_COOKBOOK_RECIPES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: COOKBOOK_RECIPES_ERROR,
    });
  }
};

export const getDefaultFilters = () => async (dispatch) => {
  try {
    const res = await api.get("/cookbook/myfilters");

    dispatch({
      type: GET_DEFAULT_RECIPE_FILTERS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_DEFAULT_RECIPE_FILTERS_ERROR,
    });
  }
};

export const recipeSearch =
  (query, filters, offset = 0) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_SEARCH_RECIPES,
        payload: {
          key: "search",
        },
      });
      const res = await api.post("/cookbook/search", {
        query,
        filters,
        offset,
      });

      dispatch({
        type: SET_SEARCH_RECIPES,
        payload: {
          recipes: res.data.recipes,
          numPosts: res.data.numPosts,
          isEndOfFeed: res.data.isEndOfFeed,
          offset: res.data.offset,
          key: "search",
        },
      });
    } catch (error) {
      dispatch({
        type: SEARCH_RECIPES_ERROR,
      });
    }
  };

export const recipeSearchBookmarked = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "bookmarked",
      },
    });

    const res = await api.post("/cookbook/bookmarked/search", {
      query,
      filters: {
        allergens: filters?.allergies ? filters.allergies : [],
        diets: filters?.diets ? filters.diets : [],
        includeIngredients: filters?.include ? filters.include : [],
        excludeIngredients: filters?.exclude ? filters.exclude : [],
        includeIngredientsMatchAll: filters?.includeMatchAll ? filters.includeMatchAll : false,
      },
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "bookmarked",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

export const recipeSearchMyRecipes = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "myRecipes",
      },
    });

    const res = await api.post("/cookbook/my/search", {
      query,
      filters: {
        allergens: filters?.allergies ? filters.allergies : [],
        diets: filters?.diets ? filters.diets : [],
        includeIngredients: filters?.include ? filters.include : [],
        excludeIngredients: filters?.exclude ? filters.exclude : [],
        includeIngredientsMatchAll: filters?.includeMatchAll ? filters.includeMatchAll : false,
      },
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "myRecipes",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

export const recipeSearchRecentlyCooked = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "recentlyCooked",
      },
    });

    const res = await api.post("/cookbook/recent/search", {
      query,
      filters: {
        allergens: filters?.allergies ? filters.allergies : [],
        diets: filters?.diets ? filters.diets : [],
        includeIngredients: filters?.include ? filters.include : [],
        excludeIngredients: filters?.exclude ? filters.exclude : [],
        includeIngredientsMatchAll: filters?.includeMatchAll ? filters.includeMatchAll : false,
      },
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "recentlyCooked",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

export const recipeSearchMyFavourites = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "favourites",
      },
    });

    const res = await api.post("/cookbook/favourites/search", {
      query,
      filters: {
        allergens: filters?.allergies ? filters.allergies : [],
        diets: filters?.diets ? filters.diets : [],
        includeIngredients: filters?.include ? filters.include : [],
        excludeIngredients: filters?.exclude ? filters.exclude : [],
        includeIngredientsMatchAll: filters?.includeMatchAll ? filters.includeMatchAll : false,
      },
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "favourites",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

// Trending recipes search
export const recipeSearchTrending = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "trending",
      },
    });

    const res = await api.post("/cookbook/trending/search", {
      query,
      filters: {
        allergens: filters?.allergies ? filters.allergies : [],
        diets: filters?.diets ? filters.diets : [],
        includeIngredients: filters?.include ? filters.include : [],
        excludeIngredients: filters?.exclude ? filters.exclude : [],
        includeIngredientsMatchAll: filters?.includeMatchAll ? filters.includeMatchAll : false,
      },
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "trending",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

// Recently cooked search
export const recipeSearchRecent = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "recentlyCooked",
      },
    });

    const res = await api.post("/cookbook/recent/search", {
      query,
      filters,
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "recentlyCooked",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

export const recipeSearchLovedByFriends = (query, filters, offset) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_RECIPES,
      payload: {
        key: "lovedByFriends",
      },
    });

    const res = await api.post("/cookbook/loved/search", {
      query,
      filters: {
        allergens: filters?.allergies ? filters.allergies : [],
        diets: filters?.diets ? filters.diets : [],
        includeIngredients: filters?.include ? filters.include : [],
        excludeIngredients: filters?.exclude ? filters.exclude : [],
        includeIngredientsMatchAll: filters?.includeMatchAll ? filters.includeMatchAll : false,
      },
      offset,
    });

    dispatch({
      type: SET_SEARCH_RECIPES,
      payload: {
        recipes: res.data.recipes,
        numPosts: res.data.numPosts,
        isEndOfFeed: res.data.isEndOfFeed,
        offset: res.data.offset,
        key: "lovedByFriends",
      },
    });
  } catch (error) {
    dispatch({
      type: SEARCH_RECIPES_ERROR,
    });
  }
};

export const recipeSearchReset = () => (dispatch) => {
  dispatch({
    type: SEARCH_RECIPES_RESET,
  });
};

export const resetCookbookFilters = () => (dispatch) => {
  dispatch({
    type: RESET_COOKBOOK_FILTERS,
  });
};

export const setCookbookQuery = (query) => (dispatch) => {
  dispatch({
    type: SET_COOKBOOK_QUERY,
    payload: query,
  });
};

export const setAllergenFilters = (allergens) => (dispatch) => {
  console.log(allergens);
  dispatch({
    type: SET_ALLERGEN_FILTERS,
    payload: allergens,
  });
};

export const setDietFilters = (diets) => (dispatch) => {
  dispatch({
    type: SET_DIETARY_FILTERS,
    payload: diets,
  });
};

export const setIncludeIngredientFilters = (include) => (dispatch) => {
  dispatch({
    type: SET_INCLUDE_FILTERS,
    payload: include,
  });
};

export const setExcludeIngredientFilters = (exclude) => (dispatch) => {
  dispatch({
    type: SET_EXCLUDE_FILTERS,
    payload: exclude,
  });
};

export const setIncludeIngredientsType = (matchAll) => (dispatch) => {
  dispatch({
    type: SET_INCLUDE_INGRED_TYPE,
    payload: matchAll,
  });
};
